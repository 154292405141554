import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Profile_Avatar from '../../assets/Profile_Avatar.png';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { scaleByRatio } from '../../utils/helper';
import { useGetActiveDater } from '../../services/mutations';
import ChatterBoxProspectiveDaterProfile from '../ChatterBoxProspectiveDaterProfile';

const FriendActivityList = (activity, id) => {
  console.log('activity: ', activity, id);
  //   const [show, setShow] = useState(false);
  //   const [profileData, setProfileData] = useState([]);
  //   const getActiveDater = useGetActiveDater();

  //   const fetchActiveDater = () => {
  //     let data = {};
  //     // setLoading(true);
  //     getActiveDater.mutate(data, {
  //       onSuccess: (response) => {
  //         console.log('getActiveDater success: ', response);
  //         // setActiveDaterFriend(response?.data?.payload?.friend);
  //         // setLoading(false);
  //         console.log('getActiveDater success: ----->', response?.data?.payload);
  //       },
  //       onError: (error) => {
  //         // setLoading(false);
  //         console.log('getActiveDater error: ', error);
  //       },
  //     });
  //   };

  //   useEffect(() => {
  //     fetchActiveDater();
  //   }, []);
  //   const onClickImage = () => {
  //     setProfileData(activity?.activity?.request_from);
  //     setShow(true);
  //   };

  return (
    <>
      <Grid
        container
        direction="row"
        columnSpacing={0.5}
        className={styles.root}>
        <div className={styles.convosFirstDiv}>
          <Grid
            item
            alignItems={'center'}
            justifyContent="left"
            style={{ paddingTop: `${scaleByRatio(5)}px` }}>
            <Typography textAlign="left">
              <img
                src={
                  activity?.activity?.request_from?.profile_pic ||
                  activity?.activity?.request_to?.profile_pic ||
                  Profile_Avatar
                }
                alt="profile"
                width={scaleByRatio(140)}
                height={scaleByRatio(140)}
                style={{
                  borderRadius: `8px`,
                  border: `2px solid ${'#02E242'}`,
                  //   cursor: `${
                  //     convo?.elbow.request_status === 'A' ? 'pointer' : 'default'
                  //   }`,
                }}
                onClick={() => {
                  //   if (convo?.elbow.request_status === 'A') {
                  //     handleProfileClicked(convo);
                  //   }
                  //   onClickImage();
                }}
              />
            </Typography>
          </Grid>
          <Grid
            item
            justifyContent="left"
            alignItems="start"
            style={{ marginBottom: '45px', paddingTop: '15px' }}
            // style={{
            //   paddingTop: `${scaleByRatio(5)}px`,
            //   display: 'flex',
            //   cursor: `${isDirectMessageEnabled ? 'pointer' : 'default'}`,
            // }}
            // onClick={() => {
            //   console.log('On Click');
            //   startDirectMessaging();
            // }}
          >
            {/* <Grid container direction="column" justifyContent="left"> */}
            <Stack
              direction="column"
              spacing={scaleByRatio(1.8)}
              className={styles.stackClass}
              flexWrap="wrap"
              style={{ display: 'flex' }}>
              <Grid
                style={{
                  color: '#76d9ff',
                  textWrap: 'nowrap',
                  fontSize: '18px',
                }}>
                {activity?.activity?.request_type === 'S' && 'I Reached Out to'}
              </Grid>
              <Grid
                item
                xs={12}
                alignItems={'start'}
                style={{ display: 'flex', marginTop: '0px' }}>
                <Typography
                  fullwidth
                  textAlign="left"
                  className={styles.profileName}
                  onClick={() => {
                    console.log('On Click');
                    // startDirectMessaging();
                  }}>
                  {activity?.activity?.request_from?.name ||
                    activity?.activity?.request_to?.name}
                </Typography>
              </Grid>
              <Grid
                style={{
                  color: '#76d9ff',
                  textWrap: 'nowrap',
                  fontSize: '18px',
                }}
                className={styles.reachOutText}>
                {activity?.activity?.request_type === 'R' && 'Reach Out to Me'}
              </Grid>
            </Stack>
            {/* </Grid> */}
          </Grid>
        </div>
        <div className={styles.convosSecDiv}>
          <Grid
            item
            justifyContent="right"
            alignItems="start"
            // style={{
            //   paddingTop: `${scaleByRatio(5)}px`,
            //   display: 'flex',
            //   cursor: `${isDirectMessageEnabled ? 'pointer' : 'default'}`,
            // }}
            // onClick={() => {
            //   console.log('On Click');
            //   startDirectMessaging();
            // }}
          >
            <Typography textAlign="left" className={styles.date}>
              {dayjs(activity?.activity?.created_on).format('DD/MM/YY HH:mm')}
              {/* date */}
            </Typography>
          </Grid>
        </div>
      </Grid>
      {/* {show && (
        <ChatterBoxProspectiveDaterProfile
          dater={profileData}
          //   closeDialog={() => setShow(false)}
        />
      )} */}
    </>
  );
};

export default FriendActivityList;
