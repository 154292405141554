import Radio from '@mui/material/Radio';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { scaleByRatio } from '../../utils/helper';
import './styles.scss';

const ListItemRadio = ({
  value,
  onChange,
  isChecked,
  id,
  displayText,
  listItemClass = '',
  textClass = '',
}) => {
  return (
    <ListItem
      key={value}
      secondaryAction={
        <Radio
          edge="end"
          onChange={() => onChange(value)}
          checked={isChecked}
          sx={{
            '& .MuiSvgIcon-root': { fontSize: scaleByRatio(28) },
            color: '#F93',
            '&.Mui-checked': {
              color: '#F93',
            },
          }}
          name="RadioButtons"
        />
      }
      sx={{ '&.MuiListItem-root': listItemClass }}>
      <ListItemText id={id} primary={displayText} className="listItemTxt" />
    </ListItem>
  );
};

export default ListItemRadio;
