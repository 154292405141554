import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import styles from './index.module.scss';
import FullPageGridLayout from '../../Layout/FullPageGridLayout';
import { scaleByRatio } from '../../utils/helper';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import {
  localStorageKeys,
  useLocalStorage,
} from '../../utils/localStorageItems';

const rawHtml = `
<div class="WordSection1">
<p
  class="privacyTxt Default"
  align="center"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    text-align: left;
    line-height: normal; 
  "
>
  <span style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    ><span style="mso-spacerun: yes"> </span
    ><span lang="EN-US" style="font-size:18px;
    text-align:left"
    class="privacyTxt"
      >Your Privacy is very important to us.
      <span class="SpellE">Introw</span> uses third party services to
      track analytics data, and provide features such as authentication,
      <span class="GramE">chat</span> and share links.<o:p></o:p></span
  ></span>
</p>

<p
  class="privacyTxt Default"
  align="center"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    text-align: left;
    line-height: normal;
  "
>
  <b
    ><span
      lang="EN-US"
      style="
        font-size: 24px;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
        font-weight:700
      "
      class="privacySecHead"
      >We care about your <span class="GramE">privacy</span></span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 14pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></b>
</p>

<div class="privacy-body privacyTxt">
<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; 
    font-size:18px"
    class="privacyTxtHead";
    >Your privacy is a top priority. Your privacy is at the core of the
    way we design and build the services and products you know and love,
    so that you can fully trust them and focus on building meaningful
    connections.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We appreciate that you put your trust in us when you provide us with
    your information, and we do not take this lightly.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
      class="privacyTxtHead"
      >Our commitment to privacy.</span
    ></b
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
  >
    We design <span class="GramE">all of</span> our products and services
    with your privacy in mind. We involve experts from various fields,
    including legal, security, engineering, product design and others to
    make sure that our decisions are taken with the utmost respect for
    your privacy.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff; font-size: 18px
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
      class="privacyTxtHead"
      >Our commitment to transparency.</span
    ></b
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
  >
    Because we use many of the same online services you do, we know that
    insufficient information and overly complicated language are common
    issues in privacy policies. We take the exact opposite approach:
    we</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff; font-size: 18px;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >re doing our best to write our Privacy Policy and related documents
    in plain language. We <span class="GramE">actually want</span> you to
    read our policies and understand our privacy practices!</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
      class="privacyTxtHead"
      >Our commitment to security.</span
    ></b
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
  >
    We have teams dedicated to keeping your data safe and secure. We
    constantly update our security practices and invest in our security
    efforts to enhance the safety of your information.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff; font-size: 18px
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  align="center"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 0pt;
    margin-left: 0cm;
    text-align: center;
    line-height: normal;
  "
>

</div>
<br />
  <b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
      "
      class="privacyHead2"
      >PRIVACY POLICY</span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></b>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    class="privacyTxt"
    >Welcome to our Privacy Policy. Thank you for taking the time to read
    it.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
      class="privacyTxt"
      >If you are a California resident, please see our California Privacy
      Statement, which supplements this Privacy Policy.</span
    ></b
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    class="privacyTxt"
    >We appreciate that you trust us with your
    <span class="GramE">information</span> and we intend to always keep
    that trust. This starts with making sure you understand the
    information we collect, why we collect it, how it is used and your
    choices regarding your information. This Policy describes our privacy
    practices in plain language, keeping legal and technical jargon to a
    minimum.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    class="privacyTxt"
    >This Privacy Policy applies from July 1, 2023.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    class="privacyTxt"
    >The previous version of this Privacy Policy, available </span
  ><span class="Hyperlink0"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: white;
        mso-style-textfill-fill-color: white;
        mso-style-textfill-fill-alpha: 100%;
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/privacy/2023-01-31"
        ><span
          style="
            color: white;
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: "white";
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%;
              font-size: 18px
            "
            >here</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff;  font-size: 18px"
    >, will apply until then.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default anchorCls"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l5 level1 lfo2;
  "
>
  <![if !supportLists]><span
    lang="DE"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #00EDE2;
      mso-ansi-language: DE;
    "
    ><span style="mso-list: Ignore"
      >1.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><u
    ><span
      lang="DE"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: #00EDE2;
        mso-ansi-language: DE;
      "
      class="anchorCls"
      >WHO WE ARE<o:p></o:p></span
  ></u>
</p>

<p
  class="Default anchorCls"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l5 level1 lfo2;
  "
>
  <![if !supportLists]><span
    lang="DE"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #00EDE2;
      mso-ansi-language: DE;
    "
    ><span style="mso-list: Ignore"
      >2.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><u
    ><span
      lang="DE"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: #00EDE2;
        mso-ansi-language: DE;
      "
      >WHERE THIS PRIVACY POLICY APPLIES<o:p></o:p></span
  ></u>
</p>

<p
  class="Default anchorCls"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l5 level1 lfo2;
  "
>
  <![if !supportLists]><span
    lang="DE"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #00EDE2;
      mso-ansi-language: DE;
    "
    ><span style="mso-list: Ignore"
      >3.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><u
    ><span
      lang="DE"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: #00EDE2;
        mso-ansi-language: DE;
      "
      >INFORMATION WE COLLECT<o:p></o:p></span
  ></u>
</p>

<p
  class="Default anchorCls"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l5 level1 lfo2;
  "
>
  <![if !supportLists]><span
    lang="DE"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #00EDE2;
      mso-ansi-language: DE;
    "
    ><span style="mso-list: Ignore"
      >4.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><u
    ><span
      lang="DE"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: #00EDE2;
        mso-ansi-language: DE;
      "
      >HOW WE USE INFORMATION<o:p></o:p></span
  ></u>
</p>

<p
  class="Default anchorCls"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l5 level1 lfo2;
  "
>
  <![if !supportLists]><span
    lang="DE"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #00EDE2;
      mso-ansi-language: DE;
    "
    ><span style="mso-list: Ignore"
      >5.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><u
    ><span
      lang="DE"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: #00EDE2;
        mso-ansi-language: DE;
      "
      >HOW WE SHARE INFORMATION<o:p></o:p></span
  ></u>
</p>

<p
  class="Default anchorCls"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l5 level1 lfo2;
  "
>
  <![if !supportLists]><span
    lang="DE"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #00EDE2;
      mso-ansi-language: DE;
    "
    ><span style="mso-list: Ignore"
      >6.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><u
    ><span
      lang="DE"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: #00EDE2;
        mso-ansi-language: DE;
      "
      >HOW <span class="SpellE">Introw</span>
      <span class="SpellE">inc</span> COMPANIES WORK TOGETHER<o:p
      ></o:p></span
  ></u>
</p>

<p
  class="Default anchorCls"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l5 level1 lfo2;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #00EDE2;
    "
    ><span style="mso-list: Ignore"
      >7.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><u
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #00EDE2"
      >YOUR RIGHTS<o:p></o:p></span
  ></u>
</p>

<p
  class="Default anchorCls"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l5 level1 lfo2;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #00EDE2;
    "
    ><span style="mso-list: Ignore"
      >8.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><u
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #00EDE2"
      >HOW LONG WE RETAIN YOUR INFORMATION<o:p></o:p></span
  ></u>
</p>

<p
  class="Default anchorCls"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l5 level1 lfo2;
  "
>
  <![if !supportLists]><span
    lang="DE"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #00EDE2;
      mso-ansi-language: DE;
    "
    ><span style="mso-list: Ignore"
      >9.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><u
    ><span
      lang="DE"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: #00EDE2;
        mso-ansi-language: DE;
      "
      >CHILDREN</span
    ></u
  ><span dir="RTL"></span
  ><u
    ><span
      lang="AR-SA"
      dir="RTL"
      style="
        font-family: 'Arial Unicode MS', sans-serif;
        mso-ascii-font-family: 'Myriad Pro';
        mso-hansi-font-family: 'Myriad Pro';
        color: #00EDE2;
      "
      class="anchorCls"
      ><span dir="RTL"></span>’</span
    ></u
  ><u
    ><span
      lang="PT"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: #00EDE2;
        mso-ansi-language: PT;
      "
      class="anchorCls"
      >S PRIVACY</span
    ></u
  ><u
    ><span
      lang="DE"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: #00EDE2;
        mso-ansi-language: DE;
      "
      ><o:p></o:p></span
  ></u>
</p>

<p
  class="Default anchorCls"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l5 level1 lfo2;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #00EDE2;
    "
    ><span style="mso-list: Ignore"
      >10.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><u
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #00EDE2"
      >JOB CANDIDATES, <span class="GramE">CONTRACTORS</span> AND VENDOR
      REPRESENTATIVES<o:p></o:p></span
  ></u>
</p>

<p
  class="Default anchorCls"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l5 level1 lfo2;
  "
>
  <![if !supportLists]><span
    lang="ES-TRAD"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #00EDE2;
      mso-ansi-language: ES-TRAD;
    "
    ><span style="mso-list: Ignore"
      >11.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><u
    ><span
      lang="ES-TRAD"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: #00EDE2;
        mso-ansi-language: ES-TRAD;
      "
      >PRIVACY POLICY CHANGES<o:p></o:p></span
  ></u>
</p>

<p
  class="Default anchorCls"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l5 level1 lfo2;
  "
>
  <![if !supportLists]><span
    lang="DE"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #00EDE2;
      mso-ansi-language: DE;
    "
    ><span style="mso-list: Ignore"
      >12.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><u
    ><span
      lang="DE"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: #00EDE2;
        mso-ansi-language: DE;
      "
      >HOW TO CONTACT US<o:p></o:p></span
  ></u>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="DE"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
        mso-ansi-language: DE;
      "
      class="privacySubTxt"
      >1. WHO WE ARE</span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >If you live in the United States or Canada, the company that is
    responsible for your information under this Privacy Policy is:</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      font-size: 18px;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p class="privacyTxt Default" style="margin-top: 0cm; line-height: normal">
  <span
    lang="EN-US"
    style="font-size: 13pt; font-family: 'Myriad Pro', sans-serif"
    class="privacyTxt"
    ><span style="mso-spacerun: yes">  </span
    ><span class="SpellE" style={{font-size: 18px}}>Introw</span>
    <span class="SpellE" style={{font-size: 18px}}>inc</span>,<span style="mso-spacerun: yes"
      >    
    </span></span
  ><span
    lang="EN-US"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Courier;
      mso-bidi-font-family: Courier;
    "
    ><o:p></o:p
  ></span>
</p>

<p class="privacyTxt Default" style="margin-top: 0cm; line-height: normal">
  <span
    lang="EN-US"
    style="font-size: 13pt; font-family: 'Myriad Pro', sans-serif"
    class="privacyTxt"
    ><span style="mso-spacerun: yes"> </span
    ><span style="mso-spacerun: yes" class="privacyTxt"> </span>Sag Harbor </span
  ><span
    lang="EN-US"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Courier;
      mso-bidi-font-family: Courier;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff" class="privacyTxt"
    >If you live outside the United States or Canada, the company
    responsible for your information is:</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p class="privacyTxt Default" style="margin-top: 0cm; line-height: normal">
  <span
    lang="EN-US"
    style="font-size: 13pt; font-family: 'Myriad Pro', sans-serif"
    class="privacyTxt"
    ><span style="mso-spacerun: yes">  </span>Introw.com Global Services
    Limited</span
  ><span
    lang="EN-US"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Courier;
      mso-bidi-font-family: Courier;
    "
    ><o:p></o:p
  ></span>
</p>

<p class="privacyTxt Default" style="margin-top: 0cm; line-height: normal">
  <span
    lang="EN-US"
    style="font-size: 13pt; font-family: 'Myriad Pro', sans-serif"
    class="privacyTxt"
    ><span style="mso-spacerun: yes">  </span
    ><span class="SpellE">Introw</span></span
  ><span
    lang="EN-US"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Courier;
      mso-bidi-font-family: Courier;
    "
    ><o:p></o:p
  ></span>
</p>

<p class="privacyTxt Default" style="margin-top: 0cm; line-height: normal">
  <span
    lang="EN-US"
    style="font-size: 13pt; font-family: 'Myriad Pro', sans-serif"
    class="privacyTxt"
    ><span style="mso-spacerun: yes">  </span>8750 N. Central Expressway,
    Suite 1400</span
  ><span
    lang="EN-US"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Courier;
      mso-bidi-font-family: Courier;
    "
    ><o:p></o:p
  ></span>
</p>

<p class="privacyTxt Default" style="margin-top: 0cm; line-height: normal">
  <span
    lang="IT"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-ansi-language: IT;
    "
    class="privacyTxt"
    ><span style="mso-spacerun: yes">  </span>Dallas, TX 75231</span
  ><span
    lang="EN-US"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Courier;
      mso-bidi-font-family: Courier;
    "
    ><o:p></o:p
  ></span>
</p>

<p class="privacyTxt Default" style="margin-top: 0cm; line-height: normal">
  <span
    lang="EN-US"
    style="font-size: 13pt; font-family: 'Myriad Pro', sans-serif"
    ><span style="mso-spacerun: yes">  </span></span
  ><span
    lang="EN-US"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Courier;
      mso-bidi-font-family: Courier;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="DE"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
        mso-ansi-language: DE;
      "
      class="privacySubTxt"
      >2. WHERE THIS PRIVACY POLICY APPLIES</span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></b>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    class="privacyTxt"
    >This Privacy Policy applies to websites, apps,
    <span class="GramE">events</span> and other services we operate under
    the brand <span class="SpellE">Introw</span>. For simplicity, we refer
    to all of these as our </span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
      mso-ansi-language: AR-SA;
    "
    ><span dir="RTL"></span>“</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    >services” in this Privacy Policy. To make it extra clear, we</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span class="SpellE"
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff"
      >ve</span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
  >
    added links to this Privacy Policy on all applicable services.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    class="privacyTxt"
    >Some services may require their own unique privacy policy. If a
    service has its own privacy policy, then that policy -- not this
    Privacy Policy -- applies.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="DE"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
        mso-ansi-language: DE;
      "
      class="privacySubTxt"
      >3. INFORMATION WE COLLECT</span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    >It goes without saying, we can</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    >t help you develop meaningful connections without some information
    about you, such as basic profile details and the types of people
    you</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    >d like to meet. We also collect information about your use of our
    services such as access logs, as well as information from third
    parties, like when you access our services through your social media
    account or when you upload information from your social media account
    to complete your profile. If you want additional info, we go into more
    detail below.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><i
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff"
        >Information you give <span class="GramE">us</span></span
      ></i
    ></b
  ><b
    ><i
      ><span
        lang="EN-US"
        style="
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><o:p></o:p></span></i
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >You choose to give us certain information when using our services.
    This includes:</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >When you create an account, you provide us with at least your login
    credentials and/or phone number and email address, as well as some
    basic details necessary for the service to work, such as your gender,
    date of birth, and who you</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >d like to connect with.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >When you complete your profile, you have the option to share your
    religion, <span class="GramE">ethnicity</span> and political views
    with us. Where you provide such elements to us, you consent to us
    using it for the purposes identified and as laid out in this Privacy
    Policy. You can also share with us additional information, such as
    details on your personality, lifestyle,
    <span class="GramE">interests</span> and other details about you, as
    well as content such as photos, videos and your bio. To add certain
    content, like pictures or videos, you may allow us to access your
    camera or photo album.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >When you subscribe to a paid service or make a purchase directly from
    us (rather than through a platform such as iOS or Android), you
    provide us with information related to the purchases you make and our
    payment processor with information, such as your debit or credit card
    number or other financial information.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >When you participate in surveys, focus groups or market studies, you
    give us your insights into our products and services, responses to our
    questions and testimonials.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >When you choose to participate in our promotions,
    <span class="GramE">events</span> or contests, we collect the
    information that you use to register or enter.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >If you contact our customer care team, we collect the information you
    give us during the interaction.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >If you share with us information about other people (for example, if
    you use contact details of a friend for a given feature), we process
    this information on your behalf
    <span class="GramE">in order to</span> complete your request.<o:p
    ></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Of course, we also process your chats with other users as well as the
    content you publish to operate and secure the
    <span class="GramE">services, and</span> keep our community safe.<o:p
    ></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><i
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >Information we receive from
        <span class="GramE">others</span></span
      ></i
    ></b
  ><b
    ><i
      ><span
        lang="EN-US"
        style="
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><o:p></o:p></span></i
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >In addition to the <span class="GramE">information</span> you may
    provide us directly, we receive information about you from others,
    including:</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 29.75pt;
    text-indent: -18.75pt;
    line-height: normal;
    mso-list: l2 level1 lfo5;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-size: 16pt;
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-bidi-font-weight: bold;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          color: #fff;
        "
        >Users</span
      ></b
    ></span
  ><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><br /> </span></b></span
  ><span class="SpellE"
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff"
      >Users</span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
  >
    may provide information about you as they use our services, for
    instance as they interact with you or if they submit a report
    involving you.</span
  ><span
    lang="EN-US"
    style="
      font-size: 16pt;
      font-family: 'Myriad Pro', sans-serif;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 29.75pt;
    text-indent: -18.75pt;
    line-height: normal;
    mso-list: l2 level1 lfo5;
  "
>
  <![if !supportLists]><span
    lang="PT"
    style="
      font-size: 16pt;
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-ansi-language: PT;
      mso-bidi-font-weight: bold;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><b
      ><span
        lang="PT"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          color: #fff;
          mso-ansi-language: PT;
        "
        >Social Media</span
      ></b
    ></span
  ><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><br /> </span></b></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >You may decide to share information with us through your social media
    account, for instance if you decide to create or log into your account
    via your social media or other account (e.g., Facebook, Google or
    Apple) or to upload onto our services information such as photos from
    one of your social media accounts (e.g., Facebook or Instagram).</span
  ><span
    lang="PT"
    style="
      font-size: 16pt;
      font-family: 'Myriad Pro', sans-serif;
      color: #fff;
      mso-ansi-language: PT;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 29.75pt;
    text-indent: -18.75pt;
    line-height: normal;
    mso-list: l2 level1 lfo5;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-size: 16pt;
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-bidi-font-weight: bold;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          color: #fff;
        "
        >Other Partners</span
      ></b
    ></span
  ><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><br /> </span></b></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We may receive information about you from our partners, including
    when accounts can be created through a partner</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >s service (in which case they pass along registration information to
    us) or where our ads are published on a partner</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >s service (in which case they may pass along details on a
    campaign</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >s success). Where legally allowed, we can also receive information
    about suspected or convicted bad actors from third parties as part of
    our efforts to ensure our users</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff; font-size: 18px
    "
    ><span dir="RTL"></span>’ </span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >safety and security.</span
  ><span
    lang="EN-US"
    style="
      font-size: 16pt;
      font-family: 'Myriad Pro', sans-serif;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><i
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >Information generated or automatically collected when you use our
        <span class="GramE">services</span></span
      ></i
    ></b
  ><b
    ><i
      ><span
        lang="EN-US"
        style="
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><o:p></o:p></span></i
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >When you use our services, this generates technical data about which
    features you</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span class="SpellE"
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
      >ve</span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
  >
    used, how you</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span class="SpellE"
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
      >ve</span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
  >
    used them and the devices you use to access our services. See below
    for more details:</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 29.75pt;
    text-indent: -18.75pt;
    line-height: normal;
    mso-list: l2 level1 lfo5;
  "
>
  <![if !supportLists]><span
    lang="FR"
    style="
      font-size: 16pt;
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-ansi-language: FR;
      mso-bidi-font-weight: bold;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><b
      ><span
        lang="FR"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          color: #fff;
          mso-ansi-language: FR;
        "
        >Usage Information</span
      ></b
    ></span
  ><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><br /> </span></b></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Using the services generates data about your activity on our
    services, for instance how you use them (e.g., when you logged in,
    features you</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span class="SpellE"
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff"
      >ve</span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
  >
    been using, actions taken, information shown to you, referring
    webpages address and ads that you interacted with) and your
    interactions with other users (e.g., users you connect and interact
    with, when you <span class="SpellE">introwed</span> and exchanged
    messages with them).</span
  ><span
    lang="FR"
    style="
      font-size: 16pt;
      font-family: 'Myriad Pro', sans-serif;
      color: #fff;
      mso-ansi-language: FR;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 29.75pt;
    text-indent: -18.75pt;
    line-height: normal;
    mso-list: l2 level1 lfo5;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-size: 16pt;
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-bidi-font-weight: bold;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          color: #fff
        "
        >Device Information</span
      ></b
    ></span
  ><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><br /> </span></b></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We collect information from and about the device(s) you use to access
    our services, including hardware and software information such as IP
    address, device ID and type, apps settings and characteristics, app
    crashes, advertising IDs (which are randomly generated numbers that
    you can reset by going into your device</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >s settings), and identifiers associated with cookies or other
    technologies that may uniquely identify a device or browser.</span
  ><span
    lang="EN-US"
    style="
      font-size: 16pt;
      font-family: 'Myriad Pro', sans-serif;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 29.75pt;
    text-indent: -18.75pt;
    line-height: normal;
    mso-list: l2 level1 lfo5;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-size: 16pt;
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-bidi-font-weight: bold;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          color: #fff;
        "
        >Information collected by cookies and similar technologies</span
      ></b
    ></span
  ><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><br /> </span></b></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We use and may allow others to use cookies and similar technologies
    (e.g., web beacons, pixels, SDKs) to recognize you and/or your
    device(s). You may read our Cookie Policy for more information on why
    we use them and how you can better control their use.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><br /> </span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Some web browsers (including Safari, Internet Explorer,
    <span class="GramE">Firefox</span> and Chrome) have a </span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
      mso-ansi-language: AR-SA;
    "
    ><span dir="RTL"></span>“</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Do Not Track” (</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
      mso-ansi-language: AR-SA;
    "
    ><span dir="RTL"></span>“</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >DNT”) feature that tells a website that a user does not want to have
    his or her online activity tracked. If a website that responds to a
    DNT signal receives a DNT signal, the browser can block that website
    from collecting certain information about the browser</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >s user. Not all browsers offer a DNT option and DNT signals are not
    yet uniform. For this reason, many businesses, including ours, do not
    currently respond to DNT <span class="GramE">signals..</span></span
  ><span
    lang="EN-US"
    style="
      font-size: 16pt;
      font-family: 'Myriad Pro', sans-serif;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 29.75pt;
    text-indent: -18.75pt;
    line-height: normal;
    mso-list: l2 level1 lfo5;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-size: 16pt;
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-bidi-font-weight: bold;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          color: #fff; font-size: 18px
        "
        >Other information with your consent</span
      ></b
    ></span
  ><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><br /> </span></b></span
  ><span class="None"
    ><b
      ><span
        lang="IT"
        style="
          font-family: 'Myriad Pro', sans-serif;
          color: #fff;
          mso-ansi-language: IT; font-size: 18px
        "
        >Precise <span class="SpellE">geolocation</span> data:
      </span></b
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >If you give us your consent, we can collect your precise geolocation
    (latitude and longitude) from your device. The collection of your
    geolocation may occur in the background even when you
    <span class="SpellE">aren</span></span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro'; font-size: 18px
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >t using the services if the permission you gave us expressly permits
    such collection. If you decline permission for us to collect your
    precise geolocation, we will not collect it, and our services that
    rely on precise geolocation may not be available to you. </span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><br /> </span
  ><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff"
        >Other information:
      </span></b
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We may collect other information with your permission such as photos
    and videos (for instance, if you want to publish a photo or video or
    participate in streaming features on our services).</span
  ><span
    lang="EN-US"
    style="
      font-size: 16pt;
      font-family: 'Myriad Pro', sans-serif;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="DE"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
        mso-ansi-language: DE;
      "
      class="privacySubTxt"
      >4. HOW WE USE INFORMATION</span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >The main reason we use your information is to deliver and improve our
    services. Additionally, we use your information to help keep you safe,
    and to provide you with advertising that may be of interest to you.
    Read on for a more detailed explanation of the various reasons for
    which we use your information, together with practical examples.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><i
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >A. To administer your account and provide our services to
        <span class="GramE">you</span></span
      ></i
    ></b
  ><b
    ><i
      ><span
        lang="EN-US"
        style="
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><o:p></o:p></span></i
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Create and manage your <span class="GramE">account</span><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Provide you with customer support and respond to your
    <span class="GramE">requests</span><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Personalize pricing, offer discounts and other promotions, and
    complete your <span class="GramE">transactions</span><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Communicate with you about our <span class="GramE">services</span
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Administer sweepstakes and <span class="GramE">contests</span
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><i
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >B. To help you connect with other
        <span class="GramE">users</span></span
      ></i
    ></b
  ><b
    ><i
      ><span
        lang="EN-US"
        style="
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><o:p></o:p></span></i
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Recommend you other users and recommend other users to
    <span class="GramE">you</span><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Show users</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’ </span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >profiles to <span class="GramE">one another</span><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Enable users to search for and connect with
    <span class="GramE">users</span><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><i
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >C. To provide offers and operate advertising and marketing
        <span class="GramE">campaigns</span></span
      ></i
    ></b
  ><b
    ><i
      ><span
        lang="EN-US"
        style="
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><o:p></o:p></span></i
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Perform and measure the effectiveness of advertising campaigns on our
    services and marketing our services off our
    <span class="GramE">platform</span><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Communicate with you about products or services that we believe may
    interest <span class="GramE">you</span><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><i
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >D. To improve our services and develop new
        <span class="GramE">ones</span></span
      ></i
    ></b
  ><b
    ><i
      ><span
        lang="EN-US"
        style="
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><o:p></o:p></span></i
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Administer focus groups, market studies and
    <span class="GramE">surveys</span><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Review interactions with customer care teams to improve our quality
    of <span class="GramE">service</span><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Understand how users typically use the services to improve them (for
    instance, we may decide to change the look and feel or even
    substantially modify a given feature based on how users react to
    it)<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Develop new features and services (for example, we may decide to
    build a new interests-based feature further to requests received from
    users).<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><i
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >E. To prevent, detect and fight fraud and other illegal or
        unauthorized <span class="GramE">activities</span></span
      ></i
    ></b
  ><b
    ><i
      ><span
        lang="EN-US"
        style="
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><o:p></o:p></span></i
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Find and address ongoing, <span class="GramE">suspected</span> or
    alleged violations of our </span
  ><span class="Hyperlink0"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: white;
        mso-style-textfill-fill-color: white;
        mso-style-textfill-fill-alpha: 100%;
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/terms"
        ><span
          style="
            color: white;
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: white;
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%;
            "
            >Terms of Use</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >, notably through the review of reports and interactions between
    users.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Better understand and design countermeasures against violations of
    our </span
  ><span class="Hyperlink0"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: white;
        mso-style-textfill-fill-color: white;
        mso-style-textfill-fill-alpha: 100%;
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/terms"
        ><span
          style="
            color: white;
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: black;
              mso-style-textfill-fill-color: black;
              font-size: 18px
              mso-style-textfill-fill-alpha: 100%;
            "
            >Terms of Use</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Retain data related to violations of our </span
  ><span class="Hyperlink0"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: white;
        mso-style-textfill-fill-color: white;
        mso-style-textfill-fill-alpha: 100%;
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/terms"
        ><span
          style="
            color: white;
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: white;
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%;
            "
            >Terms of Use</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
  >
    to prevent against <span class="GramE">recurrences</span><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Enforce or exercise our rights, for example our </span
  ><span class="Hyperlink0"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: white;
        mso-style-textfill-fill-color: white;
        mso-style-textfill-fill-alpha: 100%;
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/terms"
        ><span
          style="
            color: white;
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: white;
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%;
            "
            >Terms of Use</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Communicate to users who submit a report, including what we</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span class="SpellE"
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
      >ve</span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
  >
    done <span class="GramE">as a result of</span> their submission.<o:p
    ></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><i
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >F. To ensure legal <span class="GramE">compliance</span></span
      ></i
    ></b
  ><b
    ><i
      ><span
        lang="EN-US"
        style="
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><o:p></o:p></span></i
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Comply with legal <span class="GramE">requirements</span><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Assist law <span class="GramE">enforcement</span><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="DE"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
        mso-ansi-language: DE;
      "
      class="privacySubTxt"
      >5. HOW WE SHARE INFORMATION</span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Since our goal is to help you make meaningful connections, the main
    sharing of users</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’ </span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >information is, of course, with other users. We also share some
    users</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’ </span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >information with service providers and partners who assist us in
    operating the services, with other <span class="SpellE">Introw</span>
    <span class="SpellE">inc</span> companies for specified reasons as
    laid out below and, in some cases, legal authorities. Read on for more
    details about how your information is shared with others.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 29.75pt;
    text-indent: -18.75pt;
    line-height: normal;
    mso-list: l2 level1 lfo6;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-size: 16pt;
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-bidi-font-weight: bold;
      mso-bidi-font-style: italic;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          color: #fff;
          font-size: 18px
        "
        >With other users</span
      ></b
    ></span
  ><span class="None"
    ><b
      ><span
        lang="EN-US"
        style="
          font-size: 16pt;
          font-family: 'Myriad Pro', sans-serif;
          mso-fareast-font-family: Helvetica;
          mso-bidi-font-family: Helvetica;
          color: #fff;
        "
        ><br /> </span></b></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >You share information with other users when you voluntarily disclose
    information on the service (including your public profile). Please be
    careful with your information and make sure that the content you share
    is stuff that you</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >re comfortable being visible.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><br /> </span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >If you choose to limit the audience for all or part of your profile
    or for certain content or information about you, then it will be
    visible according to your settings.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><br /> </span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >If someone submits a report involving you (such as a claim that you
    violated our Terms of Use), we may communicate to the reporter
    actions, if any, we took as a result of their report.</span
  ><span
    lang="EN-US"
    style="
      font-size: 16pt;
      font-family: 'Myriad Pro', sans-serif;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 29.75pt;
    text-indent: -18.75pt;
    line-height: normal;
    mso-list: l2 level1 lfo7;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-bidi-font-weight: bold;
      mso-bidi-font-style: italic;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><b
      ><i
        ><span
          lang="EN-US"
          style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
          >With our service providers and partners</span
        ></i
      ></b
    ></span
  ><span class="None"
    ><b
      ><i
        ><span
          lang="EN-US"
          style="
            font-family: 'Myriad Pro', sans-serif;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #fff;
          "
          ><br /> </span></i></b></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We use vendors to help us operate, distribute,
    <span class="GramE">market</span> and improve our services, such as
    data hosting and maintenance, analytics, customer care, marketing,
    advertising, payment processing and security operations. We also share
    information with vendors who distribute and assist us in advertising
    our services. For instance, we may share limited information on you in
    hashed, non-human readable form to advertising partners.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><br /> </span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We follow a strict vetting process prior to engaging any vendor or
    working with any partner. Our vendors and partners must agree to
    strict confidentiality obligations.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 29.75pt;
    text-indent: -18.75pt;
    line-height: normal;
    mso-list: l2 level1 lfo7;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-bidi-font-weight: bold;
      mso-bidi-font-style: italic;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><b
      ><i
        ><span
          lang="EN-US"
          style="font-family: 'Myriad Pro', sans-serif; color: #fff"
          >For corporate transactions</span
        ></i
      ></b
    ></span
  ><span class="None"
    ><b
      ><i
        ><span
          lang="EN-US"
          style="
            font-family: 'Myriad Pro', sans-serif;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #fff;
          "
          ><br /> </span></i></b></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We may transfer your information if we are involved, whether in whole
    or in part, in a merger, sale, acquisition, divestiture,
    restructuring, reorganization, dissolution, bankruptcy or other change
    of ownership or control.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 29.75pt;
    text-indent: -18.75pt;
    line-height: normal;
    mso-list: l2 level1 lfo7;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-bidi-font-weight: bold;
      mso-bidi-font-style: italic;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><b
      ><i
        ><span
          lang="EN-US"
          style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
          >With law enforcement / when required by law
        </span></i
      ></b
    ></span
  ><span class="None"
    ><b
      ><i
        ><span
          lang="EN-US"
          style="
            font-family: 'Myriad Pro', sans-serif;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #fff;
          "
          ><br /> </span></i></b></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We may disclose your information if reasonably necessary: (<span
      class="SpellE"
      >i</span
    >) to comply with a legal process, such as a court order, subpoena or
    search warrant, government / law enforcement investigation or other
    legal requirements; (ii) to assist in the prevention or detection of
    crime (subject in each case to applicable law); or (iii) to protect
    the safety of any person.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 29.75pt;
    text-indent: -18.75pt;
    line-height: normal;
    mso-list: l2 level1 lfo7;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-bidi-font-weight: bold;
      mso-bidi-font-style: italic;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><b
      ><i
        ><span
          lang="EN-US"
          style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
          >To enforce legal rights</span
        ></i
      ></b
    ></span
  ><span class="None"
    ><b
      ><i
        ><span
          lang="EN-US"
          style="
            font-family: 'Myriad Pro', sans-serif;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #fff;
          "
          ><br /> </span></i></b></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We may also share information: (<span class="SpellE">i</span>) if
    disclosure would mitigate our liability in an actual or threatened
    lawsuit; (ii) as necessary to protect our legal rights and legal
    rights of our users, business partners or other interested parties;
    (iii) to enforce our agreements with you; and (iv) to investigate,
    prevent, or take other action regarding illegal activity, suspected
    fraud or other wrongdoing.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 29.75pt;
    text-indent: -18.75pt;
    line-height: normal;
    mso-list: l2 level1 lfo7;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-bidi-font-weight: bold;
      mso-bidi-font-style: italic;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><b
      ><i
        ><span
          lang="EN-US"
          style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
          >With your consent or at your request</span
        ></i
      ></b
    ></span
  ><span class="None"
    ><b
      ><i
        ><span
          lang="EN-US"
          style="
            font-family: 'Myriad Pro', sans-serif;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #fff;
          "
          ><br /> </span></i></b></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We may ask for your consent to share your information with third
    parties. In any such case, we will make it clear why we want to share
    the information.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We may use and share non-personal information (meaning information
    that, by itself, does not identify who you are such as device
    information, general demographics, general behavioral data,
    geolocation in de-identified form), as well as personal information in
    hashed, non-human readable form, under any of the above circumstances.
    We may also share this information with other
    <span class="SpellE">Introw</span>
    <span class="SpellE">inc</span> companies and third parties (notably
    advertisers) to develop and deliver targeted advertising on our
    services and on websites or applications of third parties, and to
    analyze and report on advertising you see. We may combine this
    information with additional non-personal information or personal
    information in hashed, non-human readable form collected from other
    sources. More information on our use of cookies and similar
    technologies can be found in our </span
  ><span class="Hyperlink0"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: white;
        mso-style-textfill-fill-color: white;
        mso-style-textfill-fill-alpha: 100%;
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/cookiepolicy"
        ><span
          style="
            color: white;
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: white;
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%;
            "
            >Cookie Policy</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    >.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="DE"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
        mso-ansi-language: DE;
      "
      class="privacySubTxt"
      >6. HOW <span class="SpellE">Introw</span>
      <span class="SpellE">inc</span> COMPANIES WORK TOGETHER</span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span class="None"
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
      >We are part of the
    </span></span
  ><span class="Hyperlink1"
    ><span lang="EN-US" style="font-family: 'Myriad Pro', sans-serif"
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://mtch.com/ourcompany"
        ><span style="color: #00EDE2"
          >Match Group family of businesses.</span
        ></a
      ></span
    ></span
  ><span class="None"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We may share information about you with our affiliates and they may
    share information about you with us, for the reasons laid out
    below:</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >To make all <span class="SpellE">Introw</span>
    <span class="SpellE">inc</span> platforms safer, for instance by
    making sure that when a bad actor is found on one
    <span class="SpellE">Introw</span>
    <span class="SpellE">inc</span> platform (for instance ours), they can
    be banned from all.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >To assist each other in data processing operations, as service
    providers. This assistance may include technical processing
    operations, such as data hosting and maintenance, customer care,
    marketing and targeted advertising, analytics, finance and accounting,
    payment processing, service improvement, data security and fighting
    against spam, abuse, fraud,
    <span class="GramE">infringement</span> and other wrongdoings.<o:p
    ></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >To improve your chances at building significant connections with
    others, we may make you visible on other
    <span class="SpellE">Introw</span>
    <span class="SpellE">inc</span> services or allow you to benefit from
    cross-platform functionalities. We will of course comply with
    applicable law and, where relevant, notify you of any such opportunity
    and allow you to agree or to refuse.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >If you <span class="GramE">are located in</span> the USA excluding
    California, Colorado, Utah, Connecticut and Virginia, to personalize
    your experience on <span class="SpellE">Introw</span>
    <span class="SpellE">inc</span> platforms, including how you</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >re shown to other users and how others are shown to you, prices,
    discounts, ads and content you experience, to improve our marketing
    campaigns.<o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >For other legitimate business purposes including corporate audit,
    <span class="GramE">analysis</span> and consolidated reporting.<o:p
    ></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span class="Hyperlink0"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: white;
        mso-style-textfill-fill-color: white;
        mso-style-textfill-fill-alpha: 100%;
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;"
        href="https://help.match.com/hc/en-us/articles/9611271032475-Privacy-Sharing-Information"
        ><span
          style="
            color: white;
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: white;
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%;
              font-size: 18px
            "
            >Learn more</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
  >
    about how <span class="SpellE">Introw</span>
    <span class="SpellE">inc</span> companies work together.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
      "
      class="privacySubTxt"
      >7. YOUR RIGHTS</span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></b>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We want you to be in control of your information, so we want to
    remind you of the following options and tools available to you:</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="Default privacyTxt"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><u
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >Access / Update tools in the service</span
      ></u
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >. Tools and account settings can help you access,
    <span class="GramE">rectify</span> or remove information that you
    provided to us and that</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >s associated with your account directly within the service. If you
    have any questions on those tools and settings, please contact our
    customer care team for help </span
  ><span class="Hyperlink0"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: white;
        mso-style-textfill-fill-color: white;
        mso-style-textfill-fill-alpha: 100%;
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/help/help.aspx"
        ><span
          style="
            color: white;
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: "white";
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%;
              font-size: 18px
            "
            >here</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    >.<o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="IT"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      mso-ansi-language: IT;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><u
      ><span
        lang="IT"
        style="
          font-family: 'Myriad Pro', sans-serif;
          color: #fff;
          mso-ansi-language: IT; font-size: 18px
        "
        >Device <span class="SpellE">permissions</span></span
      ></u
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >. Mobile platforms can have permission systems for specific types of
    device data and notifications, such as phone contacts, pictures,
    location services, push notifications and advertising identifiers. You
    can change your settings on your device to either consent or oppose
    the collection or processing of the corresponding information or the
    display of the corresponding notifications. Of course, if you do that,
    certain services may lose functionality.</span
  ><span
    lang="IT"
    style="
      font-family: 'Myriad Pro', sans-serif;
      color: #fff;
      mso-ansi-language: IT;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><u
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >Uninstall</span
      ></u
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >. You can stop all information collection by an app by uninstalling
    it using the standard uninstall process for your device. Remember that
    uninstalling an app does NOT close your account. To close your
    account, please use the corresponding functionality on the
    <span class="GramE">service</span><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><u
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >Account closure</span
      ></u
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >. You can close your account by using the corresponding functionality
    directly on the service.<o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We also want you to be aware of your privacy rights. Here are a few
    key points to remember:</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><u
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >Access/know</span
      ></u
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >. You may have the right to request a copy of the information we keep
    about you, and in certain circumstances to receive this in a portable
    format. To do so, please contact us </span
  ><span class="Hyperlink0"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: white;
        mso-style-textfill-fill-color: white;
        mso-style-textfill-fill-alpha: 100%;
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://help.match.com/hc/en-us/requests/new"
        ><span
          style="
            color: white;
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: "white";
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%; font-size: 18px
            "
            >here</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    >.<o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><u
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >Delete/erase</span
      ></u
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >. You may request that we delete the personal information we keep
    about you. You can exercise your right to delete by submitting a
    request </span
  ><span class="Hyperlink0"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: white;
        mso-style-textfill-fill-color: white;
        mso-style-textfill-fill-alpha: 100%;
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://help.match.com/hc/en-us/requests/new"
        ><span
          style="
            color: white;
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: "white";
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%; font-size: 18px
            "
            >here</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    >.<o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level1 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span class="None"
    ><u
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >Correct/rectify/<span class="SpellE"
          >update<span style="text-decoration: none; text-underline: none; font-size: 18px"
            >You</span
          ></span
        ></span
      ></u
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
  >
    can correct most information you provided to us by editing your
    profile directly in the service. If you believe the
    <span class="GramE">information</span> we hold about you is
    inaccurate, you may contact us </span
  ><span class="Hyperlink0"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: white;
        mso-style-textfill-fill-color: white;
        mso-style-textfill-fill-alpha: 100%;
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://help.match.com/hc/en-us/requests/new"
        ><span
          style="
            color: white;
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: "white";
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%; font-size: 18px
            "
            >here</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
  >
    to rectify it.<o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span class="None"
    ><u style="text-underline: #fff"
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >Object/restrict</span
      ></u
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
  >
    You may also have the right to object to or request that we restrict
    certain processing. To do so, please contact us </span
  ><span class="Hyperlink0"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: black;
        mso-style-textfill-fill-color: black;
        mso-style-textfill-fill-alpha: 100%; font-size: 18px
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://help.match.com/hc/en-us/requests/new"
        ><span
          style="
            color: "white";
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: "white";
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%;
            "
            >here</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >. For your protection and the protection of
    <span class="GramE">all of</span> our users, we may ask you to provide
    proof of identity before we can answer the above requests.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Keep in mind, we may reject requests, including if we are unable to
    authenticate you, if the request is unlawful or invalid, or if it may
    infringe on trade secrets or intellectual property or the privacy or
    other rights of someone else. If you wish to receive information
    relating to another user, such as a copy of any messages you received
    from them through our service, the other user will have to contact us
    to submit a separate request for their information. We may also ask
    them to provide proof of identity before we can answer the
    request.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Also, we may not be able to accommodate certain requests to object to
    or restrict the processing of personal information, notably where such
    requests would not allow us to provide our service to you anymore. For
    instance, we cannot provide our service if we do not have your date of
    birth and thus cannot ensure that you are 18 years of age or
    older.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >In certain jurisdictions, you have a right to lodge a complaint with
    the appropriate data protection authority if you have concerns about
    how we process your personal information.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >If you are a resident of Virginia, Colorado, or Connecticut, USA, if
    we deny your privacy request, you may be able to appeal by contacting
    us </span
  ><span class="Hyperlink0"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: white;
        mso-style-textfill-fill-color: white;
        mso-style-textfill-fill-alpha: 100%;
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://help.match.com/hc/en-us/requests/new"
        ><span
          style="
            color: black;
            mso-style-textfill-fill-color: black;
            mso-style-textfill-fill-alpha: 100%; font-size: 18px
          "
          ><span
            style="
              color: white;
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%;
            "
            >here</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    >, and explicitly referencing </span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
      mso-ansi-language: AR-SA;
    "
    ><span dir="RTL"></span>“</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Privacy Request Appeal.” If you have concerns about the result of
    your <span class="GramE">appeal</span> you may contact the attorney
    general for your state. Additionally, please note that we do not </span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
      mso-ansi-language: AR-SA;
    "
    ><span dir="RTL"></span>“</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >sell” your personal <span class="GramE">data, or</span> use it for </span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
      mso-ansi-language: AR-SA;
    "
    ><span dir="RTL"></span>“</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >targeted advertising” or </span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
      mso-ansi-language: AR-SA;
    "
    ><span dir="RTL"></span>“</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >profiling” in furtherance of decisions that produce legal or
    similarly significant effects, as those terms are defined by
    applicable law in your state, so no opt-out choice is necessary.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="DE"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
        mso-ansi-language: DE;
      "
      class="privacySubTxt"
      >8. HOW LONG WE RETAIN YOUR INFORMATION</span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></b>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span class="None"
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
      >We keep your personal information only as long as we need it for
      legitimate business purposes (as laid out in
    </span></span
  ><span class="None"
    ><u style="text-underline: #00EDE2"
      ><span
        lang="FR"
        style="
          font-family: 'Myriad Pro', sans-serif;
          color: #00EDE2;
          mso-ansi-language: FR;
        "
        >Section 4</span
      ></u
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >) and as permitted by applicable law. If you decide to stop using our
    services, you can close your account and your profile will stop being
    visible to other users. Note that we will close your account
    automatically if you are inactive for a period of two years. After
    your account is closed, we will delete your personal information, as
    laid out below:</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l3 level1 lfo9;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >1.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >To protect the safety and security of our users, we implement a
    safety retention window of three months following account closure.
    During this period, we keep your information
    <span class="GramE">in the event that</span> it might be necessary to
    investigate unlawful or harmful conducts. The retention of information
    during this safety retention window is based on our legitimate
    interest as well as that of potential third-party victims.<o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 36pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l3 level1 lfo9;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><span style="mso-list: Ignore"
      >2.<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Once the safety retention window elapses, we delete your data and
    only keep limited information for specified purposes, as laid out
    below:<o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 72pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level2 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      position: relative;
      top: 1pt;
      mso-text-raise: -1pt;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We maintain limited data to comply with legal data retention
    obligations: in particular, we keep transaction data to comply with
    tax and accounting legal requirements, credit card information, if
    any, for the duration the user may challenge the transaction and </span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
      mso-ansi-language: AR-SA;
    "
    ><span dir="RTL"></span>“</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >traffic data” / logs for one year to comply with legal data retention
    obligations. We also keep records of consents users give us for five
    years to evidence our compliance with applicable law.<o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 72pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level2 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      position: relative;
      top: 1pt;
      mso-text-raise: -1pt;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We maintain limited information on the basis of our legitimate
    interest: we keep customer care records and supporting data as well as
    imprecise location of download/purchase for five years to support our
    customer care decisions, enforce our rights and enable us to defend
    ourselves in the event of a claim, profile data for one year in
    anticipation of potential litigation, for the establishment, exercise
    or defense of legal claims, and data necessary to prevent users who
    were banned or people who were found to be under the age of 18 from
    opening a new account, to ensure the safety and vital interests of our
    users.<o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 10pt;
    margin-left: 72pt;
    text-indent: -25pt;
    line-height: normal;
    mso-list: l2 level2 lfo4;
  "
>
  <![if !supportLists]><span
    lang="EN-US"
    style="
      font-family: Helvetica;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
      position: relative;
      top: 1pt;
      mso-text-raise: -1pt;
    "
    ><span style="mso-list: Ignore"
      >•<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ></span
  ><![endif]><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Finally, we maintain information on the basis of our legitimate
    interest where there is an outstanding or potential issue, claim or
    dispute requiring us to keep information (in particular if we receive
    a valid legal subpoena or request asking us to preserve data (in which
    case we would need to keep the data to comply with our legal
    obligations) or if data would otherwise be necessary as part of legal
    proceedings).<o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="DE"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
        mso-ansi-language: DE;
      "
      class="privacySubTxt"
      >9. CHILDREN'S PRIVACY</span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></b>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Our services are restricted to individuals who are 18 years of age or
    older. We do not permit individuals under the age of 18 on our
    platform. If you suspect that a user is under the age of 18, please
    use the reporting mechanism available on the service.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
      "
      class="privacySubTxt"
      >10. JOB CANDIDATES, <span class="GramE anchorCls">CONTRACTORS</span> AND
      VENDOR REPRESENTATIVES</span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></b>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >We also process the personal information of our job candidates,
    <span class="GramE">contractors</span> and vendor representatives, as
    part of our recruitment and talent management operations and our
    management of the services that contractors and vendors provide to us.
    If you are a job candidate, contractor or vendor representative of
    ours, certain relevant terms of this Privacy Policy apply to our
    processing of your personal information, including the sections of
    this Privacy Policy that discuss </span
  ><span class="None"
    ><u style="text-underline: #00EDE2"
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >the entity that is responsible for the processing of your
        personal information</span
      ></u
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff"
    >, </span
  ><span class="None"
    ><u style="text-underline: #00EDE2"
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >rights you may have under applicable law</span
      ></u
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >, </span
  ><span class="None"
    ><u style="text-underline: #00EDE2"
      ><span
        lang="EN-US"
        style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
        >how to contact us</span
      ></u
    ></span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: "#fff"; font-size: 18px"
  >
    and </span
  ><span class="Hyperlink2"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: black;
        mso-style-textfill-fill-color: black;
        mso-style-textfill-fill-alpha: 100%; font-size: 18px
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;"
        href="https://www.match.com/legalpolicy/CaliforniaPrivacyStatement"
        ><span
          style="
            color: white;
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: white;
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%;
            "
            >California-specific information</span
          ></span
        ></a
      ></span
    ></span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >If you are a job applicant, the personal information we process about
    you may vary depending on the job you seek but typically includes what
    you provide to us as part of your job application as well as
    professional qualifications, <span class="GramE">background</span> and
    reference information that recruiters or other third parties share
    with us. We use this information to support the recruitment process,
    which may lead to an employment contract. For contractors and vendor
    representatives, we may process identification information and
    work-related information, as necessary to manage our relationship with
    you and your employer, which is necessary for the performance of the
    services agreement, and to establish, exercise or defend potential
    legal claims. We may share personal information with service providers
    that assist us with recruitment and technical data processing
    operations as well as with <span class="SpellE">Introw</span>
    <span class="SpellE">inc</span> companies (for instance if you have a
    business relationship with employees of an affiliate). We keep your
    personal information only as long as necessary for those
    purposes.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="ES-TRAD"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
        mso-ansi-language: ES-TRAD;
      "
      class="privacySubTxt"
      >11. PRIVACY POLICY CHANGES</span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></b>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >Because we</span
  ><span dir="RTL"></span
  ><span
    lang="AR-SA"
    dir="RTL"
    style="
      font-family: 'Arial Unicode MS', sans-serif;
      mso-ascii-font-family: 'Myriad Pro';
      mso-hansi-font-family: 'Myriad Pro';
      color: #fff;
    "
    ><span dir="RTL"></span>’</span
  ><span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >re always looking for new and innovative ways to help you build
    meaningful connections and strive to make sure explanations about our
    data practices remain <span class="GramE">up-to-date</span>, this
    policy may change over time. We will notify you before any material
    changes take effect so that you have time to review the changes.</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="DE"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
        mso-ansi-language: DE;
      "
      class="privacySubTxt"
      >12. HOW TO CONTACT US</span
    ></b
  ><b
    ><span
      lang="EN-US"
      style="
        font-size: 16pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: "#fff";
      "
      ><o:p></o:p></span
  ></b>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >If you have questions about this Privacy Policy, you have different
    options below:</span
  ><span
    lang="EN-US"
    style="
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Helvetica;
      mso-bidi-font-family: Helvetica;
      color: #fff;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
      >If you live in the United States or Canada:</span
    ></b
  ><span class="None"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="DE"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: #fff;
        mso-ansi-language: DE; font-size: 18px
      "
      >Online:</span
    ></b
  ><span class="None"
    ><span
      lang="DE"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
    >
    </span></span
  ><span class="Hyperlink3"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        color: black;
        mso-style-textfill-fill-color: black;
        mso-style-textfill-fill-alpha: 100%; font-size: 18px
      "
      ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="mailto:matchprivacy@match.com"
        ><span
          style="
            color: white;
            mso-style-textfill-fill-color: white;
            mso-style-textfill-fill-alpha: 100%;
          "
          ><span
            style="
              color: "white";
              mso-style-textfill-fill-color: white;
              mso-style-textfill-fill-alpha: 100%;
            "
            >here</span
          ></span
        ></a
      ></span
    ></span
  ><span class="None"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
      >By post:</span
    ></b
  ><span class="None"
    ><span
      lang="EN-US"
      style="
        font-size: 13pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Courier;
        mso-bidi-font-family: Courier;
      "
      ><o:p></o:p></span
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-size: 18px; font-family: 'Myriad Pro', sans-serif"
    ><span style="mso-spacerun: yes">  </span>Data Protection
    Officer</span
  ><span
    lang="EN-US"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Courier;
      mso-bidi-font-family: Courier;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-size: 13pt; font-family: 'Myriad Pro', sans-serif; font-size: 18px"
    ><span style="mso-spacerun: yes"> </span
    ><span class="SpellE">Introw</span>
    <span class="SpellE">inc</span></span
  ><span
    lang="EN-US"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Courier;
      mso-bidi-font-family: Courier;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-size: 13pt; font-family: 'Myriad Pro', sans-serif"
    ><span style="mso-spacerun: yes">  </span></span
  ><span
    lang="EN-US"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Courier;
      mso-bidi-font-family: Courier;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
      >If you live outside the United States or Canada:</span
    ></b
  ><span class="None"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p></o:p></span
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span class="None"
    ><span
      lang="EN-US"
      style="
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Helvetica;
        mso-bidi-font-family: Helvetica;
        color: #fff;
      "
      ><o:p>&nbsp;</o:p></span
    ></span
  >
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <b
    ><span
      lang="EN-US"
      style="font-family: 'Myriad Pro', sans-serif; color: #fff; font-size: 18px"
      >By post:</span
    ></b
  ><span class="None"
    ><span
      lang="EN-US"
      style="
        font-size: 13pt;
        font-family: 'Myriad Pro', sans-serif;
        mso-fareast-font-family: Courier;
        mso-bidi-font-family: Courier;
      "
      ><o:p></o:p></span
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-size: 13pt; font-family: 'Myriad Pro', sans-serif; font-size: 18px"
    ><span style="mso-spacerun: yes">  </span>Data Protection
    Officer</span
  ><span
    lang="EN-US"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Courier;
      mso-bidi-font-family: Courier;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-size: 13pt; font-family: 'Myriad Pro', sans-serif; font-size: 18px"
    ><span style="mso-spacerun: yes">  </span
    ><span class="SpellE">Introw</span>
    <span class="SpellE">inc</span></span
  ><span
    lang="EN-US"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Courier;
      mso-bidi-font-family: Courier;
    "
    ><o:p></o:p
  ></span>
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="
      font-size: 13pt;
      font-family: 'Myriad Pro', sans-serif;
      mso-fareast-font-family: Courier;
      mso-bidi-font-family: Courier;
    "
    ><o:p>&nbsp;</o:p></span
  >
</p>

<p
  class="privacyTxt Default"
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 4pt;
    margin-left: 0cm;
    line-height: normal;
  "
>
  <span
    lang="EN-US"
    style="font-size: 13pt; font-family: 'Myriad Pro', sans-serif"
    ><span style="mso-spacerun: yes">  </span></span
  ><span lang="EN-US" style="font-family: 'Myriad Pro', sans-serif"
    ><o:p></o:p
  ></span>
</p>
</div>
`;

const PrivacyPolicy = ({
  onAcceptClicked,
  showAcceptButton = true,
  showCrossButton = false,
  maxheight = 55,
  showPageName = false,
  showBackbutton = false,
  onBackClicked,
}) => {
  const nav = useNavigate();
  // const myProfileID = localStorage.getLocalStorageItem(
  //   localStorageKeys.profileId,
  // );
  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };
  return (
    <>
      <FullPageGridLayout>
        <Grid container direction="row" alignItems="flex-end" xs={12}>
          {showBackbutton && (
            <Grid item justifyContent="left" alignItems="flex-end" xs={12}>
              <Typography
                textAlign="left"
                className={styles.back}
                onClick={onBackClicked}
                style={{
                  fontSize: `${scaleByRatio(16)}px`,
                  paddingRight: `${scaleByRatio(10)}px`,
                  paddingBottom: `${scaleByRatio(5)}px`,
                  float: `left`,
                }}>
                <img
                  src={ArrowLeft}
                  alt={'Back icon'}
                  className={styles.logo}
                  onClick={onBackClicked}
                  width={scaleByRatio(24)}
                  height={scaleByRatio(24)}
                />
              </Typography>
              <Typography
                textAlign="left"
                className={styles.back}
                onClick={onBackClicked}
                style={{
                  fontSize: `${scaleByRatio(16)}px`,
                  paddingLeft: `${scaleByRatio(10)}px`,
                  paddingBottom: `${scaleByRatio(5)}px`,
                  paddingTop: `${scaleByRatio(5)}px`,
                }}>
                Back
              </Typography>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="flex-end"
            xs={12}>
            <Typography
              textAlign="left"
              className={styles.pageName}
              style={{
                fontSize: `${scaleByRatio(32)}px`,
                // color:'#04597B'
                // color: '#fff',
                color: '#76d9ff',
              }}>
              Privacy Policy
            </Typography>
          </Grid>

          {showCrossButton && (
            <Grid
              display={'flex'}
              justifyContent={'flex-end'}
              color={'#fff'}
              // marginTop={'-35px'}
              // marginRight={'40px'}
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                width: '100%',
                marginTop: '-25px',
              }}
              onClick={handleClose}>
              <Typography color={'#fff'}>X</Typography>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end"
          className={styles.main}
          style={{ maxHeight: `${maxheight}vh`, color: '#fff' }}>
          <div dangerouslySetInnerHTML={{ __html: rawHtml }}></div>
        </Grid>
        {/* {showAcceptButton && (
            <Grid container direction="row" justifyContent="right" alignItems="flex-end" style={{
                marginRight: "30%",
               position: "fixed",
               zIndex: "100",
               marginTop: "50vh"
            }}>
                <Grid item >
                    <CustomButton 
                    text={'Accept'}
                    variant={ButtonVariant.contained}
                    type={ButtonType.primary}
                    onClick={()=>{onAcceptClicked()}}
                    />
                </Grid>
            </Grid>
            )} */}
      </FullPageGridLayout>
      {showAcceptButton && (
        <Grid
          container
          direction="row"
          justifyContent="right"
          alignItems="flex-start"
          style={{
            marginRight: '10%',
            display: `flex`,
            // position: "fixed",
            //zIndex: "100",
            marginTop: '-3%',
          }}>
          <Grid item>
            <CustomButton
              text={'Accept'}
              variant={ButtonVariant.contained}
              type={ButtonType.primary}
              onClick={() => {
                onAcceptClicked();
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PrivacyPolicy;
