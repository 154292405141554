import { useEffect } from 'react'
import OneSignal from 'react-onesignal'
import { ONESIGNAL_APP_ID } from '../constants'

const usePushNotification = () => {


  useEffect(() => {

    console.log("web push notification called")
    OneSignal.init({
      appId: ONESIGNAL_APP_ID || '',
      // eslint-disable-next-line camelcase
      // safari_web_id: ONESIGNAL_APP_ID || '',
      //   notifyButton: {
      //     enable: true,
      //   },
      allowLocalhostAsSecureOrigin: true,
      promptOptions: {
        actionMessage: 'Allow notification to get update on all activities.',
      },
    }).then(() => {
      OneSignal.showSlidedownPrompt()

      OneSignal.on('subscriptionChange', function (isSubscribed) {
        if (isSubscribed) {
          OneSignal.getUserId().then(function (userId) {
            console.log('OneSignal User ID:', userId)

            // Set external user ID here
            const profileId = localStorage.getItem('profileId');
            OneSignal.setExternalUserId(profileId)
              .then(() => {
                console.log('External user ID set successfully');
              })
              .catch(error => {
                console.error('Error setting external user ID:', error);
              });

            // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
            // API call

          })
        }
      })

      //   OneSignal.setDefaultNotificationUrl(window.location.origin + routeUrls.notification)
    })
  }, [])
}

export default usePushNotification