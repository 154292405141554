import styles from './index.module.scss'
import { Grid } from '@mui/material'
import {scaleByRatio} from '../../utils/helper'

const CentralGridLayout = (props) => {
    return ( 
        <Grid container xs={10} sm={10} md={6} lg={6} className={styles.container} 
        style={{            
            gap:`${scaleByRatio(24)}px`, 
            maxWidth:`${scaleByRatio(600)}px`,
            maxHeight: `90vh`,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-48%)'
            }}>
            {props.children}
        </Grid>
     );
}
 
export default CentralGridLayout;