import React, {useState, useRef, useEffect} from 'react'
import styles from './index.module.scss'
import icon_upload_photo from '../../assets/icon_upload_photo.svg'
import Badge from '@mui/material/Badge';
import {scaleByRatio} from '../../utils/helper'
import ImageCropper from "../../molecule/ImageCropper/ImageCropper"
import Webcam from "react-webcam";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const UploadPhotoBlock = ({
    size='96',
    selectedImage,
    selectedImageCallBack,
    onDeleteCallBack,
    showDeleteButton = false
}) => {
    const inputImage = useRef(null)
    const [imageFile, setImageFile] = useState(null);
    const [showImageCropper, setShowImageCropper] = useState(false)
    const [fileUpload, setFileUpload] = useState({ imageUrl: null, flag: false })
    const [anchorEl, setAnchorEl] = useState(null);
    const [showWebcamModal, setShowWebcamModal] = useState(false);

    const webcamRef = useRef(null);

    const handleMenuOption = (option) => {
        if (option === 'chooseImage') {
            inputImage.current.click();
        } else if (option === 'openCamera') {
            setShowWebcamModal(true);
        }
        setAnchorEl(null);
    };

    const capturePhoto = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImageFile(imageSrc);
        selectedImageCallBack(imageSrc);
        setShowWebcamModal(false);
        setShowImageCropper(true)
        setFileUpload({
            imageUrl: imageSrc,
            flag: true
          })
    };

    const handleInputImage = (e)=>{
        setImageFile(URL.createObjectURL(e.target.files[0]));
        selectedImageCallBack(e.target.files[0])
        setShowImageCropper(true)
        setFileUpload({
            imageUrl: URL.createObjectURL(e.target.files[0]),
            flag: true
          })
    }
    useEffect(()=>{
        setImageFile(selectedImage)
    },[selectedImage])

    const callBackImageCropped = (imageFile)=>{
        setShowImageCropper(false)
        setImageFile(URL.createObjectURL(imageFile))
        selectedImageCallBack(imageFile)
        setFileUpload({
            imageUrl: URL.createObjectURL(imageFile),
            flag: true
          })
    
      }
      const callBackImageNotCropped = ()=>{
        setShowImageCropper(false)
      }
      const handleCloseImageCropperCallBack = ()=>{
        setShowImageCropper(false)
      }
    return ( 
        <>
        <div
            className={(!imageFile)?styles.imageHolder:''}
            style={{ cursor:"pointer", height: `${scaleByRatio(size)}px`,width: `${scaleByRatio(size)}px`, textAlign: "center"}}
            onClick={(event) => {
                if ((event.target).nodeName === "SPAN") {
                    setImageFile(null)
                    onDeleteCallBack()

                }else{
                    // inputImage.current.click() 
                    setAnchorEl(event.currentTarget)
                }
                
            }}
        >
            {imageFile && showDeleteButton && (
                 <Badge badgeContent={'X'} color="error">
                    <img src={imageFile} alt="selected file" style={{ cursor:"pointer", height: `${scaleByRatio(size)}px`,width: `${scaleByRatio(size)}px`}}/>
                </Badge>
            )}
            {imageFile && !showDeleteButton && (
                 <img src={imageFile} alt="selected file 1" style={{ cursor:"pointer", height: `${scaleByRatio(size)}px`,width: `${scaleByRatio(size)}px`}}/>
            )}
            {!imageFile && (
                <img src={icon_upload_photo} alt={'App icon'} className={styles.uploadIcon}/>
            )}
            
        </div>
        <input
            type="file"
            accept="image/*"
            className={"displayNone"}
            ref={inputImage}
            onChange={handleInputImage}
            onClick={(evt)=>{
                evt.target.value=null
            }}
        />
        {showImageCropper && (
                              <ImageCropper
                              ImageURL={fileUpload?.imageUrl}
                              callBackImageCropped={callBackImageCropped}
                              callBackImageNotCropped={callBackImageNotCropped}
                              handleCloseModalCallBack={handleCloseImageCropperCallBack}
                              />
                            )}

        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            getContentAnchorEl={null}
        >
            <MenuItem onClick={() => handleMenuOption('chooseImage')}>Choose an Image</MenuItem>
            <MenuItem onClick={() => handleMenuOption('openCamera')}>Open Camera</MenuItem>
        </Menu>
        <Modal
            open={showWebcamModal}
            onClose={() => setShowWebcamModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => setShowWebcamModal(false)}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: 'text.secondary',
                    }}
                >
                    <CloseIcon />
                    </IconButton>
                    <Webcam
                        style={{
                            width: '100%',
                            height: 'auto'
                        }}
                        ref={webcamRef}
                    />
                    <Button onClick={capturePhoto} variant="contained" color="primary" sx={{ mt: 2 }}>Capture Photo</Button>
                </Box>
            </Modal>
        </>
     );
}
 
export default UploadPhotoBlock;