import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './index.module.scss'
import { scaleByRatio } from '../../utils/helper';


const CustomAlertDelete = ({
    isOpen=false,
    message='',
    heading='',
    onYesClick,
    onNoClick,
}) => {
    const [open, setOpen] = useState(isOpen);

  const handleCancel = () => {
    setOpen(false);
    onNoClick()
  };
  const handleAgree = ()=>{
    setOpen(false);
    onYesClick()
  }
    return (  
        <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={styles.heading}
        style={{
            fontSize:`${scaleByRatio(32)}px`,
            lineHeight:`${scaleByRatio(38)}px`,
        }}>
          {heading}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"
          className={styles.message}
          style={{
            fontSize: `${scaleByRatio(16)}px`,
            lineHeight: `${scaleByRatio(20)}px`,
          }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{
            justifyContent:"center",
            display: 'flex'
        }}>
        <Button onClick={handleCancel} autoFocus className={`${styles.button} ${styles.primary} `}
        style={{
            fontSize:`${scaleByRatio(18)}px`,
            lineHeight:`${scaleByRatio(24)}px`
        }}
        >
            Cancel
        </Button>
        <Button onClick={handleAgree} className={`${styles.button}`}
        style={{
            fontSize:`${scaleByRatio(18)}px`,
            lineHeight:`${scaleByRatio(24)}px`
        }}>
            Yes, Delete
        </Button>
        </DialogActions>
      </Dialog>
    );
}
 
export default CustomAlertDelete;