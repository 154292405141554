import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import InputField from '../../atoms/InputField';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import FlagUs from '../../assets/FlagUs.svg';
import { useWindowSize } from '../../utils/useWindowSize';
import FlagIndia from '../../assets/FlagIndia.svg';
import Stack from '@mui/material/Stack';
import { usePostAddFriend } from '../../services/mutations';
import { ISD_CODE } from '../../utils/constants';
import Loader from '../../molecule/Loader';
import { scaleByRatio } from '../../utils/helper';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import './styles.scss';
const arrISDCodes = [
  {
    value: '+1',
    flag: (
      <img
        src={FlagUs}
        alt="US"
        style={{
          height: `${scaleByRatio(24)}px`,
          width: `${scaleByRatio(24)}px`,
        }}
      />
    ),
  },
  {
    value: '+91',
    flag: (
      <img
        src={FlagIndia}
        alt="IND"
        style={{
          height: `${scaleByRatio(24)}px`,
          width: `${scaleByRatio(24)}px`,
        }}
      />
    ),
  },
];
const AddFriendFrom = ({ onBackClicked }) => {
  const postAddFriend = usePostAddFriend();
  const [first_name, setFirst_name] = useState('');
  const [height, width] = useWindowSize();
  const [getwidth, setWidth] = useState(0);
  const [last_name, setLast_name] = useState('');
  const [error, setError] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [friendshipInfo, setFriendshipInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const [ISDCode, setISDCode] = useState('+1');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window?.innerWidth);
    }
  }, [width]);

  const handleAreaCodeXs = () => {
    if (getwidth < 1100) {
      return 4.5;
    } else if (getwidth > 1100 && getwidth < 1500) {
      return 2.5;
    } else {
      return 1.5;
    }
  };

  const handleMobNumXs = () => {
    if (getwidth < 1100) {
      return 7.5;
    } else if (getwidth > 1100 && getwidth < 1500) {
      return 9.5;
    } else {
      return 10.5;
    }
  };

  const handleISDChange = (evt) => {
    console.log(evt.target.value);
    setISDCode(evt.target.value);
  };

  const handleFirstNameChange = (evt) => {
    setError('');
    setFirst_name(evt.target.value);
  };
  const handleLastNameChange = (evt) => {
    setError('');
    setLast_name(evt.target.value);
  };
  const onMobileNumberChange = (e) => {
    console.log('onMobileNumberChange: ', e.target.value);
    setError('');
    let mobileNumber = e.target.value;
    // let phRegex = /^[0-9]$/;
    // if(phRegex.test(mobileNumber)) {

    if (mobileNumber.length <= 10) {
      setMobileNumber(e.target.value);
    }
    // }
  };
  const validatePhoneNumber = (phNum) => {
    console.log('validatePhoneNumber: ', phNum);
    let phRegex = /^[0-9]{10}$/;
    if (phRegex.test(phNum)) {
      setError('');
      return true;
    } else {
      setError('Please provide a valid phone number.');
      return false;
    }
  };

  const handleEmailChange = (evt) => {
    setError('');
    onEmailChange(evt.target.value);
  };
  const ValidateEmail = (email) => {
    let validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const onEmailChange = (_email) => {
    setEmail(_email);
    if (!ValidateEmail(_email)) {
      setError('Please provide a valid email');
    }
  };

  const handleFriendshipInfoChange = (evt) => {
    setFriendshipInfo(evt.target.value);
  };

  const handleOnSubmitClicked = () => {
    let data = {};
    if (first_name.length > 0) {
      data.first_name = first_name;
    } else {
      setError('Please provide your first name.');
      return;
    }
    if (last_name.length > 0) {
      data.last_name = last_name;
    } else {
      setError('Please provide your last name.');
      return;
    }
    if (validatePhoneNumber(mobileNumber)) {
      data.phone_number = ISDCode + mobileNumber;
    } else {
      return;
    }
    if (ValidateEmail(email)) {
      data.email = email;
    } else {
      setError('Please provide a valid email.');
      return;
    }

    data.description = friendshipInfo;
    setLoading(true);
    postAddFriend.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        onBackClicked();
      },
      onError: async (error) => {
        setLoading(false);
        if (error?.response?.data?.payload) {
          if (error.response.data.payload.phone_number) {
            await setError(error.response.data.payload.phone_number[0]);
          } else if (error.response.data.payload.email) {
            await setError(error.response.data.payload.email[0]);
          } else if (error.response.data.payload.description) {
            await setError(error.response.data.payload.description[0]);
          }
        } else {
          await setError(error?.response?.data?.error);
        }
      },
    });
    
  };

  return (
    <>
      <Loader open={loading} />
      <Stack
        direction="column"
        spacing={scaleByRatio(2)}
        className={styles.stackClass}
        flexWrap="wrap">
        <Grid container direction="row">
          <Grid
            item
            justifyContent="left"
            alignItems="flex-end"
            className={styles.logoContainer}
            xs={1}>
            <img
              src={ArrowLeft}
              alt={'Back icon'}
              className={styles.logo}
              onClick={onBackClicked}
              width={scaleByRatio(24)}
              height={scaleByRatio(24)}
            />
          </Grid>
          <Grid
            item
            xs={10}
            justifyContent="center"
            alignItems="flex-start"
            style={{ display: 'flex', cursor: 'pointer' }}>
            <Typography textAlign="center" className={styles.addFriend}>
              Add Friend
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.description}
              style={{
                fontSize: `${scaleByRatio(24)}px`,
              }}>
              Name
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <InputField
            name="FirstName"
            id={'FirstName'}
            value={first_name}
            placeholder={'First Name (Required)'}
            handleChange={(evt) => handleFirstNameChange(evt)}
            type={'text'}
            class_name={styles.name}
            minHeightPX={`${scaleByRatio(56)}`}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <InputField
            name="LastName"
            id={'LastName'}
            value={last_name}
            placeholder={'Last Name (Required)'}
            handleChange={(evt) => handleLastNameChange(evt)}
            type={'text'}
            class_name={styles.name}
            minHeightPX={`${scaleByRatio(56)}`}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item xs={12}>
            <Typography
              textAlign="left"
              className={styles.description}
              style={{
                fontSize: `${scaleByRatio(24)}px`,
              }}>
              Mobile No
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid
            item
            // xs={handleAreaCodeXs()}
            style={{ paddingRight: `${scaleByRatio(5)}px` }}
            className={styles.centerOnSmallScreen}>
            <FormControl variant="standard" fullWidth>
              <Select
                labelId={'ISD'}
                name={'ISDCODE'}
                id={'ISDCODE'}
                value={ISDCode}
                onChange={handleISDChange}
                variant="filled"
                className="inputMobileNumberDropdown"
                sx={{
                  '.MuiSelect-filled': {
                    // height: `${scaleByRatio(46)}px`,
                    padding: `${scaleByRatio(15)}px`,
                    fontSize: `${scaleByRatio(10)}px`,
                    borderBottom: `1px solid #C4C4C4`,
                    paddingTop: `${scaleByRatio(5)}px`,
                    paddingBottom: `${scaleByRatio(4)}px`,
                    background: '#FFFFFF',

                    paddingRight: '25px',
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}>
                {arrISDCodes.map((menu) => {
                  return (
                    <MenuItem
                      value={menu.value}
                      style={{
                        fontSize: `${scaleByRatio(10)}px`,
                        // height: `${scaleByRatio(40)}px`
                        paddingBottom: `${scaleByRatio(5)}px`,
                      }}
                      class_name={styles.inputMobileNumber}>
                      <Typography
                        style={{
                          float: 'left',
                          marginRight: `${scaleByRatio(5)}px`,
                          lineHeight: 'unset',
                          display: 'flex',
                        }}>
                        {menu.flag}
                      </Typography>
                      <Typography
                        style={{
                          float: 'right',
                          fontSize: `${scaleByRatio(20)}px`,
                        }}>
                        {menu.value}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            //  xs={handleMobNumXs()}
            className={styles.mobileInputDiv}>
            {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
            <InputField
              name="MobileNum"
              id={'MobileNum'}
              value={mobileNumber}
              placeholder={'Mobile Number'}
              handleChange={onMobileNumberChange}
              type={'number'}
              class_name={styles.inputMobileNumber}
              minHeightPX={`${scaleByRatio(56)}`}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.description}
              style={{
                fontSize: `${scaleByRatio(24)}px`,
              }}>
              Email
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <InputField
            name="Email"
            id={'Email'}
            value={email}
            placeholder={'Email (required)'}
            handleChange={(evt) => handleEmailChange(evt)}
            type={'text'}
            class_name={styles.name}
            minHeightPX={`${scaleByRatio(56)}`}
          />
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.description}
              style={{
                fontSize: `${scaleByRatio(24)}px`,
              }}>
              Describe Your Friendship
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <InputField
            name="friendship"
            id={'friendship'}
            value={friendshipInfo}
            placeholder={'Add info'}
            handleChange={(evt) => handleFriendshipInfoChange(evt)}
            type={'text'}
            class_name={styles.name}
            multiline={true}
            rows={5}
          />
        </Grid>

        {error && error?.length > 0 && (
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="flex-end">
            <Typography
              textAlign="left"
              className={styles.error}
              style={{
                fontSize: `${scaleByRatio(18)}px`,
                lineHeight: `${scaleByRatio(40)}px`,
              }}>
              {error}
            </Typography>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid item className={styles.inviteBtn}>
            <CustomButton
              text={'Invite'}
              variant={ButtonVariant.contained}
              type={ButtonType.primary}
              onClick={handleOnSubmitClicked}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default AddFriendFrom;
