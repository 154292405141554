import React from 'react';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import { Box, Grid, Typography } from '@mui/material';
import styles from './index.module.scss';
import { scaleByRatio } from '../../utils/helper';
import { CustomButton } from '../../atoms/CustomButton';
import { useNavigate } from 'react-router-dom';
import { HomeMenuConstant, routeUrls } from '../../utils/constants';

const Logout = ({ handleMenuClicked }) => {
  const nav = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    nav(routeUrls.landingPage);
    localStorage?.removeItem('setEdit')
    localStorage.removeItem('questionEdit')
  };

  const handleLeftMenuClicked = (menu) => {
    handleMenuClicked(menu);
    // nav(routeUrls.logout);
  };
  return (
    <Box>
      <CentralContentPageLayout>
        <CentralGridLayout>
          <Grid container>
            <Grid
              item
              display={'flex'}
              justifyContent={'center'}
              mt={4}
              marginBottom={2}
              paddingLeft={0}
              
              style={{margin:'auto'}}>
              <Typography
                textAlign="center"
                alignItems="center"
                className={styles.description}
                style={{
                  fontSize: `${scaleByRatio(32)}px`,
                  //   lineHeight: `${scaleByRatio(38)}px`,
                  alignItems: 'center',
                }}>
                Log out
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" alignItems="center" m={1} className={styles.logoutDesc}>
              <Typography style={{ fontWeight: '600', fontSize: '15px' }}>
                Are you sure you want to Logout?
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              // textAlign="center"
              // alignItems="center"
              m={2}
              display={'flex'}
              style={{alignItems:'center'}}
              >
              <Grid
                item
                xs={4}
                justifyContent="flex-start"
                style={{ cursor: 'pointer' }}
                marginLeft={2}
                onClick={() =>
                  handleLeftMenuClicked(HomeMenuConstant.MyProfile)
                }>
                <Typography style={{ fontWeight: '600', fontSize: '14px' }} className={styles.cancelTxt}>
                  Cancel
                </Typography>
              </Grid>
              <Grid item xs={8} paddingLeft={2} justifyContent="flex-end" display={'flex'} className={styles.logoutBtn}>
                <CustomButton
                  text={' Yes, Logout'}
                  onClick={handleLogout}
                  style={{
                    fontSize: `${scaleByRatio(25)}px`,
                    fontWeight: '600',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </CentralGridLayout>
      </CentralContentPageLayout>
    </Box>
  );
};

export default Logout;
