import React, {useState} from 'react'
import styles from './index.module.scss'
import {  Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import { scaleByRatio } from '../../utils/helper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AnswerType } from '../../utils/constants'
import './styles.scss';

const DatingPreferenceSelectDropDown = ({
    question,
    question_color,
    id,
    identifier,
    type,
    choice,
    selectedValue,
    handleOnChangeCallBack,
    question_type
}) => {
    const [selectValue, setSelectValue] = useState(selectedValue)
    const handleChange = (event) => {
        setSelectValue(event.target.value);
        handleOnChangeCallBack(event.target.value, id, identifier, question_type, AnswerType.Select)
      };
    return ( 
        <>
        <Grid container direction="row" justifyContent="left" alignItems="flex-end">
            <Grid item >
                <Typography textAlign='left' className={styles.question} 
                style={{
                    color:`${question_color}`,
                    lineHeight: `${scaleByRatio(21.6)}px`
                }}
                >
                {question}
                </Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="left" alignItems="flex-end">
            <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
                <Select
                value={selectValue}
                onChange={handleChange}
                displayEmpty
                variant="filled"
                className='menuSelect'
                sx={{
                    "& .MuiSelect-filled":{
                        backgroundColor:'#F1F6F8',
                        fontFamily: 'Myriad Pro',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        height: `${scaleByRatio(24)}px`,
                        padding: `${scaleByRatio(16)}px`,
                        borderBottom: `1px solid #C4C4C4`
                    }
                }}
                >
                    {choice && choice.map(_choice=>{
                        return (
                            <MenuItem value={_choice.value}>{_choice.label}</MenuItem>
                        )
                    })}
                    

                </Select>
            </FormControl>
        </Grid>
        </>
     );
}
 
export default DatingPreferenceSelectDropDown;