import { FormControl } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/system';
import React from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { scaleByRatio } from '../../utils/helper';
import styles from './index.module.scss';

const InputAdornment = styled('div')`
  margin: 8px;
  display: inline-flex;
  align-items: left;
  justify-content: center;
`;

const InputField = ({
  name,
  id,
  value,
  placeholder,
  handleChange,
  type,
  class_name,
  margin = 'none',
  multiline = false,
  rows = 1,
  required = false,
  autoComplete = 'off',
  disabled = false,
  prefix = null,
  minHeightPX = '40',
}) => {
    return ( 
        <>
            {type !== 'date' && (
                <FormControl variant='standard' fullWidth margin={margin}>
                    
                    <InputBase
                    name={name}
                    id={id}
                    value={value}
                    placeholder={placeholder}
                    onChange={(evt)=>handleChange(evt)}
                    type={type}
                    className={`${styles.inputField} ${class_name}`}
                    //style={{minHeight:`${minHeightPX}px`}}
                    multiline={!!multiline}
                    rows={rows || 0}
                    minRows={rows}
                    required={!!required}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    startAdornment={
                        <InputAdornment>
                        {prefix}
                        </InputAdornment>
                    }
                    style={{
                        fontSize: `${scaleByRatio(20)}px`,
                        minHeight:`${scaleByRatio(minHeightPX)}px`
                    }}
                    inputProps={{
                        inputMode: type === 'number' ? 'numeric' : 'text', // Set inputMode to 'numeric' for number type
                    }}
                    />
                
                </FormControl>
            )}
            {type === 'date' && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl variant='standard' fullWidth margin={margin}>
                    <DatePicker
                    value={value}
                    disableFuture
                    onChange={(newValue)=>handleChange(newValue)}
                    className={`${styles.inputField} ${styles.inputDateField} ${(class_name)?class_name:''}`}
                    multiline={!!multiline}
                    rows={rows || 0}
                    required={!!required}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    format={"DD/MM/YYYY"}
                    />
                    </FormControl>
                </LocalizationProvider>
            )}
        </>
     );
}
 
export default InputField;
