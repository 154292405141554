import React, { useContext, useState } from 'react';
import styles from './index.module.scss';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuItem from '@mui/material/MenuItem';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { Box, Grid } from '@mui/material';
import Explore from '../../assets/Explore.svg';
import ExplorePage from '../../organism/Explore';
import ConvosPage from '../../organism/Convos';
import ChatterBoxPage from '../../organism/ChatterBox';
import {
  ReportConvoCounter,
  ReportElbowCounter,
} from '../../utils/Context/reportCounter';
import ExploreSelected from '../../assets/ExploreSelected.svg';
import ElbowNew from '../../assets/ElbowNew.svg';
import ElbowsSelected from '../../assets/ElbowsSelected.svg';
import Convos from '../../assets/Convos.svg';
import ConvosSelected from '../../assets/ConvosSelected.svg';
import ChatterBox from '../../assets/ChatterBox.svg';
import ChatterBoxSelected from '../../assets/ChatterBoxSelected.svg';
import Typography from '@mui/material/Typography';
import { HomeMenuConstant } from '../../utils/constants';
import { useGetRequestCount, usePatchClearRequestCount } from '../../services/mutations';
import { clear } from '@testing-library/user-event/dist/clear';

const StickyFooter = ({ handleMenuClicked, selectedMenu }) => {
  const [elbowCount, setElbowCount] = useContext(ReportElbowCounter);
  const [convoCount, setConvoCount] = useContext(ReportConvoCounter);
  const localStorage = useLocalStorage();
  const reportCounterData = useGetRequestCount();
  const clearCounter=usePatchClearRequestCount()
  // const [selectedMenu, setSelectedMenu] = useState(HomeMenuConstant.ChatterBox);
  console.log('selected menu', HomeMenuConstant.MyProfile);
 

  const reportCount = () => {
    reportCounterData.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('reportCounterData success:', response);
          console.log(
            'convo count',
            response?.data?.payload?.pending_introw_request,
          );

          setConvoCount(response?.data?.payload?.results[0]?.chat_with_dater_count);
          localStorage.setLocalStorageItem('counterID', response?.data?.payload?.results[0]?.id);
          console.log(
            'elbow count',
            response?.data?.payload?.pending_elbow_request,
          );
          setElbowCount(response?.data?.payload?.results[0]?.reach_out_count);
        },
      },
      // onError: (err) => {
      //   console.log('getMyProfile onError: ', err);
      //   setLoading(false);
      // }
    );
  };


  const clearCounterData = (check=false) => {
    console.log('clear counter data:', check);
    // let data={
    //   request_id:localStorage.getLocalStorageItem('counterID'),
    //   reach_out_count:0,
    // }
    let data={};
    if(check){ 
       data={
      request_id:localStorage.getLocalStorageItem('counterID'),
      reach_out_count:0,
    }
  } else{
      data={
      request_id:localStorage.getLocalStorageItem('counterID'),
      chat_with_dater_count:0,
    }
  }
    clearCounter.mutate(
      data,
      {
        onSuccess: (response) => {
          console.log('clear counter success:', response);
          if(check){
            setElbowCount(0);
          }else{
            setConvoCount(0);
          }
        },
        onError: (error) => {
          // setLoading(false);
          console.log('getUsersFriendList error:', error);
        },
      },
  
    );
        }

  const handleTopMenuClick = (menu) => {
    // setSelectedMenu(menu);
    handleMenuClicked(menu);
  };

  const handleExploreClick = (menu) => {
    reportCount();
    handleMenuClicked(menu);
    // clearCounterData(check)
    
  };


  const handleReachOutClick = (menu,check) => {
    handleMenuClicked(menu);
    clearCounterData(check)
    
  };

  const handleConvoClick = (menu,check) => {
    handleMenuClicked(menu);
    clearCounterData(check)
    
  };

  return (
    <>
      <div className={styles.mobStickyFooter}>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'flex', sm: 'flex' },
          }}
          className={styles.menuContainer}>
          <MenuItem
            key={'Explore'}
            // onClick={() => handleTopMenuClick(HomeMenuConstant?.Explore)}
            onClick={() => handleExploreClick(HomeMenuConstant?.Explore)}
            >
            <img
              src={
                selectedMenu === HomeMenuConstant?.Explore
                  ? ExploreSelected
                  : Explore
              }
              alt="Explore"
              className={styles.TopMenuICon}
            />
            <Typography
              textAlign="center"
              className={styles.menuItem}
              style={{
                fontSize: `14px`,
                lineHeight: `22px`,
                paddingLeft: `5px`,
                color: `${
                  selectedMenu === HomeMenuConstant?.Explore
                    ? // ? '#FF000A'
                      '#76d9ff'
                    : // : '#05232E'
                      '#fff'
                }`,
              }}>
              Explore
            </Typography>
          </MenuItem>
          <MenuItem
            key={'ChatterBox'}
            onClick={() => handleTopMenuClick(HomeMenuConstant?.ChatterBox)}>
            <img
              src={
                selectedMenu === HomeMenuConstant?.ChatterBox
                  ? ChatterBoxSelected
                  : ChatterBox
              }
              alt="Chatter Box"
              className={styles.TopMenuICon}
            />
            <Typography
              textAlign="center"
              className={styles.menuItem}
              style={{
                fontSize: `12px`,
                lineHeight: `22px`,
                paddingLeft: `5px`,
                color: `${
                  selectedMenu === HomeMenuConstant?.ChatterBox
                    ? // ? '#FF000A'
                      '#76d9ff'
                    : // : '#05232E'
                      '#fff'
                }`,
              }}>
              {/* Chatter Box */}
              {/* Chat with my friends */}
              Group chat
            </Typography>
          </MenuItem>
          <MenuItem
            key={'Convos'}
            // onClick={() => handleTopMenuClick(HomeMenuConstant?.Convos)}
            onClick={() => handleConvoClick(HomeMenuConstant?.Convos,false)}
            >
            <img
              src={
                selectedMenu === HomeMenuConstant?.Convos
                  ? ConvosSelected
                  : Convos
              }
              alt="Convos"
              className={styles.TopMenuICon}
            />
            {convoCount > 0 && (
              <Grid
                style={{
                  backgroundColor: 'red',
                  // color: 'red',
                  text: 'white',
                  borderRadius: '50%',
                  width: `25px`,
                  height: `25px`,
                  textAlign: 'center',
                  fontSize: `15px`,
                  marginTop: `-40px`,
                  marginLeft: `-10px`,
                }}>
                {convoCount > 9 ? '9+' : convoCount}
              </Grid>
            )}
            <Typography
              textAlign="center"
              className={styles.menuItem}
              style={{
                fontSize: `14px`,
                lineHeight: `22px`,
                paddingLeft: `5px`,
                color: `${
                  selectedMenu === HomeMenuConstant?.Convos
                    ? // ? '#FF000A'
                      '#76d9ff'
                    : // : '#05232E'
                      '#fff'
                }`,
              }}>
              {/* Convos */}
              {/* Chat with daters */}
              Dater to Dater
            </Typography>
          </MenuItem>
          {/* {localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
              localStorageUserType.dater && ( */}
          <MenuItem
            key={'Elbows'}
            // onClick={() => handleTopMenuClick(HomeMenuConstant?.Elbows)}
            onClick={() => handleReachOutClick(HomeMenuConstant?.Elbows,true)}
            >
            <img
              src={
                selectedMenu === HomeMenuConstant?.Elbows
                  ? ElbowsSelected
                  : ElbowNew
              }
              alt="Elbows"
              className={styles.TopMenuICon}
            />
            {elbowCount > 0 && (
              <Grid
                style={{
                  backgroundColor: 'red',
                  // color: 'red',
                  text: 'white',
                  borderRadius: '50%',
                  width: `25px`,
                  height: `25px`,
                  textAlign: 'center',
                  fontSize: `14px`,
                  marginTop: `-40px`,
                  marginLeft: `-10px`,
                }}
                // className={styles.counter}
              >
                {elbowCount > 9 ? '9+' : elbowCount}
              </Grid>
            )}
            <Typography
              textAlign="center"
              className={styles.menuItem}
              style={{
                fontSize: `14px`,
                lineHeight: `22px`,
                paddingLeft: `5px`,
                color: `${
                  selectedMenu === HomeMenuConstant?.Elbows
                    ? // ? '#FF000A'
                      '#76d9ff'
                    : // : '#05232E'
                      '#fff'
                }`,
              }}>
              {/* Elbows */}
              Reach Outs
            </Typography>
          </MenuItem>
          {/* )} */}
        </Box>
      </div>
    </>
  );
};

export default StickyFooter;
