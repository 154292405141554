import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import styles from './index.module.scss';
import OthersFriendListItem from '../../molecule/OthersFriendListItem';
import {
  useGetOthersFriendList,
  useGetUsersFriendList,
  usePostReachOutForElbow,
} from '../../services/mutations';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import Stack from '@mui/material/Stack';
import Loader from '../../molecule/Loader';
import { scaleByRatio } from '../../utils/helper';
import { Typography } from '@mui/material';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import CustomAlert from '../../atoms/CustomAlert';
import { useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import Elbows from '../Elbows';
import { ReachOutContext } from '../../utils/Context/reportCounter';

const OthersFriendList = ({
  dater_id,
  dater_name,
  onBackClicked,
  activeDater,
  showReachout = true,
}) => {
  const getOthersFriendList = useGetOthersFriendList();
  const getUsersFriendList = useGetUsersFriendList();
  const postReachOutForElbow = usePostReachOutForElbow();
  const localStorage = useLocalStorage();
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [friendsList, setFriendsList] = useState([]);
  const [activeChat, setActiveChat] = useState(false);
  const [daterFriendId, setDaterFriendId] = useState(null);
  const [reachOutHandle, setReachOutHandle] = useContext(ReachOutContext);
  const onAlertClose = () => {
    setAlertMsg('');
  };
  const callBackReachOut = (friend_id, message = '') => {
    console.log('friend_id', friend_id);
    let data = {
      request_from: activeDater,
      request_to_friend: friend_id,
      interest_type: 'Y',
      request_text: message,
    };
    setLoading(true);
    postReachOutForElbow.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        setAlertMsg('Request Sent!!');
        // nav(routeUrls.Elbows);
        // setDaterFriendId(friend_id);
        setReachOutHandle({
          status:true,
          id: friend_id
        });    
        setActiveChat(true);
      },
      onError: (error) => {
        setLoading(false);
        console.log('postReachOutForElbow onError:', error);
        if (error?.response?.data?.status_code === 400) {
          setAlertMsg(error?.response?.data?.error);
          // nav(routeUrls.Elbows);
          // // nav(routeUrls.profile);
          // window.history.back();
        }
      },
    });
  };

  const fetchOthersFriendList = () => {
    setLoading(true);
    let data = {
      dater: dater_id,
    };
    getUsersFriendList.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('getUsersFriendList success:', response);
        setFriendsList(response?.data?.payload?.results);
      },
      onError: (error) => {
        setLoading(false);
        console.log('getUsersFriendList error:', error);
        setFriendsList([]);
      },
    });
  };

  useEffect(() => {
    fetchOthersFriendList();
  }, []);
  return (
    <Box>
      {activeChat ? (
        <Grid>
          <Elbows daterFriendId={daterFriendId} setDaterFriendId={setDaterFriendId} />
        </Grid>
      ) : (
        <>
          {alertMsg.length > 0 && (
            <CustomAlert
              isOpen={alertMsg.length > 0 ? true : false}
              message={alertMsg}
              onClose={onAlertClose}
            />
          )}
          <Loader open={loading} />
          {!loading && (
            <>
              <Grid
                container
                direction="row"
                alignItems="flex-end"
                sx={{
                  background: '#000',
                  minHeight: `${scaleByRatio(50)}px`,
                  paddingBottom: `${scaleByRatio(10)}px`,
                }}>
                <Grid
                  item
                  xs={1}
                  justifyContent="left"
                  alignItems="flex-end"
                  className={styles.logoContainer}>
                  <img
                    src={ArrowLeft}
                    alt={'Back icon'}
                    className={styles.logo}
                    onClick={onBackClicked}
                    width={scaleByRatio(24)}
                    height={scaleByRatio(24)}
                  />
                </Grid>
                <Grid item xs={10} justifyContent="center">
                  <Typography textAlign="center" className={styles.daterName}>
                    {dater_name}'s Friend List
                  </Typography>
                </Grid>
              </Grid>
              <Box
                component="main"
                sx={{ flexGrow: 1, pb: 9, pt: 3, pr: 3, pl: 3 }}>
                <Stack
                  direction="column"
                  spacing={2}
                  className={styles.stackClass}
                  flexWrap="wrap"
                  style={{
                    gap: `${scaleByRatio(24)}px`,
                  }}>
                  {friendsList &&
                    friendsList.length > 0 &&
                    friendsList.map((friend) => {
                      return (
                        <OthersFriendListItem
                          friendDetails={friend}
                          callBackReachOut={callBackReachOut}
                          showReachOutButton={showReachout}
                        />
                      );
                    })}
                  {friendsList && friendsList.length === 0 && (
                    <Typography
                      justifyContent={'center'}
                      alignItems={'flex-start'}>
                      No friends added for {dater_name}
                    </Typography>
                  )}
                </Stack>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default OthersFriendList;
