import React, { useState } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import AppLogoMin from '../../assets/AppLogoMin.svg';
import InputField from '../../atoms/InputField';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import { scaleByRatio } from '../../utils/helper';
import ArrowLeft from '../../assets/ArrowLeft.svg';

const ProfileDataName = ({
  first_name,
  last_name,
  onFirstNameChange,
  onLastNameChange,
  onNextClicked,
  error,
  onBackClicked,
  submitBtnText = 'Next',
  showAppIcon = true,
}) => {
  const handleFirstNameChange = (evt) => {
    onFirstNameChange(evt.target.value);
  };
  const handleLastNameChange = (evt) => {
    onLastNameChange(evt.target.value);
  };

  const handleOnNextClicked = (evt) => {
    onNextClicked();
  };
  return (
    <>
      {showAppIcon && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            {/* <Typography textAlign='center' className={styles.logoContainer}> */}
            <img
              src={AppLogoMin}
              alt={'App icon'}
              className={styles.logo}
              style={{
                width: `${scaleByRatio(56)}px`,
                height: `${scaleByRatio(126)}px`,
              }}
            />
            {/* </Typography> */}
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item className={styles.logoContainer}>
          <img
            src={ArrowLeft}
            alt={'Back icon'}
            className={styles.logo}
            onClick={onBackClicked}
            width={scaleByRatio(24)}
            height={scaleByRatio(24)}
          />
        </Grid>
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.back}
            onClick={onBackClicked}
            style={{
              fontSize: `${scaleByRatio(16)}px`,
              paddingLeft: `${scaleByRatio(10)}px`,
              paddingBottom: `${scaleByRatio(5)}px`,
            }}>
            Back
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.description}
            style={{
              fontSize: `${scaleByRatio(24)}px`,
              lineHeight: `${scaleByRatio(40)}px`,
            }}>
            What's your name?
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <InputField
          name="FirstName"
          id={'FirstName'}
          value={first_name}
          placeholder={'First Name (Required)'}
          handleChange={(evt) => handleFirstNameChange(evt)}
          type={'text'}
          class_name={styles.name}
        />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <InputField
          name="LastName"
          id={'LastName'}
          value={last_name}
          placeholder={'Last Name'}
          handleChange={(evt) => handleLastNameChange(evt)}
          type={'text'}
          class_name={styles.name}
        />
      </Grid>
      {error && error?.length > 0 && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Typography
            textAlign="left"
            className={styles.error}
            style={{
              fontSize: `${scaleByRatio(18)}px`,
              lineHeight: `${scaleByRatio(40)}px`,
            }}>
            {error}
          </Typography>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="end"
        alignItems="flex-end">
        <Grid item>
          <CustomButton
            text={submitBtnText}
            variant={ButtonVariant.contained}
            type={ButtonType.primary}
            onClick={handleOnNextClicked}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileDataName;
