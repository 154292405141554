import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import styles from './index.module.scss';
import { scaleByRatio, getFormattedDate } from '../../utils/helper';
import NotificationListItem from '../NotificationListItem';
import Loader from '../../molecule/Loader';
import {
  usePatchNotification,
  useGetNotificationList,
} from '../../services/mutations';
import { useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';

const NotificationList = ({ notifications, callbackOnClick }) => {
  const patchNotification = usePatchNotification();
  const getNotificationList = useGetNotificationList();
  const [listNotifications, setListNotifications] = useState(notifications);
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();
  const handleNotificationClick = (notification) => {
    console.log('handleNotificationClick:', notification);
    updateNotificationStatus(notification);
  };

  const fetchNotificationList = () => {
    getNotificationList.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('fetchNotificationList::', response);

          setListNotifications(response?.data?.payload?.results);
        },
        onError: (error) => {
          console.log(error);
        },
      },
    );
  };

  const updateNotificationStatus = (notification) => {
    let data = {
      notification_id: notification?.id,
      read: true,
    };
    setLoading(true);
    patchNotification.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        callbackOnClick();
      },
      onError: (error) => {
        setLoading(false);
      },
    });
  };

  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };

  useState(() => {
    console.log('Notification List: ', notifications);
    setListNotifications(notifications);
  }, [notifications]);

  useState(() => {
    fetchNotificationList();
  }, []);
  return (
    <>
      <Loader open={loading} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          pt: scaleByRatio(3),
          marginTop: `${scaleByRatio(80)}px`,
        }}
        style={{ padding: '15px' }}>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          sx={{ minHeight: '50px', paddingBottom: '10px' }}>
          <Grid item xs={12} justifyContent="center">
            <Typography
              textAlign="center"
              className={styles.header}
              style={{
                fontSize: `${scaleByRatio(24)}px`,
              }}>
              Notifications
            </Typography>
          </Grid>
        </Grid>
        <Grid
          display={'flex'}
          justifyContent={'flex-end'}
          color={'#fff'}
          marginTop={'-35px'}
          marginRight={'20px'}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
          onClick={handleClose}>
          <Typography color={'#fff'}>X</Typography>
        </Grid>
        {!loading &&
          listNotifications.length > 0 &&
          listNotifications.map((notification) => {
            return (
              <NotificationListItem
                notificationItem={notification}
                onClickCallBack={handleNotificationClick}
              />
            );
          })}
      </Box>
    </>
  );
};

export default NotificationList;
