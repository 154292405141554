import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import './styles.scss';

export default function CustomAlert({ isOpen = false, message = '', onClose }) {
  const [open, setOpen] = useState(isOpen);

  const handleClose = (event, reason) => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    console.log('CustomAlert loaded');
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent className='dialog-content-container'>
        <DialogContentText
          id="alert-dialog-description"
          className='alert-dialog-description-cls'
          minWidth={'150px'}
          minHeight={'80px'}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}
