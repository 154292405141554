import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import dater from '../../assets/dater.svg';
import friend from '../../assets/friend.svg';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import { useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import { scaleByRatio } from '../../utils/helper';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';

const Join = () => {
  const nav = useNavigate();
  const localStorageItem = useLocalStorage();
  return (
    <Grid className={styles.centralLayout}>
      <CentralContentPageLayout>
        <CentralGridLayout>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end">
            <Grid item>
              <Typography
                textAlign="center"
                className={styles.joinHeader}
                style={{
                  fontSize: `${scaleByRatio(32)}px`,
                  lineHeight: `${scaleByRatio(40)}px`,
                }}>
                Who you are?
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: '110px' }}>
            <Grid item justifyContent="center" xs={5}>
              <Typography
                textAlign="center"
                className={styles.joinSubtitles}
                style={{
                  fontSize: `${scaleByRatio(24)}px`,
                  lineHeight: `${scaleByRatio(29)}px`,
                }}>
                Looking for love?
              </Typography>
              <Typography
                textAlign="center"
                className={styles.daterIcon}
                style={{
                  paddingTop: `${scaleByRatio(12)}px`,
                  fontSize: `${scaleByRatio(40)}px`,
                  lineHeight: `${scaleByRatio(40)}px`,
                  cursor: 'pointer',
                  color: '#3F65C5',
                }}
                onClick={() => {
                  localStorageItem.setLocalStorageItem(
                    localStorageKeys.userType,
                    localStorageUserType.dater,
                  );
                  nav(routeUrls.start);
                }}>
                Dater
                {/* <img src={dater} alt='Dater Icon' 
                        onClick={()=>{
                            localStorageItem.setLocalStorageItem(localStorageKeys.userType, localStorageUserType.dater)
                            nav(routeUrls.start)
                        }}/> */}
              </Typography>
            </Grid>
            <Grid item justifyContent="center" xs={2}>
              <Typography
                textAlign="center"
                className={styles.joinSubtitleOr}
                style={{
                  fontSize: `${scaleByRatio(18)}px`,
                  lineHeight: `${scaleByRatio(22)}px`,
                }}>
                Or
              </Typography>
            </Grid>
            <Grid item justifyContent="center" xs={5}>
              <Typography
                textAlign="center"
                className={styles.joinSubtitles}
                style={{
                  fontSize: `${scaleByRatio(24)}px`,
                  lineHeight: `${scaleByRatio(29)}px`,
                }}>
                here to help as a
              </Typography>
              <Typography
                textAlign="center"
                className={styles.daterIcon}
                style={{
                  paddingTop: `${scaleByRatio(12)}px`,
                  fontSize: `${scaleByRatio(40)}px`,
                  lineHeight: `${scaleByRatio(40)}px`,
                  cursor: 'pointer',
                  color: '#3B7939',
                }}
                onClick={() => {
                  localStorageItem.setLocalStorageItem(
                    localStorageKeys.userType,
                    localStorageUserType.friend,
                  );
                  nav(routeUrls.start);
                }}>
                friend
                {/* <img src={friend} alt='friend Icon' 
                         onClick={()=>{
                            localStorageItem.setLocalStorageItem(localStorageKeys.userType, localStorageUserType.friend)
                            nav(routeUrls.start)
                        }}/> */}
              </Typography>
            </Grid>
          </Grid>
        </CentralGridLayout>
      </CentralContentPageLayout>
    </Grid>
  );
};

export default Join;
