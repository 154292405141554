import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import styles from './index.module.scss';
import { useNavigate } from "react-router-dom";
import MyProfileAboutUs from '../MyProfileAbouUs';
import MyProfileMyInterests from '../MyProfileMyInterests';
import Loader from '../../molecule/Loader';

import OthersFriendList from '../OthersFriendList';
import MyProfileSectionView from '../MyProfileSectionView';
import MyProfilePhotos from '../MyProfilePhotos';

import {
  useGetUserProfileByID,
  useGetAllQuestions,
  useGetUsersFriendList,
  useGetResponseForAllQuesionsByUser,
} from '../../services/mutations';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { scaleByRatio } from '../../utils/helper';
import { ProfilePageType, AlertTypes } from '../../utils/constants';
import CustomAlert from '../../atoms/CustomAlert';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import { ActiveDaterContext, DaterProfileBackRoute, FriendDaterId } from '../../utils/Context/reportCounter';
import MyProfileOthersData from '../MyProfileOthersData';

const DaterProfileView = ({
lastRoute,
onBackClicked,
chatFlag,
otherProfile
}) => {
    console.log('lastRoute######',lastRoute,chatFlag)
  const localStorageItem = useLocalStorage();
  const navigate = useNavigate();
  
  const getUserProfileByID = useGetUserProfileByID();
  const getAllQuestions = useGetAllQuestions();
  const getUsersFriendList = useGetUsersFriendList();
  const getResponseForAllQuesionsByUser = useGetResponseForAllQuesionsByUser();
  const [friendDaterId,setFriendDaterId] = useContext(FriendDaterId);
  const [showDaterProfile,setShowDaterProfile]=useState(false)
  const [activeFriendDater,setActiveFriendDater]=useContext(ActiveDaterContext);
  const [backRoute, setBackRoute] = useContext(DaterProfileBackRoute);

  console.log('activeFriendDater',activeFriendDater,showDaterProfile);
  console.log('otherProfile',otherProfile)
  const [allQuestions, setAllQuestions] = useState(null);
  const [nameAgeLocation, setNameAgeLocation] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [userImages, setUserImages] = useState([]);
  const [allAnswers, setAllAnswers] = useState(null);
  const [friendsList, setFriendsList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showOtherFriendsList, setShowOtherFriendsList] = useState(false);

  const arrProfileSections = [
    {
      header: 'My Work',
      type: 'Questions',
      identifiers: [22, 23],
    },
    {
      header: 'I dig someone that.',
      type: 'Questions',
      identifiers: [7],
    },
    {
      header: 'My Politics',
      type: 'Questions',
      identifiers: [8],
    },
    {
      header: 'I dream of a person who.',
      type: 'Questions',
      identifiers: [2],
    },
    {
      header: 'Things of spirit.',
      type: 'Questions',
      identifiers: [12],
    },
    {
      header: 'Hobbies and fun.',
      type: 'Questions',
      identifiers: [9],
    },
    {
      header: 'My deepest passion is.',
      type: 'Questions',
      identifiers: [3],
    },
    {
      header: 'Excercise and Sports.',
      type: 'Questions',
      identifiers: [9],
    },
    {
      header: 'Dream Job.',
      type: 'Questions',
      identifiers: [24],
    },
    {
      header: 'Home and habbits.',
      type: 'Questions',
      identifiers: [10],
    },
    {
      header: 'I feel most at home when.',
      type: 'Questions',
      identifiers: [4],
    },
    {
      header: 'Culture and Media.',
      type: 'Questions',
      identifiers: [11],
    },
    {
      header: 'The most fun I remember when is.',
      type: 'Questions',
      identifiers: [6],
    },
    {
      header: 'To me my family is.',
      type: 'Questions',
      identifiers: [5],
    },
  ];

  const fetchUserProfileDetailsById = () => {
    let data = {
    //   id: convo?.prospective_dater?.id,
    id:otherProfile !=undefined ? otherProfile?.user?.id: activeFriendDater?.id,
    };
    setLoading(true);
    getUserProfileByID.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('getMyProfile onSuccess: ', response);
        setUserDetails(response?.data?.payload);
        // setNameAgeLocation(
        //   response?.data?.payload?.user?.first_name +
        //     ', ' +
        //     response?.data?.payload?.age !=null? response?.data?.payload?.age:'' +
        //     ' ' +
        //     response?.data?.payload?.city,
        // );
        setNameAgeLocation(`${response?.data?.payload?.user?.first_name}${response?.data?.payload?.age !=null?','+response?.data?.payload?.age:'' } ${response?.data?.payload?.city}`)
        setProfilePic(response?.data?.payload?.profile_pic || null);
        setUserImages(response?.data?.payload?.images || []);
        fetchAllQuestions();
      },
      onError: (err) => {
        console.log('getMyProfile onError: ', err);
        setLoading(false);
      },
    });
  };
  const fetchAllQuestions = () => {
    setLoading(true);
    getAllQuestions.mutate(
      {},
      {
        onSuccess: (response) => {
          setLoading(false);
          console.log('getAllQuestions onSuccess: ', response);
          setAllQuestions(response?.data?.payload?.results);
          fetchAllAnswers();
        },
        onError: (error) => {
          setLoading(false);
          console.log('getAllQuestions onError :', error);
          setLoading(false);
        },
      },
    );
  };
  const fetchAllAnswers = () => {
    let answerData = {
      limit: 200,
      offset: 0,
    //   user: convo?.prospective_dater?.id,
    user:otherProfile !=undefined ? otherProfile?.user?.id: activeFriendDater?.id,
    };
    setLoading(true);
    getResponseForAllQuesionsByUser.mutate(answerData, {
      onSuccess: (response) => {
        let arrAnswer = response?.data?.payload?.results;
        console.log('arrAnswer: ', arrAnswer);
        setAllAnswers(arrAnswer);
        setLoading(false);
        fetchFriendList();
      },
      onError: (error) => {
        setAllAnswers(null);
        setLoading(false);
      },
    });
  };
  const fetchFriendList = () => {
    setLoading(true);
    let data = {
    //   dater: convo?.prospective_dater?.id,
    dater:otherProfile !=undefined ? otherProfile?.user?.id: activeFriendDater?.id,
    };
    getUsersFriendList.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('getUsersFriendList success:', response);
        setFriendsList(response?.data?.payload?.results);
      },
      onError: (error) => {
        setLoading(false);
        console.log('getUsersFriendList error:', error);
        setFriendsList([]);
      },
    });
  };
  const onAlertClose = () => {
    setAlertMsg('');
  };
 
 

  const handleBackClicked=(e)=>{
    setBackRoute(lastRoute)
    // onBackClicked()
  }

  const friendsClickCallBack = () => {
    console.log('friendsClickCallBack');
    setShowOtherFriendsList(true);
  };
  const friendsBackClickCallBack = () => {
    setShowOtherFriendsList(false);
  };
  useEffect(() => {
    // fetchUserProfileDetailsById(convo?.prospective_dater?.id);
    fetchUserProfileDetailsById(otherProfile !=undefined ? otherProfile?.user?.id:activeFriendDater?.id);
  }, []);

  return (
    <>
      {alertMsg.length > 0 && (
        <CustomAlert
          isOpen={alertMsg.length > 0 ? true : false}
          message={alertMsg}
          onClose={onAlertClose}
        />
      )}
      <Loader open={loading} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pb:  3,
          pt: !chatFlag && scaleByRatio(12),
          // background: '#FFFFFF',
          background: '#000',
          height: `${scaleByRatio(85)}vh`,
        }}>
        {!loading && !showOtherFriendsList && (
          <>
            <Grid
              container
              direction="row"
              alignItems="flex-end"
              sx={{
                // background: '#FFFFFF',
                background: '#000',
                minHeight: `${scaleByRatio(50)}px`,
                paddingBottom: `${scaleByRatio(10)}px`,
              }}>
              <Grid
                item
                xs={1}
                justifyContent="left"
                alignItems="flex-end"
                className={styles.logoContainer}>
                <img
                  src={ArrowLeft}
                  alt={'Back icon'}
                  className={styles.logo}
                  onClick={otherProfile !=undefined || chatFlag ? onBackClicked : handleBackClicked}
                  width={scaleByRatio(24)}
                  height={scaleByRatio(24)}
                />
              </Grid>
              <Grid item xs={10} justifyContent="center">
                <Typography
                  textAlign="center"
                  className={styles.nameAddressHeader}
                  style={{
                    fontSize: `${scaleByRatio(24)}px`,
                  }}>
                  {nameAgeLocation}
                </Typography>
              </Grid>
            </Grid>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                pb: 3,
                p: 3,
                // background: '#FFFFF',
                background: '#000',
                color: '#fff',
              }}>
              <MyProfileAboutUs
                profilePic={profilePic}
                allAnswers={allAnswers}
                userDetails={userDetails}
                handleChipClick={() => {}}
                setClickable={() => {}}
                friendsList={friendsList}
                handleMenuClicked={() => {}}
                setShowAddFriend={() => {}}
                pageType={ProfilePageType.Convos}
                elbow_request_status={null}
                convos_direct_session_introw_status={
                //   convo?.direct_session_introw_status
                true
                }
                // onNoClicked={onNoClicked}
                // onYesClicked={onYesClicked}
                // onMaybeClicked={() => {}}
                friendsClickExploreCallBack={friendsClickCallBack}
              />

              <MyProfileMyInterests
                allAnswers={allAnswers}
                handleChipClick={() => {}}
              />
              <MyProfilePhotos
                arrImages={userImages}
                handleChipClick={() => {}}
              />
              {/* <MyProfileSectionView
                arrProfileSections={arrProfileSections}
                allAnswers={allAnswers}
                clickable={false}
                handleChipClick={() => {}}
              /> */}
                <MyProfileOthersData
                      allQuestions={allQuestions}
                      allAnswers={allAnswers}
                      clickable={false}
                      // setEditMode={setEditMode}
                    />
            </Box>
          </>
        )}
        {!loading && showOtherFriendsList && (
          <OthersFriendList
            // dater_id={convo?.prospective_dater?.id}
            dater_id={otherProfile !=undefined ? otherProfile?.user?.id: activeFriendDater?.id}
            // dater_name={convo?.prospective_dater?.user?.first_name}
            dater_name={otherProfile !=undefined ? otherProfile?.user?.name:activeFriendDater?.user?.first_name}
            onBackClicked={friendsBackClickCallBack}
            // activeDater={convo?.prospective_dater?.id}
            activeDater={otherProfile !=undefined ? otherProfile?.user?.id:activeFriendDater?.id}
            showReachout={false}
          />
        )}
      </Box>
    </>
  );
};

export default DaterProfileView;
