import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import {
    useGetMyDaters,
    useGetActiveDater,
    usePostActiveDate
} from '../../services/mutations'
import Loader from '../../molecule/Loader'
import DaterListItem from '../../molecule/DaterListItem'
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import { Grid, Typography } from '@mui/material'
import { scaleByRatio } from '../../utils/helper'

const MyDaters = ({ handleCurrentDater }) => {
    const [datersList, setDatersList] = useState([])
    const [activeUser, setActiveuser] = useState(null)
    const [loading, setLoading] = useState(false)
    const getMyDaters = useGetMyDaters()
    const getActiveDater = useGetActiveDater()
    const postActiveDate = usePostActiveDate()

    const fetchMyDaters = () => {
        setLoading(true)
        let data = {}
        getMyDaters.mutate(data, {
            onSuccess: (response) => {
                let arrDaters = response?.data?.payload?.results;
                setDatersList(arrDaters)
                console.log('getMyDaters success: ', arrDaters)
                getActiveDater.mutate(data, {
                    onSuccess: (response) => {
                        console.log('getActiveDater success: ', response)
                        setActiveuser(response?.data?.payload?.user);
                        setLoading(false)

                    },
                    onError: (error) => {
                        setLoading(false)
                        console.log('getActiveDater error: ', error)
                    }
                })

            },
            onError: (error) => {
                setLoading(false)
                console.log('getMyDaters error: ', error)
            }
        })
    }

    const onChangeView = (status, friendID) => {
        console.log('onChangeView friendID:', friendID)
        console.log('onChangeView status:', status)
        if (status) {
            let data = {
                friend_id: friendID
            }
            setLoading(true)
            postActiveDate.mutate(data, {
                onSuccess: (response) => {
                    setLoading(false)
                    fetchMyDaters()
                },
                onError: (error) => {
                    console.log('Error: ', error)
                    setLoading(false)
                    fetchMyDaters()
                }
            });
            handleCurrentDater();
        }
    }

    useEffect(() => {
        console.log('My Daters Called')
        fetchMyDaters()
    }, [])
    return (
        <>
            <Loader open={loading} />
            <Box component="main" sx={{ flexGrow: 1, pb: 9, pt: 3, pr: 3, pl: 3, marginTop: "80px" }}>
                <Grid container direction="row" alignItems="flex-end" sx={{ minHeight: "50px", paddingBottom: "10px" }}>
                    <Grid item xs={12} justifyContent="center">
                        <Typography textAlign='center' className={styles.header}
                        >
                            View My Daters
                        </Typography>
                    </Grid>
                    <Grid item xs={12} justifyContent="left">
                        <Typography textAlign='left' className={styles.subheader}
                        >
                            Choose which Dater's activity you want to view (You can support up to three Daters but only view one at a time).
                        </Typography>
                    </Grid>
                </Grid>


                <Stack direction="column" spacing={scaleByRatio(2)} className={styles.stackClass} flexWrap="wrap">
                    {!loading && datersList.length > 0 && datersList.map((dater) => {
                        return (
                            <DaterListItem
                                daterDetails={dater}
                                callBackViewChange={onChangeView}
                                activeUser={activeUser}
                            />
                        )
                    })}
                </Stack>
            </Box>
        </>
    );
}

export default MyDaters;