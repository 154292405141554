import './App.scss';
import { RouterProvider } from 'react-router-dom';
import router from './utils/setup/router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useWindowSize } from './utils/useWindowSize';
import {
  ActiveDaterContext,
  ChatClose,
  DaterProfileBackRoute,
  ExploreCloseContext,
  FriendDaterId,
  GroupChatCloseContext,
  ReachOutContext,
  ReportConvoCounter,
  ReportElbowCounter,
} from './utils/Context/reportCounter';
import { useState, useEffect } from 'react';
import StickyFooter from './organism/StickyFooter';
import usePushNotification from './utils/hooks/usePushNotification';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Explore, Group } from '@mui/icons-material';

const queryClient = new QueryClient({});
function App() {
  const [elbowCount, setElbowCount] = useState(0);
  const [convoCount, setConvoCount] = useState(0);
  const [activeFriendDater, setActiveFriendDater] = useState(null);
  const [reachOutHandle, setReachOutHandle] = useState(false);
  const [friendDaterId,setFriendDaterId]=useState(null)
  const [backRoute, setBackRoute] = useState(null);
  const [chatColse, setChatClose] = useState(false);
  const [groupChatClose, setGroupChatClose] = useState(false);
  const [exploreClose, setExploreClose] = useState(false);
  const [height, width] = useWindowSize();
  const [getwidth, setWidth] = useState(0);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window?.innerWidth);
    }
  }, [width]);
  // useEffect(() => {
  //   let deferredPrompt;

  //   const beforeInstallPromptHandler = (event) => {
  //     console.log('beforeinstallprompt event:', event);
  //     event.preventDefault();
  //     deferredPrompt = event;
  //   };

  //   // Attach the event listener when the component mounts
  //   window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
  //   };
  // }, []);

  return (
    <div>
      <ReportElbowCounter.Provider value={[elbowCount, setElbowCount]}>
        <ReportConvoCounter.Provider value={[convoCount, setConvoCount]}>
          <ReachOutContext.Provider value={[reachOutHandle, setReachOutHandle]}>
            <ActiveDaterContext.Provider value={[activeFriendDater,setActiveFriendDater]}>
              <FriendDaterId.Provider value={[friendDaterId,setFriendDaterId]}>
                <DaterProfileBackRoute.Provider value={[backRoute, setBackRoute]}>
                  <ChatClose.Provider value={[chatColse, setChatClose]}>
                    <GroupChatCloseContext.Provider value={[groupChatClose, setGroupChatClose]}>
                      <ExploreCloseContext.Provider value={[exploreClose, setExploreClose]}>
            <QueryClientProvider client={queryClient}>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                theme="light"
              />
              <RouterProvider router={router} />
            </QueryClientProvider>
            </ExploreCloseContext.Provider>
            </GroupChatCloseContext.Provider>
            </ChatClose.Provider>
            </DaterProfileBackRoute.Provider>
            </FriendDaterId.Provider>
            </ActiveDaterContext.Provider>
          </ReachOutContext.Provider>
          {/* {
            getwidth < 900 &&
            <StickyFooter />
          } */}
        </ReportConvoCounter.Provider>
      </ReportElbowCounter.Provider>
    </div>
  );
}

export default App;
