import React, { useState, useEffect } from 'react';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import AppLogo from '../../assets/AppLogo.svg';
import { Grid } from '@mui/material';
const DashboardPage = () => {
  return (
    <>
      <Grid backgroundColor="black">
        {/* <CentralGridLayout> */}
        <Grid style={{ marginTop: '45vh' }}>
          <img src={AppLogo} alt="Applogo" width="100%" height={'70vh'} />
        </Grid>
        {/* </CentralGridLayout> */}
      </Grid>
    </>
  );
};

export default DashboardPage;
