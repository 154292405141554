import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import styles from './index.module.scss';
import FriendListAddItem from '../../molecule/FriendListAddItem';
import FriendListItem from '../../molecule/FriendListItem';
import {
  useGetUsersFriendList,
  useDeleteFriend,
  usePatchEditFriend,
} from '../../services/mutations';
import {
  localStorageKeys,
  useLocalStorage,
} from '../../utils/localStorageItems';
import AddFriendFrom from '../../organism/AddFriendForm';
import Stack from '@mui/material/Stack';
import Loader from '../../molecule/Loader';
import { scaleByRatio } from '../../utils/helper';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import CustomAlert from '../../atoms/CustomAlert';

const MyFriends = () => {
  const getUsersFriendList = useGetUsersFriendList();
  const localStorageItem = useLocalStorage();
  const deleteFriendById = useDeleteFriend();
  const editFriend = usePatchEditFriend();
  const [errorMsg, setErrorMsg] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [friendsList, setFriendsList] = useState([]);
  const [showAddFriend, setShowAddFriend] = useState(false);
  const [showAddFriendRow, setShowAddFriendRow] = useState(false);
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();
  const fetchFriendList = () => {
    setLoading(true);
    let data = {
      dater: localStorageItem.getLocalStorageItem(localStorageKeys.profileId),
    };
    getUsersFriendList.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('getUsersFriendList success:', response);
        console.log('getUsersFriendList success result:', response?.data?.payload?.results);
        console.log('getUsersFriendList success result length:', response?.data?.payload?.results?.length);
        setFriendsList(response?.data?.payload?.results);
        if (response?.data?.payload?.results?.length <3) {
          setShowAddFriendRow(true);
        }
        else{
          setShowAddFriendRow(false);
        }
      },
      onError: (error) => {
        setLoading(false);
        console.log('getUsersFriendList error:', error);
        setFriendsList([]);
        setShowAddFriendRow(true);
      },
    });
  };

  const deleteFriend = (_id) => {
    setLoading(true);
    console.log('deleteFriend:', _id);
    let data = {
      id: _id,
    };
    deleteFriendById.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        fetchFriendList();
      },
      onError: (error) => {
        setLoading(false);
        setErrorMessage(error?.response?.data?.error)
        console.log(error);
      },
    });
  };

  const changeStatus = (status, _id) => {
    setLoading(true);
    console.log('changeStatus: ', status);
    let data = {
      id: _id,
      data: {
        active: status,
      },
    };
    editFriend.mutate(data, {
      onSettled: () => {
        setLoading(false);
        fetchFriendList();
      },
      onError: (error) => {
        // setLoading(false);
        setErrorMessage(error?.response?.data?.error)
        console.log('changeStatus error:', error?.response?.data?.error);
        
      }
    });
  };

  const addFriendcallBack = () => {
    setShowAddFriend(true);
  };
  const addFriendBackButtonClick = () => {
    setShowAddFriend(false);
  };
  const onAlertClose = () => {
    setErrorMessage('');
  };
  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };
  useEffect(() => {
    if (!showAddFriend) {
      fetchFriendList();
    }
  }, [showAddFriend]);
  return (
    <>
      <Loader open={loading} />
      {errorMessage.length>0 && (
        <CustomAlert 
        isOpen={errorMessage.length>0 ? true : false}
        message={errorMessage}
        onClose={onAlertClose}
        />
      )}
      {!showAddFriend && (
        <Box
          component="main"
          bgcolor={'#000'}
          sx={{ flexGrow: 1, pb: 9, pt: 3, pr: 1, pl: 3, marginTop: '80px' }}>
          <Grid
            display={'flex'}
            justifyContent={'flex-end'}
            color={'#fff'}
            // marginTop={'-35px'}
            // marginRight={'40px'}
            style={{
              cursor: 'pointer',
              fontWeight: 'bold',
              width: '99%',
              // marginTop: '-25px',
            }}
            onClick={handleClose}>
            <Typography color={'#fff'}>X</Typography>
          </Grid>
          <Stack
            direction="column"
            bgcolor={'#000'}
            spacing={2}
            className={styles.stackClass}
            flexWrap="wrap">
            {friendsList &&
              friendsList.length > 0 &&
              friendsList.map((friend) => {
                return (
                  <FriendListItem
                    friendDetails={friend}
                    callBackDeleteFriend={deleteFriend}
                    callBackActiveInactive={changeStatus}
                  />
                );
              })}
            {showAddFriendRow && (
              <FriendListAddItem callBackFunction={addFriendcallBack} />
            )}
          </Stack>
        </Box>
      )}
      {showAddFriend && !loading && (
        <Box
          component="main"
          bgcolor={'#000'}
          sx={{
            flexGrow: 1,
            pb: 9,
            pt: 3,
            pr: 3,
            pl: 3,
            marginTop: `${scaleByRatio(80)}px`,
          }}>
          <AddFriendFrom onBackClicked={addFriendBackButtonClick} />
        </Box>
      )}
    </>
  );
};

export default MyFriends;
