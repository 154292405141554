import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Profile_Avatar from '../../assets/Profile_Avatar.png';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { scaleByRatio } from '../../utils/helper';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import TickGreen from '../../assets/TickGreen.svg';
import CloseRed from '../../assets/CloseRed.svg';

const ConvosListItem = ({
  convo,
  handleProfileClicked,
  activeDaterFriend,
  setDirectChatChannel,
  setShowDirectMessage,
  setDirectChatMyProfile,
  setDirectChatOtherProfile,
  setDirectChatConvo,
  lastMessage,
}) => {
  console.log('lastMessage :', lastMessage);
  const localStorage = useLocalStorage();
  const myProfileID = localStorage.getLocalStorageItem(
    localStorageKeys.profileId,
  );
  const [myProfile, setMyProfile] = useState(null);
  const [otherProfile, setOtherProfile] = useState(null);
  const [isDirectMessageEnabled, setIsDirectMessageEnabled] = useState(false);
  // const [showDirectMessage, setShowDirectMessage] = useState(false)

  // const onDirectChatBackClicked = ()=>{
  //     setShowDirectMessage(false)
  // }

  const startDirectMessaging = () => {
    console.log('startDirectMessaging :', isDirectMessageEnabled);
    if (isDirectMessageEnabled) {
      console.log('start direct messaging');
      setDirectChatChannel(convo?.id);
      setShowDirectMessage(true);
      setDirectChatMyProfile(myProfile);
      setDirectChatOtherProfile(otherProfile);
      setDirectChatConvo(convo);
    }
  };
  useEffect(() => {
    let allowDirectMessage = true;
    convo?.convoSessionMembers.forEach((sessionMember) => {
      if (sessionMember?.user?.id === myProfileID) {
        setMyProfile(sessionMember);
      } else {
        setOtherProfile(sessionMember);
      }
      //     if(sessionMember.status !== 'A'){
      //         allowDirectMessage = false;
      //    }
    });
    if (convo?.elbow?.request_status !== 'A') {
      allowDirectMessage = false;
    } else {
      allowDirectMessage = true;
    }
    setIsDirectMessageEnabled(allowDirectMessage);
  }, []);
  return (
    <>
      <Grid
        container
        direction="row"
        columnSpacing={0.5}
        className={styles.root}
        onClick={() => {
          console.log('On Click');
          startDirectMessaging();
        }}
        >
        <div className={styles.convosFirstDiv}>
          <Grid
            item
            alignItems={'center'}
            justifyContent="left"
            // style={{ paddingTop: `${scaleByRatio(5)}px` }}
          >
            {/* {convo.direct_session_introw_status === 'A' && (
              <Typography
                textAlign={'left'}
                alignItems={'center'}
                className={styles.introwStatus}
                style={{
                  display: `flex`,
                }}>
                <img
                  src={TickGreen}
                  alt="Successful Introw"
                  style={{
                    width: `${scaleByRatio(18)}px`,
                    height: `${scaleByRatio(18)}px`,
                    paddingRight: `${scaleByRatio(5)}px`,
                  }}
                />
                Successful Introw
              </Typography>
            )}
            {convo.direct_session_introw_status === 'D' && (
              <Typography
                textAlign={'left'}
                alignItems={'center'}
                className={styles.introwStatus}
                style={{
                  display: `flex`,
                }}>
                <img
                  src={CloseRed}
                  alt="UnSuccessful Introw"
                  style={{
                    width: `${scaleByRatio(18)}px`,
                    height: `${scaleByRatio(18)}px`,
                    paddingRight: `${scaleByRatio(5)}px`,
                  }}
                />
                Introw unsuccessful
              </Typography>
            )} */}
            <Typography textAlign="left">
              <img
                src={convo?.prospective_dater?.profile_pic || Profile_Avatar}
                alt="profile"
                width={scaleByRatio(140)}
                height={scaleByRatio(140)}
                style={{
                  borderRadius: `8px`,
                  border: `2px solid ${
                    convo?.elbow?.interest_type === 'Y' ? '#02E242' : '#F90'
                  }`,
                  cursor: `${
                    convo?.elbow.request_status === 'A' ? 'pointer' : 'default'
                  }`,
                }}
                // onClick={() => {
                //   if (convo?.elbow.request_status === 'A') {
                //     handleProfileClicked(convo);
                //   }
                // }}
              />
            </Typography>
          </Grid>
          <Grid
            item
            justifyContent="left"
            alignItems="start"
            style={{
              // paddingTop: `${scaleByRatio(5)}px`,
              display: 'flex',
              cursor: `${isDirectMessageEnabled ? 'pointer' : 'default'}`,
            }}
            onClick={() => {
              console.log('On Click');
              startDirectMessaging();
            }}>
            {/* <Grid container direction="column" justifyContent="left"> */}
            <Stack
              direction="column"
              spacing={scaleByRatio(1.8)}
              className={styles.stackClass}
              flexWrap="wrap"
              style={{ display: 'flex' }}>
              <Grid
                item
                xs={12}
                alignItems={'start'}
                style={{ display: 'flex', marginTop: '-70px' }}>
                {convo.direct_session_introw_status === 'A' ||
                convo.direct_session_introw_status === 'D' ? (
                  <Typography
                    fullwidth
                    textAlign="left"
                    className={styles.profileName}
                    style={{ paddingBottom: `${scaleByRatio(7)}vh` }}
                    onClick={() => {
                      console.log('On Click');
                      startDirectMessaging();
                    }}>
                    {`${convo?.prospective_dater?.user?.first_name},
                    ${convo?.prospective_dater?.age}`}
                  </Typography>
                ) : (
                  <Typography
                    fullwidth
                    textAlign="left"
                    className={styles.profileName}
                    onClick={() => {
                      console.log('On Click');
                      startDirectMessaging();
                    }}>
                    {/* {convo?.prospective_dater?.user?.first_name} */}
                    {/* {convo?.prospective_dater?.age} */}
                    {`${convo?.prospective_dater?.user?.first_name},
                    ${convo?.prospective_dater?.age}`}
                  </Typography>
                )}
              </Grid>

              {/* {convo?.direct_session_introw_status == 'P' && (
                <Grid
                  item
                  xs={12}
                  alignItems={'start'}
                  style={{ display: 'flex' }}>
                  <Typography
                    fullwidth
                    textAlign="left"
                    className={styles.elbowStatus}
                    style={{
                      lineHeight: `${scaleByRatio(20)}px`,
                      // color: `#000000`,
                      color: '#76d9ff',
                      float: `left`,
                      fontSize: `${scaleByRatio(20)}px`,
                      textWrap: 'nowrap',
                    }}>
                    Introw Requested.
                  </Typography>
                </Grid>
              )} */}

              {lastMessage?.file && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justifyContent="left">
                  <Grid
                    item
                    xs={12}
                    alignItems={'start'}
                    style={{ display: 'flex' }}>
                    <Typography
                      fullwidth
                      textAlign="left"
                      className={styles.introwStatus}
                      style={{
                        lineHeight: `${scaleByRatio(24)}px`,
                        float: `left`,
                      }}>
                      <img
                        src={lastMessage?.file?.url}
                        alt={''}
                        width={scaleByRatio(50)}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {!lastMessage?.file && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justifyContent="left">
                  <Grid
                    item
                    xs={12}
                    alignItems={'start'}
                    style={{ display: 'flex' }}>
                    <Typography
                      fullwidth
                      textAlign="left"
                      className={styles.introwStatus}
                      style={{
                        lineHeight: `${scaleByRatio(24)}px`,
                        float: `left`,
                        color: `#fff`,
                        width: '100px',
                        overflow: 'hidden',
                        textWrap: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}>
                      {lastMessage?.message}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Stack>
            {/* </Grid> */}
          </Grid>
        </div>
        <div className={styles.convosSecDiv}>
          <Grid
            item
            justifyContent="right"
            alignItems="start"
            style={{
              paddingTop: `${scaleByRatio(5)}px`,
              display: 'flex',
              cursor: `${isDirectMessageEnabled ? 'pointer' : 'default'}`,
            }}
            onClick={() => {
              console.log('On Click');
              startDirectMessaging();
            }}>
            <Typography textAlign="left" className={styles.date}>
              {dayjs(convo?.created_on).format('DD/MM/YY HH:mm')}
            </Typography>
          </Grid>

          <Grid>
            <Grid
              item
              display={'flex'}
              justifyContent={'center'}
              // style={{ marginLeft: `${scaleByRatio(30)}px` }}
              // onClick={handleDaterProfile}
            >
              <Grid
                item
                xs={2}
                sm={1.2}
                alignItems={'center'}
                justifyContent="right"
                style={{ paddingTop: `${scaleByRatio(40)}px` }}>
                <Typography textAlign="left">
                  {/* <img
                    src={
                      convo?.prospective_dater?.profile_pic || Profile_Avatar
                    }
                    alt="profile"
                    width={scaleByRatio(60)}
                    height={scaleByRatio(70)}
                    // style={{
                    //   borderRadius: `8px`,
                    //   border: `2px solid ${
                    //     convo?.elbow?.interest_type === 'Y'
                    //       ? '#02E242'
                    //       : '#F90'
                    //   }`,

                    //   // cursor:`${(convo?.elbow.request_status === 'A')?'pointer':'default'}`
                    // }}
                    onClick={() => {
                      // if(convo?.elbow.request_status === 'A'){
                      //     handleProfileClicked(convo)
                      // }
                    }}
                  /> */}
                  <img
                    src={`${activeDaterFriend?.profile_pic}`}
                    alt={''}
                    style={{
                      width: `${scaleByRatio(60)}px`,
                      height: `${scaleByRatio(60)}px`,
                      borderRadius: `8px`,
                      // border: `2px solid ${
                      //   convo?.elbow?.interest_type === 'Y' ? '#02E242' : '#F90'
                      // }`,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>

            <Grid
              color={'#fff'}
              display={'flex'}
              style={{
                width: 'auto',
                alignItems: 'center',
                float: 'right',
                color: '#fff',
                paddingRight: '70px',
                marginTop: '-25px',
                fontSize: '20px',
                marginLeft: '-100px',
              }}>
              <Grid className={styles.friendOf}>Interested in </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default ConvosListItem;
