import React, { useState, useEffect, useRef } from 'react';
import styles from '../MyProfileFriend/index.module.scss';

import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import CustomSwitch from '../../atoms/CustomSwitch';
import Profile_Avatar from '../../assets/Profile_Avatar.png';
import IconUploadImage from '../../assets/IconUploadImage.svg';
import { scaleByRatio } from '../../utils/helper';
import InputField from '../../atoms/InputField';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import ImageCropper from '../../molecule/ImageCropper/ImageCropper';
import Webcam from 'react-webcam';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const MyProfileFriendEdit = ({
  first_name,
  last_name,
  profile_pic,
  description,
  errorMsg = '',
  handleCancelEditOnchange,
  handleOnSubmitCallback,
}) => {
  const inputImage = useRef(null);
  const [firstName, setFirstName] = useState(first_name);
  const [lastName, setLastName] = useState(last_name);
  const [descriptionFriendship, setDescriptionFriendship] =
    useState(description);
  const [error, setError] = useState(errorMsg);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileDetails, setImageFileDetails] = useState(null);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [fileUpload, setFileUpload] = useState({ imageUrl: null, flag: false });

  const handleSubmit = () => {
    if (firstName === '') {
      setError('Please provide your First Name');
      return;
    }
    if (lastName === '') {
      setError('Please provide your Last Name');
      return;
    }
    if (descriptionFriendship === '') {
      setError('Please describe your friendship.');
      return;
    }
    if (descriptionFriendship.length > 300) {
      setError('Please describe your friendship within 300 characters.');
      return;
    }
    let data = {
      first_name: firstName,
      last_name: lastName,
      profile_pic: imageFileDetails,
      description: descriptionFriendship,
    };
    handleOnSubmitCallback(data);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [showWebcamModal, setShowWebcamModal] = useState(false);

  const webcamRef = useRef(null);

  const handleMenuOption = (option) => {
    if (option === 'chooseImage') {
      inputImage.current.click();
    } else if (option === 'openCamera') {
      setShowWebcamModal(true);
    }
    setAnchorEl(null);
  };

  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageFile(imageSrc);
    setShowWebcamModal(false);
    setShowImageCropper(true);
    setFileUpload({
      imageUrl: imageSrc,
      flag: true,
    });
  };

  const handleInputImage = (e) => {
    console.log(e.target.files);
    setImageFile(URL.createObjectURL(e.target.files[0]));
    setShowImageCropper(true);
    setFileUpload({
      imageUrl: URL.createObjectURL(e.target.files[0]),
      flag: true,
    });
    setImageFileDetails(e.target.files[0]);
  };
  const callBackImageCropped = (imageFile) => {
    setShowImageCropper(false);
    console.log('callBackImageCropped::', imageFile);
    setImageFile(URL.createObjectURL(imageFile));
    setImageFileDetails(imageFile);
    setFileUpload({
      imageUrl: URL.createObjectURL(imageFile),
      flag: true,
    });
  };
  const callBackImageNotCropped = () => {
    setShowImageCropper(false);
    setFileUpload({ imageUrl: null, flag: false });
    console.log('callBackImageNotCropped');
  };
  const handleCloseModalImageCropper = () => {
    setShowImageCropper(false);
    setFileUpload({ imageUrl: null, flag: false });
    console.log('handleCloseModalImageCropper');
  };
  const handleFirstNameChange = (evt) => {
    setError('');
    setFirstName(evt.target.value);
  };
  const handleLastNameChange = (evt) => {
    setError('');
    setLastName(evt.target.value);
  };
  const handleDescriptionChange = (evt) => {
    setError('');
    setDescriptionFriendship(evt.target.value);
  };
  useEffect(() => {
    //console.log('UploadPhotoBlock Useeffect called: ',selectedImage)
    setImageFile(profile_pic);
  }, [profile_pic]);
  useEffect(() => {
    console.log('errorMsg: ', errorMsg);
    setError(errorMsg);
  }, [errorMsg]);
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      columnSpacing={0.5}
      sx={{
        display: 'flex',
        padding: `${scaleByRatio(20)}px`,
        gap: '5px',
      }}>
      <Grid
        item
        justifyContent="right"
        style={{ position: 'relative' }}
        className={styles.profilepicContainer}>
        <Typography
          sx={{
            position: 'absolute',
            bottom: `${scaleByRatio(45)}px`,
            left: `${scaleByRatio(30)}px`,
            display: 'block',
          }}>
          <CustomSwitch
            txtSwitchOn={'Edit On'}
            txtSwitchOff={'Edit Off'}
            checked={true}
            handleChange={handleCancelEditOnchange}
          />
        </Typography>

        <Typography
          sx={{ textAlign: { xs: 'center', sm: 'left' } }}
          style={{ display: 'block', cursor: 'pointer' }}
          onClick={(event) => {
            // inputImage.current.click()
            setAnchorEl(event.currentTarget);
          }}>
          <img src={imageFile} className={styles.friendPic} alt="profile"  style={{borderRadius: '15px'}} />
        </Typography>
        <input
          type="file"
          accept="image/*"
          className={'displayNone'}
          ref={inputImage}
          onChange={handleInputImage}
          onClick={(evt) => {
            evt.target.value = null;
          }}
        />
        {showImageCropper && (
          <ImageCropper
            ImageURL={fileUpload?.imageUrl}
            callBackImageCropped={callBackImageCropped}
            callBackImageNotCropped={callBackImageNotCropped}
            handleCloseModalCallBack={handleCloseModalImageCropper}
          />
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}>
          <MenuItem onClick={() => handleMenuOption('chooseImage')}>
            Choose an Image
          </MenuItem>
          <MenuItem onClick={() => handleMenuOption('openCamera')}>
            Open Camera
          </MenuItem>
        </Menu>
        <Modal
          open={showWebcamModal}
          onClose={() => setShowWebcamModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}>
            <IconButton
              aria-label="close"
              onClick={() => setShowWebcamModal(false)}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'text.secondary',
              }}>
              <CloseIcon />
            </IconButton>
            <Webcam
              style={{
                width: '100%',
                height: 'auto',
              }}
              ref={webcamRef}
            />
            <Button
              onClick={capturePhoto}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}>
              Capture Photo
            </Button>
          </Box>
        </Modal>
      </Grid>
      <Grid item className={styles.profileDataContainer}>
        <Grid container direction="column" style={{ gap: '20px' }}>
          <Grid item xs={12} alignItems="flex-start">
            <Typography
              fullwidth
              textAlign="center"
              style={{ color: '#76d9ff' }}
              className={styles.subHeader}>
              Profile Information
            </Typography>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={4} alignItems="flex-start">
              <Typography fullwidth textAlign="left" className={styles.details}>
                Name
              </Typography>
            </Grid>
            <Grid item xs={3.75} alignItems="flex-start">
              <Typography
                fullwidth
                textAlign="right"
                className={styles.details}>
                <InputField
                  name="FirstName"
                  id={'FirstName'}
                  value={firstName}
                  placeholder={'First Name (Required)'}
                  handleChange={(evt) => handleFirstNameChange(evt)}
                  type={'text'}
                  class_name={styles.name}
                  minHeightPX={`${scaleByRatio(56)}`}
                />
              </Typography>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={3.75} alignItems="flex-start">
              <Typography fullwidth textAlign="left" className={styles.details}>
                <InputField
                  name="LastName"
                  id={'LastName'}
                  value={lastName}
                  placeholder={'Last Name (Required)'}
                  handleChange={(evt) => handleLastNameChange(evt)}
                  type={'text'}
                  class_name={styles.name}
                  minHeightPX={`${scaleByRatio(56)}`}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={4} alignItems="flex-start">
              <Typography fullwidth textAlign="left" className={styles.details}>
                Describe Your Friendship
              </Typography>
            </Grid>
            <Grid item xs={8} alignItems="flex-start">
              <Typography
                fullwidth
                textAlign="right"
                className={styles.details}>
                <InputField
                  name="descriptionFriendship"
                  id={'descriptionFriendship'}
                  value={descriptionFriendship}
                  placeholder={'Describe Your Friendship'}
                  handleChange={(evt) => handleDescriptionChange(evt)}
                  type={'text'}
                  class_name={styles.name}
                  minHeightPX={`${scaleByRatio(56)}`}
                  multiline={true}
                  rows={5}
                />
              </Typography>
            </Grid>
          </Grid>
          {error.length > 0 && (
            <Grid container direction="row">
              <Grid item>
                <Typography
                  fullwidth
                  textAlign="center"
                  className={styles.error}>
                  {error}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container direction="row">
            <Grid
              item
              xs={12}
              justifyContent={'center'}
              style={{ display: 'flex' }}
              className={styles.submitBtn}>
              <CustomButton
                text={'Submit'}
                variant={ButtonVariant.contained}
                type={ButtonType.primary}
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MyProfileFriendEdit;
