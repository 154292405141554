import React, {useEffect, useState} from 'react'
import { Button } from '@mui/material'
import styles from './input.module.scss'
import {scaleByRatio} from '../../utils/helper'

export const ButtonVariant = {
    text:'text',
    contained: 'contained',
    outlined: 'outlined'
}

export const ButtonType = {
    primary:'primary',
    secondary:'secondary',
    custom:'custom',
    yes:'yes',
    no:'no'
}

export const CustomButton = ({
    variant,
    text,
    onClick,
    type = ButtonType.primary,
    backgroundColor = null,
}) => {
    const [styleType, setStyleType] = useState(styles.primary)
    useEffect(()=>{
        if(variant !== ButtonVariant.outlined){

        
            if(type === ButtonType.primary){
                setStyleType(styles.primary)
            }
            if(type === ButtonType.secondary){
                setStyleType(styles.secondary)
            }
            if(type === ButtonType.custom){
                setStyleType(styles.custom)
            }
            if(type === ButtonType.yes){
                setStyleType(styles.yes)
            }
            if(type === ButtonType.no){
                setStyleType(styles.no)
            }
        }else{
            setStyleType(styles.outlined)
        }
    },[])
    return ( 
        <Button
        variant={variant}
        onClick={(e)=>onClick(e)}
        className={`${styles.button} ${styleType} ${styles.customBtnFont}`}
        style={{
            padding:`${scaleByRatio(10)}px ${scaleByRatio(20)}px`,
            lineHeight: `${scaleByRatio(20)}px`
        }}
        >
            {text}
        </Button>

     );
}