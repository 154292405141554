import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import styles from './index.module.scss';
import {
  useGetConvosList,
  usePostMakeIntrow,
  usePatchDeclineIntrow,
  usePatchAcceptDeclineConvo,
  useGetActiveDater,
  useGetFriendConvo,
} from '../../services/mutations';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import Loader from '../../molecule/Loader';
import ConvosListItem from '../../molecule/ConvosListItem';
import ConvosProspectiveDaterProfile from '../ConvosProspectiveDaterProfile';
import CustomAlert from '../../atoms/CustomAlert';
import { scaleByRatio, getTime } from '../../utils/helper';
import ConvosListItemDater from '../../molecule/ConvosListItemDater';
import DirectChat from '../../molecule/DirectChat';
import { usePubNub } from 'pubnub-react';
import AppLogo from '../../assets/AppLogo.svg';
import ConvosListItemFriend from '../../molecule/ConvosListItemFriend';

const Convos = () => {
  const localStorage = useLocalStorage();
  const pubnub = usePubNub();
  const getConvosList = useGetConvosList();
  const postMakeIntrow = usePostMakeIntrow();
  const patchDeclineIntrow = usePatchDeclineIntrow();
  const patchAcceptDeclineConvo = usePatchAcceptDeclineConvo();
  const getActiveDater = useGetActiveDater();
  const getFriendConvoList = useGetFriendConvo();
  const [convoList, setConvoList] = useState([]);
  const [convoFriendList, setConvoFriendList] = useState([]);
  const [activeDater, setActiveDater] = useState(null);

  const [loading, setLoading] = useState([]);
  const [prospectiveConvo, setProspectiveConvo] = useState(null);
  const [alertMsg, setAlertMsg] = useState('');
  const [channels, setChannels] = useState([]);
  const [historicalMessages, setHistoricalMessages] = useState([]);

  const [directChatChannel, setDirectChatChannel] = useState(null);
  const [showDirectMessage, setShowDirectMessage] = useState(false);
  const [directChatMyProfile, setDirectChatMyProfile] = useState(null);
  const [directChatOtherProfile, setDirectChatOtherProfile] = useState(null);
  const [directChatConvo, setDirectChatConvo] = useState(null);
  const [activeDaterFriend, setActiveDaterFriend] = useState(null);

  const handleuserType = () => {
    if (localStorage.getLocalStorageItem('userType') === 'dater') {
      return 'Dater to Dater';
    } else if (localStorage.getLocalStorageItem('userType') === 'friend') {
      return 'Daters interested in your friend';
    }
  };

  const fetchActiveDater = () => {
    let data = {};
    setLoading(true);
    getActiveDater.mutate(data, {
      onSuccess: (response) => {
        console.log('getActiveDater success: ', response);
        setActiveDaterFriend(response?.data?.payload?.friend);
        setActiveDater(response?.data?.payload?.user);
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        console.log('getActiveDater error: ', error);
      },
    });
  };

  const fetchConvosList = () => {
    setLoading(true);
    let data = {};
    // if (
    //   localStorage.getLocalStorageItem(localStorageKeys.userType) ===
    //   localStorageUserType.friend
    // ) {
    //   data = {
    //     friend_id: activeDaterFriend.id,
    //   };
    // }
    getConvosList.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('fetchConvosList onSuccess: ', response);
        setConvoList(response?.data?.payload?.results);
      },
      onError: (error) => {
        console.log('fetchConvosList onError: ', error);
        setLoading(false);
      },
    });
  };

  const fetchFriendConvoList = () => {
    setLoading(true);
    let data = {};
    if (
      localStorage.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.friend
    ) {
      data = {
        friend_id: activeDaterFriend.id,
      };
    }
    getFriendConvoList.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('fetchFriendConvoList onSuccess: ', response);
        setConvoFriendList(response?.data?.payload?.results);
      },
      onError: (error) => {
        console.log('fetchFriendConvoList onError: ', error);
        setLoading(false);
      },
    });
  };
  const handleProfileClicked = (convo) => {
    setProspectiveConvo(convo);
  };
  const onProspectiveDaterProfileBackClicked = () => {
    setProspectiveConvo(null);
    populateConvosList();
  };

  const handleButtonClickedCallback = (status, convo_id) => {
    console.log('handleButtonClickedCallback:: ', status, convo_id);
    if (status === 'A') {
      makeIntrow(convo_id);
    } else {
      declineIntrow(convo_id);
    }
  };

  const makeIntrow = (convo_id) => {
    setLoading(true);
    let data = {
      convo_id: convo_id,
    };
    postMakeIntrow.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        setAlertMsg('Introw Created');
      },
      onError: (error) => {
        setLoading(false);
        onProspectiveDaterProfileBackClicked();
      },
    });
  };

  const declineIntrow = (convo_id) => {
    setLoading(true);
    let data = {
      convo_id: convo_id,
    };

    patchDeclineIntrow.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        setAlertMsg('Introw Declined');
      },
      onError: (error) => {
        setLoading(false);
        onProspectiveDaterProfileBackClicked();
      },
    });
  };

  const onAlertClose = () => {
    setAlertMsg('');
    onProspectiveDaterProfileBackClicked();
    populateConvosList();
  };

  const populateConvosList = () => {
    console.log('useEffect Friend');
    fetchConvosList();
    if (
      localStorage.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.friend
    )
      fetchFriendConvoList();
  };

  const handleAcceptRejectCallback = (status, session_member_id) => {
    console.log('handleAcceptRejectCallback :', status, session_member_id);
    updateConvoStatus(status, session_member_id);
  };

  const updateConvoStatus = (status, session_member_id) => {
    setLoading(true);
    let data = {
      status: status,
      session_member_id: session_member_id,
    };
    patchAcceptDeclineConvo.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        populateConvosList();
      },
      onError: (error) => {
        setLoading(false);
      },
    });
  };

  const onDirectChatBackClicked = () => {
    setShowDirectMessage(false);
    populateConvosList();
  };
  const prepareHistoricalMessage = (channelMessageArray) => {
    console.log('prepareHistoricalMessage: ', channelMessageArray);
    let key;
    let messageArr = [];
    if (channelMessageArray) {
      for (key in channelMessageArray) {
        console.log('Key: ', channelMessageArray[key][0]);
        messageArr.push(channelMessageArray[key][0]);
      }
    }
    console.log('messageArr:', messageArr);
    let arr = [];
    if (messageArr && messageArr.length > 0) {
      messageArr?.forEach((messageElem) => {
        if (messageElem?.message?.message?.type !== 'image') {
          let message = {
            channel: messageElem?.channel,
            ownerProfileID: messageElem?.uuid,
            profile_pic: messageElem?.message?.user?.profilePic,
            message: messageElem?.message?.text,
            time: getTime(parseInt(messageElem.timetoken), 'DD/MM/YY HH:mm'),
            file: null,
          };
          arr.push(message);
        } else {
          console.log('File message: ', messageElem);
          let channel = messageElem?.channel;
          let file_id = messageElem?.message?.file?.id;
          let file_name = messageElem?.message?.file?.name;
          const file_url = pubnub.getFileUrl({
            channel: channel,
            id: file_id,
            name: file_name,
          });
          console.log('file url: ', file_url);
          let fileMessage = {
            channel: channel,
            profile_pic: messageElem?.message?.message?.user?.profilePic,
            message: '',
            time: getTime(parseInt(messageElem.timetoken), 'DD/MM/YY HH:mm'),
            ownerProfileID: messageElem?.uuid,
            file: {
              id: file_id,
              name: file_name,
              url: file_url,
            },
          };
          arr.push(fileMessage);
        }
      });
      console.log('prepareHistoricalMessage final:', arr);
      setHistoricalMessages(arr);
    }
  };

  useEffect(() => {
    if (convoList && convoList.length > 0) {
      //subscribe to pubnub to get historical message
      // create channels
      let arrChannels = [];
      convoList.forEach((convo) => {
        arrChannels.push(convo.id);
      });
      setChannels(arrChannels);
    }
  }, [convoList]);
  useEffect(() => {
    console.log('useeffect subscription:', channels);
    if (channels.length > 0) {
      // const listenerParams = { message: handleDirectMessage }
      // const fileListnerParams = { file: handleFiles}
      // pubnub.addListener(listenerParams);
      // pubnub.addListener(fileListnerParams);
      pubnub.subscribe({ channels });

      let currentToken = (
        Math.floor(new Date('2023.10.01').getTime() / 1000) * 10000000
      ).toString();
      setLoading(true);
      pubnub.fetchMessages(
        {
          channels: channels,
          end: currentToken, //'16970822938689523',
          count: 1,
        },
        function (status, response) {
          console.log('>>>>>>>>>>>> Historical message start');
          console.log(status, response);
          if (status?.statusCode === 200) {
            prepareHistoricalMessage(response.channels || null);
          }

          console.log('>>>>>>>>>>>> Historical message end');
          setLoading(false);
        },
      );
    }
    return () => {
      pubnub.unsubscribe({ channels });
      // pubnub.removeListener({ message: handleDirectMessage })
      // pubnub.removeListener({ file: handleFiles})
    };
  }, [pubnub, channels]);
  useEffect(() => {
    if (
      localStorage.getLocalStorageItem(localStorageKeys.userType) ===
        localStorageUserType.friend &&
      activeDaterFriend
    ) {
      populateConvosList();
    }
  }, [activeDaterFriend]);
  useEffect(() => {
    if (
      localStorage.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.friend
    ) {
      fetchActiveDater();
    } else {
      populateConvosList();
    }
  }, []);
  return (
    <>
      <Loader open={loading} />
      {alertMsg.length > 0 && (
        <CustomAlert
          isOpen={alertMsg.length > 0 ? true : false}
          message={alertMsg}
          onClose={onAlertClose}
        />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // pb: 4.5,
          pt: scaleByRatio(3),
          marginTop: `${scaleByRatio(80)}px`,
          paddingBottom: '70px',
        }}>
        {!loading && !prospectiveConvo && !showDirectMessage && (
          // convoList.length > 0 &&
          <>
            <Grid
              container
              direction="row"
              alignItems="flex-end"
              sx={{ minHeight: '50px', paddingBottom: '10px' }}>
              <Grid item xs={12} display={'flex'} justifyContent="center">
                <Typography textAlign="center" className={styles.header}>
                  {/* Convos */}
                  {handleuserType()}
                </Typography>
                {localStorage.getLocalStorageItem('userType') === 'dater' && (
                  <Typography paddingTop={'5px'}>
                    <img src={AppLogo} />
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} justifyContent="left">
                <Typography
                  textAlign="left"
                  className={styles.subheader}
                  style={{
                    lineHeight: `${scaleByRatio(24)}px`,
                    paddingLeft: `${scaleByRatio(15)}px`,
                  }}>
                  {/* This is where you can find all of you conversations. */}
                </Typography>
              </Grid>
            </Grid>
            {/* {localStorage.getLocalStorageItem(localStorageKeys.userType) ===
              localStorageUserType.friend &&
              convoList.map((convo) => {
                if (
                  convo?.elbow?.request_status === 'A' ||
                  convo?.elbow?.request_status === 'E'
                ) {
                  return (
                    <ConvosListItem
                      convo={convo}
                      handleProfileClicked={handleProfileClicked}
                      setDirectChatChannel={setDirectChatChannel}
                      setShowDirectMessage={setShowDirectMessage}
                      setDirectChatMyProfile={setDirectChatMyProfile}
                      setDirectChatOtherProfile={setDirectChatOtherProfile}
                      lastMessage={
                        historicalMessages.length > 0
                          ? historicalMessages.find(
                              (history) => history.channel === convo?.id,
                            ) || null
                          : null
                      }
                    />
                  );
                }
              })} */}
            {localStorage.getLocalStorageItem(localStorageKeys.userType) ===
              localStorageUserType.dater &&
              convoList.map((convo) => {
                if (convo?.session_type === 'DIC') {
                  return (
                    <ConvosListItemDater
                      convo={convo}
                      handleProfileClicked={handleProfileClicked}
                      handleAcceptRejectCallback={handleAcceptRejectCallback}
                      setDirectChatChannel={setDirectChatChannel}
                      setShowDirectMessage={setShowDirectMessage}
                      setDirectChatMyProfile={setDirectChatMyProfile}
                      setDirectChatOtherProfile={setDirectChatOtherProfile}
                      setDirectChatConvo={setDirectChatConvo}
                      lastMessage={
                        historicalMessages.length > 0
                          ? historicalMessages.find(
                              (history) => history.channel === convo?.id,
                            ) || null
                          : null
                      }
                    />
                  );
                }
              })}

            {localStorage.getLocalStorageItem(localStorageKeys.userType) ===
              localStorageUserType.friend &&
              convoFriendList.map((convo) => {
                if (convo?.session_type === 'DIC') {
                  return (
                    <ConvosListItemFriend
                      convo={convo}
                      handleProfileClicked={handleProfileClicked}
                      handleAcceptRejectCallback={handleAcceptRejectCallback}
                      setDirectChatChannel={setDirectChatChannel}
                      setShowDirectMessage={setShowDirectMessage}
                      setDirectChatMyProfile={setDirectChatMyProfile}
                      setDirectChatOtherProfile={setDirectChatOtherProfile}
                      setDirectChatConvo={setDirectChatConvo}
                      lastMessage={
                        historicalMessages.length > 0
                          ? historicalMessages.find(
                              (history) => history.channel === convo?.id,
                            ) || null
                          : null
                      }
                    />
                  );
                }
              })}
          </>
        )}
        {prospectiveConvo && !showDirectMessage && (
          <ConvosProspectiveDaterProfile
            onBackClicked={onProspectiveDaterProfileBackClicked}
            handleButtonClickedCallback={handleButtonClickedCallback}
            convo={prospectiveConvo}
          />
        )}
        {!prospectiveConvo && showDirectMessage && (
          <DirectChat
            myProfile={directChatMyProfile}
            activeDaterFriend={activeDater}
            otherProfile={directChatOtherProfile}
            channel={directChatChannel}
            onBackClicked={onDirectChatBackClicked}
            convo={directChatConvo}
          />
        )}
      </Box>
    </>
  );
};

export default Convos;
