import React, {useState, useEffect} from 'react'
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout'
import CentralGridLayout from '../../Layout/CentralGridLayout'
import TermAndConditions from '../../organism/TermAndConditions'
import styles from './index.module.scss';
import {  Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import { scaleByRatio } from '../../utils/helper';
import Box from "@mui/material/Box";
import { display } from '@mui/system';
import './styles.scss';

const TermsAndConditionPage = () => {
    useEffect(()=>{
        console.log('TermsAndConditionPage')
    },[])
    const onBackClicked = () =>{
        console.log('Back clicked')
        window.history.back()
    }
    return ( 
        <>
        <CentralContentPageLayout>
        {/* <CentralGridLayout> */}
        <Box component="main" justifyContent="center" sx={{ 
            flexGrow: 1,
            pb:3, 
            pt:scaleByRatio(3), 
            margin:`auto`, 
            marginTop: `${scaleByRatio(120)}px`,
            //pl:scaleByRatio(6)
            
        }}>
            
            <Grid container direction="row" justifyContent="center" alignItems="flex-end" xs={12} >
                <TermAndConditions 
                showAcceptButton={false}
                showPageName={true}
                maxheight={50}
                showBackButton={true}
                onBackClicked={onBackClicked}
                />
            </Grid>
        </Box>
        {/* </CentralGridLayout> */}
        </CentralContentPageLayout>
    </>
     );
}
 
export default TermsAndConditionPage;