import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconMessage from '../../assets/IconMessage.svg';
import { scaleByRatio, getFormattedDate } from '../../utils/helper';
import BlueDot from '../../assets/BlueDot.svg';

const NotificationListItem = ({ notificationItem, onClickCallBack }) => {
  const [notification, setNotification] = useState(notificationItem);
  const handleOnclick = () => {
    if (!notification?.read) {
      onClickCallBack(notificationItem);
    }
  };
  return (
    <>
      {notification && (
        <Grid
          container
          direction="row"
          columnSpacing={0.5}
          className={styles.root}
          style={{
            padding: `${scaleByRatio(16)}px`,
            backgroundColor: `${
              //   notification?.read ? '#161414e0' : '#00597b1a'
              notification?.read ? '#00597b1a' : '#00597B'
            }`,
            cursor: `${notification?.read ? 'default' : 'pointer'}`,
            // gap: `${scaleByRatio(14)}px`,
            marginBottom: `${scaleByRatio(14)}px`,
            gap: '5px',
            alignItems: 'flex-start',
          }}
          onClick={handleOnclick}>
          <Grid
            item
            justifyContent="right"
            // style={{paddingTop:`${scaleByRatio(5)}px`}}
          >
            <Typography
              textAlign={'right'}
              alignItems="center"
              className={styles.introwStatus}
              style={{
                fontSize: `${scaleByRatio(16)}px`,
                display: `flex`,
              }}>
              <img
                src={IconMessage}
                alt="message"
                style={{
                  width: `${scaleByRatio(32)}px`,
                  height: `${scaleByRatio(32)}px`,
                  borderRadius: `${scaleByRatio(8)}px`,
                }}
              />
            </Typography>
          </Grid>
          <Grid
            item
            justifyContent="left"
            className={styles.notificationItemBody}>
            <Grid
              container
              direction="row"
              columnSpacing={0.5}
              className={styles.root}>
              <div
                className="noti-head-time"
                style={{
                  display: 'flex',
                  gap: '5px',
                  width: '100%',
                  justifyContent: 'space-between',
                }}>
                <Grid
                  item
                  justifyContent="left"
                  className={styles.heading}
                  style={{
                    fontSize: `${scaleByRatio(18)}px`,
                  }}>
                  {notification?.data?.headings?.en}
                  {!notification?.read && (
                    <img
                      src={BlueDot}
                      alt="BlueDot"
                      style={{
                        width: `${scaleByRatio(12)}px`,
                        marginLeft: `${scaleByRatio(18)}px`,
                      }}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  justifyContent="right"
                  className={styles.date}
                  style={{
                    fontSize: `${scaleByRatio(12)}px`,
                    lineHeight: `${scaleByRatio(16)}px`,
                    display: `flex`,
                  }}>
                  {getFormattedDate(notification?.updated_on)}
                </Grid>
              </div>

              <Grid
                item
                xs={12}
                justifyContent="left"
                className={styles.content}
                style={{
                  fontSize: `${scaleByRatio(16)}px`,
                  lineHeight: `${scaleByRatio(24)}px`,
                  paddingLeft: '0px',
                }}>
                {notification?.data?.contents?.en}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default NotificationListItem;
