import React from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import { scaleByRatio } from '../../utils/helper';

const LetsGetStarted = ({ onNextClicked, onBackClicked }) => {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item className={styles.logoContainer}>
          <img
            src={ArrowLeft}
            alt={'Back icon'}
            className={styles.logo}
            onClick={onBackClicked}
            width={scaleByRatio(24)}
            height={scaleByRatio(24)}
          />
        </Grid>
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.back}
            onClick={onBackClicked}
            style={{
              fontSize: `${scaleByRatio(16)}px`,
              paddingLeft: `${scaleByRatio(10)}px`,
              paddingBottom: `${scaleByRatio(5)}px`,
            }}>
            Back
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.heading}
            style={{
              fontSize: `${scaleByRatio(32)}px`,
            }}>
            {/* What's your Email? */}
            Let's get started
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Typography
          textAlign="left"
          className={styles.steps}
          style={{
            fontSize: `${scaleByRatio(32)}px`,
            lineHeight: `${scaleByRatio(40)}px`,
          }}>
          1. Set up your basic profile (a few minutes)
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Typography
          textAlign="left"
          className={styles.then}
          style={{
            fontSize: `${scaleByRatio(24)}px`,
          }}>
          then
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Typography
          textAlign="left"
          className={styles.steps}
          style={{
            fontSize: `${scaleByRatio(32)}px`,
            lineHeight: `${scaleByRatio(40)}px`,
          }}>
          2. Invite a friend or two to join you on your journey
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Typography
          textAlign="left"
          className={styles.smallText}
          style={{
            fontSize: `${scaleByRatio(24)}px`,
          }}>
          You'll be able to refine and enhance your profile over time. Just
          note, your profile won't become active until atleast one friend is
          connected to you
        </Typography>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="end"
        alignItems="flex-end">
        <Grid item>
          <CustomButton
            text={'Start That Now'}
            variant={ButtonVariant.contained}
            type={ButtonType.primary}
            onClick={onNextClicked}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LetsGetStarted;
