import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import CustomSwitch from '../../atoms/CustomSwitch';
import SwitchActive from '../../assets/SwitchActive.svg';
import SwitchInactive from '../../assets/SwitchInactive.svg';
import Profile_Avatar from '../../assets/Profile_Avatar.png';
import Stack from '@mui/material/Stack';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { scaleByRatio } from '../../utils/helper';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import MessageDialog from '../../molecule/MessageDialog';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { ReachOutContext } from '../../utils/Context/reportCounter';

const OthersFriendListItem = ({
  friendDetails,
  callBackReachOut,
  showReachOutButton = false,
}) => {
  const localStorage = useLocalStorage();
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const handleReachOutClicked = (status) => {
    // console.log('handleOnClick: ', status)
    // if (localStorage.getLocalStorageItem(localStorageKeys.userType) === localStorageUserType.dater) {
    //     setOpenMessageDialog(true)
    // } else {
    //     callBackReachOut(friendDetails?.id)
    // }
    callBackReachOut(friendDetails?.id);
  };
  const messageSuccess = (message) => {
    setOpenMessageDialog(false);
    console.log('message: ', message);
    callBackReachOut(friendDetails?.id, message);
  };
  const messageCancel = () => {
    setOpenMessageDialog(false);
  };
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        columnSpacing={0.5}
        className={styles.root}
        style={{
          paddingTop: `${scaleByRatio(15)}px`,
        }}>
        <div className={styles.firstDiv}>
          <Grid item justifyContent="left" style={{ paddingTop: '5px' }}>
            <Typography textAlign="left">
              <img
                src={friendDetails?.profile_pic || Profile_Avatar}
                alt="profile"
                width={scaleByRatio(106)}
                style={{ borderRadius: '10px' }}
              />
            </Typography>
          </Grid>
          <Grid
            item
            justifyContent="left"
            style={{ paddingTop: `${scaleByRatio(5)}px` }}>
            {/* <Grid container direction="column" justifyContent="left"> */}
            <Stack
              direction="column"
              spacing={scaleByRatio(1.8)}
              className={styles.stackClass}
              flexWrap="wrap">
              <Grid item xs={12} alignItems="flex-start">
                <Typography
                  fullwidth
                  textAlign="left"
                  className={styles.profileName}>
                  {friendDetails?.first_name}
                </Typography>
              </Grid>
              <Grid item xs={12} justifyContent="left">
                <Typography textAlign="left" className={styles.description}>
                  {friendDetails?.description}
                </Typography>
              </Grid>
            </Stack>
          </Grid>
        </div>
        <div className={styles.secDiv}>
          <Grid item alignItems="flex-end">
            <>
              {showReachOutButton && (
                <Typography
                  fullwidth
                  textAlign="left"
                  alignItems="flex-end"
                  className={styles.lastUpdate}>
                  <CustomButton
                    text={'Reach Out'}
                    variant={ButtonVariant.contained}
                    type={ButtonType.primary}
                    onClick={handleReachOutClicked}
                  />
                </Typography>
              )}
              {showReachOutButton && openMessageDialog && (
                <MessageDialog
                  isOpen={openMessageDialog}
                  headingpart1="Write your"
                  headingpart2="message"
                  onYesClick={messageSuccess}
                  onNoClick={messageCancel}
                />
              )}
            </>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default OthersFriendListItem;
