import React, { useEffect, useState } from 'react'
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import SwtichUnlock from '../../assets/SwitchUnlock.svg'
import SwitchInactive from '../../assets/SwitchInactive.svg'
import Typography from '@mui/material/Typography';
import styles from './index.module.scss'
import { style } from "@mui/system";
import { scaleByRatio } from '../../utils/helper'

const CustomSwitch = ({
  txtSwitchOn = '',
  txtSwitchOff = '',
  iconThumb = SwtichUnlock,
  iconThumbUncheck = SwitchInactive,
  checked,
  disabled,
  switchWidth = '100',
  handleChange,
  btnWidth,
  btnTranslateX
}) => {
  //const [isChecked, setIsChecked] = useState(checked)

  const handleSwitchClicked = () => {
    handleChange(!checked)
  }
  useEffect(() => {
    console.log('CustomSwitch :', checked)
  })
  return (
    <>
      <div className={styles.switchRoot}  onClick={() => handleSwitchClicked()}>
        {checked && (
          <Typography
            variant="subtitle2"
            className={styles.labelLeft}
            style={{
              width: `${scaleByRatio(0.5 * switchWidth)}px`,
              left: `${scaleByRatio(6)}px`,
              lineHeight: `${scaleByRatio(14)}px`,
            }}
          >
            {txtSwitchOn}
          </Typography>
        )}
        <Switch
          checked={checked}
          disableRipple
          onChange={() => handleSwitchClicked()}
          sx={{
            "&.MuiSwitch-root": {
              width: `${btnWidth ? btnWidth * switchWidth : 0.8 * switchWidth}px`,
              height: '32px',
              padding: `0px`,
              borderRadius: `${scaleByRatio(48)}px`,
              border: `1px solid ${checked ? '#FF8500' : '#CDCDCD'}`,

            },
            "& .MuiSwitch-switchBase": {
              padding: scaleByRatio(1),
              borderRadius: `48px`,
              "&.Mui-checked": {
                transform: `${btnTranslateX ? `translateX(${btnTranslateX}px)` : `translateX(${scaleByRatio(0.8 * switchWidth) - (scaleByRatio(30))}px)`}`,
                "&+.MuiSwitch-track": {
                  opacity: 1,
                  border: "none",
                  backgroundColor: "#FFFFFF",
                },
                "& .MuiSwitch-thumb:before": {
                  backgroundImage: `url(${iconThumb})`,
                  backgroundSize: `${scaleByRatio(30)}px ${scaleByRatio(28)}px`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }
              }
            },
            "& .MuiSwitch-thumb": {
              width: `${scaleByRatio(14)}px`,
              height: `${scaleByRatio(14)}px`,

              "&:before": {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "100%",
                left: 0,
                top: 0,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: `url(${iconThumbUncheck})`,
                backgroundSize: `${scaleByRatio(30)}px ${scaleByRatio(28)}px`
              }
            },
            "& .MuiSwitch-track": {
              borderRadius: "13px",
              backgroundColor: "#FFFFFF",
              opacity: 1,
            }
          }}

        />
        {!checked && (
          <Typography
            variant="subtitle2"
            className={styles.labelRight}
            style={{
              width: `${scaleByRatio(0.5 * switchWidth)}px`,
              left: `${scaleByRatio(35)}px`,
              fontSize: `${scaleByRatio(12)}px`,
              lineHeight: `${scaleByRatio(14)}px`,
            }}

          >
            {txtSwitchOff}
          </Typography>
        )}
      </div>

    </>
  );
}

export default CustomSwitch;