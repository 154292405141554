import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { scaleByRatio } from '../../utils/helper';
import styles from "./index.module.scss"
import './index.module.scss'
import {  Grid } from '@mui/material'

const ImageCropper = (
    {
        ImageURL,
        callBackImageCropped,
        callBackImageNotCropped,
        handleCloseModalCallBack
    }
) => {
    const [show, setShow] = useState(false);
    const [cropper, setCropper] = useState(null);
    const handleClose = () =>{
        callBackImageNotCropped(null);
        setShow(false);
    } 
    const handleCloseModal = ()=>{
      handleCloseModalCallBack();
      setShow(false);
    }
    useEffect(() => {
      console.log('Cropper useeffect')
        if(ImageURL){
          setShow(true)
        }
        
      }, [])
    const getCropData = async () => {
      
        if (cropper) {
          const file = await fetch(cropper.getCroppedCanvas().toDataURL())
            .then((res) => res.blob())
            .then((blob) => {
              return new File([blob], "newAvatar.png", { type: "image/png" });
            });
          if (file) {
            callBackImageCropped(file);
            handleCloseModal()
          }else{
            handleClose()
          }
        }
      };
    return ( 
        <>
        <Modal open={show} onClose={handleCloseModal} 
        >
          <Box sx={{ maxWidth: {xs:300, sm:375},
           flexGrow: 1, backgroundColor:'#FFFFFF', padding:`${scaleByRatio(50)}px`}}>
          <Grid container direction="row"  style={{display:'flex'}}>
          <Grid container direction="row" justifyContent={"center"}>
            <Typography fullwidth textAlign={"center"}
                style={{
                  fontFamily: 'Knuckle Down',
                  fontWeight: "400",
                  textAlign: "center",
                  color:"#00597B",
                  fontSize:`${scaleByRatio(32)}px`,
                  lineHeight:`${scaleByRatio(38)}px`,
                  height:`${scaleByRatio(45)}px`,
            }}>
                Crop Image

          </Typography>
          </Grid>
          <Grid container direction="row">
            <Typography fullwidth textAlign={"center"}>
              <Cropper
                  src={ImageURL}
                  style={{ height: 400, width: 300, margin: "auto"
                  }}
                  initialAspectRatio={1 / 1}
                  minCropBoxHeight={80}
                  minCropBoxWidth={80}
                  maxCropBoxHeight={80}
                  maxCropBoxWidth={80}
                  guides={false}
                  dragMode={"move"}
                  checkOrientation={false}
                  cropBoxResizable={false}
                  autoCropArea={1} 
                  onInitialized={(instance) => {
                      setCropper(instance);
                  }}
              />
              <div style={{textAlign: "center", marginTop: "5px"}}>
              
              </div>
            </Typography>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={6} >
            <Typography fullwidth textAlign={"right"} justifyContent={"right"} style={{display:'flex'}}>
              <Button variant="secondary" 
                style={{marginRight: "15px"}} 
                onClick={()=>handleClose()}
                className={`${styles.button}`}
                >Cancel</Button>
              
            </Typography>
          </Grid>
          <Grid item xs={6} >
              <Typography fullwidth textAlign={"left"}>
                <Button 
                className={`${styles.button} ${styles.primary} `}
                onClick={getCropData}>
                    Crop Image
                </Button>
              </Typography>
          </Grid>
          </Grid>
          </Grid>
          </Box>
        </Modal>
        </>
     );
}
 
export default ImageCropper;