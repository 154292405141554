import React, {useState, useEffect} from 'react'
import styles from './index.module.scss'
import {  Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import { scaleByRatio } from '../../utils/helper';
import Slider from '@mui/material/Slider';
import { AnswerType } from '../../utils/constants'
import { useWindowSize } from '../../utils/useWindowSize';

const RangeSlider = (
    {
        question,
        id,
        identifier,
        minValue,
        maxValue,
        question_color,
        displayMin,
        displayMax,
        selectedValue,
        handleOnChangeCallBack,
        question_type
    }
) => {
    const [value, setValue] = useState(selectedValue);
    const [_displayMin, set_displayMin] = useState(displayMin)
    const [_displayMax, set_displayMax] = useState(displayMax)
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    const toFeet = (n)=>{

        let heightin = Math.round(parseInt(n) / 2.54)
        let heightft = Math.floor(heightin/12)
        let remainingInch = heightin - (Math.floor(heightft)*12)
        
        // var realFeet = ((n*0.393700) / 12);
        // var feet = Math.floor(realFeet);
        // var inches = Math.round((realFeet - feet) * 12);
        return heightft + "'" + remainingInch + '"';
      }
  
    const handleChange = (event, newValue) => {
        console.log('handleChange: ', newValue)
        setValue(newValue);
        if(identifier === 'height'){
            set_displayMin(toFeet(newValue[0]))
            set_displayMax(toFeet(newValue[1]))
        }
        if(identifier === 'age'){
            set_displayMin(newValue[0])
            set_displayMax(newValue[1])
        }
        handleOnChangeCallBack(newValue, id, identifier, question_type, AnswerType.Range)
        
      };
    return ( 
        <>
        <Grid container direction="row" justifyContent="left" alignItems="flex-end">
            <Grid item >
                <Typography textAlign='left' className={styles.question} 
                style={{
                    color:`${question_color}`,
                    lineHeight: `${scaleByRatio(21.6)}px`
                }}
                >
                {question}
                </Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="flex-end" style={{
            marginBottom:`${scaleByRatio(15)}px`
        }} className="datingRange">
            <Grid item xs={getwidth > 1200 ? 1 : 2} alignItems={"flex-end"}  justifyContent="left" >
                <Typography textAlign='center' className={styles.sliderBox} 
                style={{
                    paddingBottom: `${scaleByRatio(16)}px`,
                    paddingTop: `${scaleByRatio(16)}px`,
                    paddingRight: `${scaleByRatio(12)}px`,
                    paddingLeft: `${scaleByRatio(12)}px`,
                    fontSize: `${scaleByRatio(18)}px`,
                    lineHeight: `${scaleByRatio(24)}px`,
                }}
                >
                    {_displayMin}
                </Typography>
            </Grid>
            <Grid item xs={getwidth > 1200 ? 10 : 8} alignItems={"flex-start"}  justifyContent="left" >
                <Typography  style={{paddingBottom: `${scaleByRatio(10)}px`, margin: `${scaleByRatio(5)}px`,}}>
                <Slider
                    getAriaLabel={() => 'Height range'}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="off"
                    min={minValue}
                    max={maxValue}
                    size={'small'}
                    sx={{
                        "& .MuiSlider-track":{
                            color:"#F93"
                        },
                        "& .MuiSlider-thumb":{
                            color:"#F93"
                        },
                        "& .MuiSlider-rail":{
                            color:"#F93"
                        },
                    }}
                />
                </Typography>
            </Grid>
            <Grid item xs={getwidth > 1200 ? 1 : 2} alignItems={"center"}  justifyContent="left" >
                <Typography textAlign='center' className={styles.sliderBox} 
                style={{
                    paddingBottom: `${scaleByRatio(16)}px`,
                    paddingTop: `${scaleByRatio(16)}px`,
                    paddingRight: `${scaleByRatio(12)}px`,
                    paddingLeft: `${scaleByRatio(12)}px`,
                    fontSize: `${scaleByRatio(18)}px`,
                    lineHeight: `${scaleByRatio(24)}px`,
                }}>
                    {_displayMax}
                </Typography>
            </Grid>
        </Grid>
        </>
     );
}
 
export default RangeSlider;