import React, { useState, useEffect } from 'react'
import MyProfileSection from '../MyProfileSection'
import {AnswerType} from '../../utils/constants'

/*
arrProfileSections = [
    {
        header: 'Section Name',
        identifiers:["24"]
    }
]

sectionDetails = [
    {
        header:'Section Name',
        chips:[<chips>],
        description:'description if any'
    }
]
*/
const MyProfileSectionView = ({
    arrProfileSections,
    allAnswers=[],
    clickable=false
}) => {
    const [sectionDetails, setSectionDetails] = useState([])
    // const [header, setHeader] = useState('Dream job')
    // const [chips, setChips] = useState([])
    // const [description, setDescription] = useState('')
   // let identifiers =["24"]

    const getDetailsByIdentifiers = ()=>{
        let arrDetails = []
        for(let i = 0; i < arrProfileSections.length; i++){
            let header = arrProfileSections[i]?.header
            let identifiers = arrProfileSections[i]?.identifiers;
            let chipType = arrProfileSections[i]?.type || 'Questions'
            let arrChips = [];
            let description = ''
            for(let j=0; j<identifiers.length;j++){
                let identifier = identifiers[j]
                let chipsObj = null;
                let _description = ''
                allAnswers.forEach(answerItem => {
                    if(answerItem?.answer?.question?.identifier === identifier){
                        if(answerItem?.answer?.question?.choice_type !== AnswerType.TF){
                            chipsObj = {};
                            chipsObj.label = answerItem?.answer?.value;
                            chipsObj.QuestionID = answerItem?.answer?.question?.id
                            chipsObj.identifier = identifier
                            chipsObj.clickable = clickable
                            chipsObj.type = chipType
                        }
                        else{
                            //setDescription(answerItem?.answer?.value || '')
                            _description = answerItem?.answer?.value || '';
                        }
                        
                    }
                });
                if(chipsObj){
                    arrChips.push(chipsObj)
                }
                description = _description
            }
            // setChips(arrChips)
            let detailobj = {
                header: header,
                chips: arrChips,
                description: description
            }
            arrDetails.push(detailobj)
        }
        setSectionDetails(arrDetails)

    }

    useEffect(()=>{
        if(allAnswers && allAnswers?.length>0){
            getDetailsByIdentifiers()
        }
        
    },[allAnswers])

    return ( 
        <> 
        {sectionDetails && sectionDetails.length > 0 && sectionDetails.map((section)=>{
            return(
                <>
                    {(section.description.length>0 || (section.chips && section.chips.length > 0)) && (
                        <MyProfileSection
                        heading={section.header}
                        description={section.description}
                        arrChips={section.chips}
                        />
                    )}
                </>
            )
        })}
        
        </>
     );
}
 
export default MyProfileSectionView;