import React, { useState } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { scaleByRatio } from '../../utils/helper';
import List from '@mui/material/List';
import ListItemRadio from '../../molecule/ListItemRadio';
import { AnswerType } from '../../utils/constants';
import DownArrow from '../../assets/DownArrow.svg';
import UpArrow from '../../assets/UpArrow.svg';
let listItemClass = {
  display: 'flex',
  height: `${scaleByRatio(48)}px`,
  padding: `${scaleByRatio(8)}px`,
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: `${scaleByRatio(73)}px`,
  borderBottom: '1px solid #F6F7F7',
};

let textClass = {
  color: '#1C1D1D',
  fontFamily: 'Myriad Pro',
  fontSize: `${scaleByRatio(18)}px`,
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: `${scaleByRatio(32)}px` /* 200% */,
};

const DatingPreferenceSingleChoice = ({
  question,
  id,
  identifier,
  question_choices,
  question_color,
  selectedAnswer = [],
  handleOnChangeCallBack,
  question_type,
}) => {
  const [checked, setChecked] = useState(selectedAnswer);
  const [showData, setShowData] = useState(false);
  const handleAnswerChecked = (value) => {
    let arr = [];
    arr.push(value);
    setChecked(arr);
    handleOnChangeCallBack(arr, id, identifier, question_type, AnswerType.SC);
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.question}
            style={{
              // color: `${question_color}`,
              color: '#76d9ff',
              lineHeight: `${scaleByRatio(21.6)}px`,
            }}>
            {question}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        display={'flex'}
        justifyContent={'flex-end'}
        alignContent={'flex-end'}
        onClick={() => setShowData(!showData)}
        style={{ cursor: 'pointer' }}
        mt={-2}>
        {showData ? (
          <img
            src={UpArrow}
            alt="dropIcon"
            width={'45px'}
            // style={{ marginRight: '15px' }}
          />
        ) : (
          <img
            src={DownArrow}
            alt="upIcon"
            width={'45px'}
            // style={{ marginRight: '15px' }}
          />
        )}
      </Grid>
      {showData && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <List dense sx={{ width: '100%', color: '#fff' }}>
            {question_choices.map((answer) => {
              return (
                <ListItemRadio
                  value={answer.id}
                  onChange={handleAnswerChecked}
                  isChecked={checked.indexOf(answer.id) !== -1}
                  id={answer.id}
                  displayText={answer.value}
                  listItemClass={listItemClass}
                  textClass={textClass}
                />
              );
            })}
          </List>
        </Grid>
      )}
    </>
  );
};

export default DatingPreferenceSingleChoice;
