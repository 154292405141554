import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import InputField from '../../atoms/InputField';
import { CustomButton } from '../../atoms/CustomButton';
import { usePostShareApp } from '../../services/mutations';
import styles from './index.module.scss';
import { scaleByRatio } from '../../utils/helper';
import Loader from '../../molecule/Loader';
import CustomAlert from '../../atoms/CustomAlert';
import { useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
const ShareApp = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [alertMsg, setAlertMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const shareApp = usePostShareApp();
  const nav = useNavigate();
  const handleInput = (evt) => {
    setError('');
    onEmailChange(evt.target.value);
  };
  const ValidateEmail = (email) => {
    let validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };
  const onEmailChange = (_email) => {
    setEmail(_email);
    if (!ValidateEmail(_email)) {
      setError('Please provide a valid email');
    }
  };
  const onAlertClose = () => {
    setAlertMsg('');
    setError('');
    setEmail('');
  };
  const handleShareApp = () => {
    if (email.length <= 0) {
      setError('Email is required field!');
    } else {
      setLoading(true);
      let data = {
        email: email,
        invitee_name: 'Friend',
      };

      shareApp.mutate(data, {
        onSuccess: (response) => {
          setLoading(false);
          setAlertMsg('Your Invitation has been sent successfully.');
          console.log('response', response);
        },
        onError: (error) => {
          setLoading(false);
          setError(error?.error || 'Something went wrong. Please try again.');
        },
      });
    }
  };

  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };
  return (
    <>
      <Loader open={loading} />
      {alertMsg.length > 0 && (
        <CustomAlert
          isOpen={alertMsg.length > 0 ? true : false}
          message={alertMsg}
          onClose={onAlertClose}
        />
      )}
      <Grid container>
        <Grid
          item
          xs={12}
          //   alignContent={'center'}
          mt={15}
          color={' #444444'}
          display={'flex'}
          justifyContent={'center'}>
          <Typography className={styles.shareApp}>Share App</Typography>
        </Grid>
        <Grid
          display={'flex'}
          justifyContent={'flex-end'}
          color={'#fff'}
          // marginTop={'-35px'}
          // marginRight={'40px'}
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
            width: '95%',
            marginTop: '-25px',
          }}
          onClick={handleClose}>
          <Typography color={'#fff'}>X</Typography>
        </Grid>
        <Grid item m={1} className={styles.title_message}>
          <Typography>
            {' '}
            Help us to build the Introw community, share the app with friends{' '}
          </Typography>
        </Grid>

        <Grid container m={1}>
          <Grid item xs={12} color={' #444444'}>
            <Typography
              textAlign="left"
              className={styles.description}
              style={{
                fontSize: `${scaleByRatio(24)}px`,
              }}>
              Email
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <InputField
              id={'Email'}
              name={'Email'}
              labelId={''}
              type={'email'}
              // id={'inputData'}
              value={email}
              width="1000px"
              handleChange={(event) => handleInput(event)}
              minHeightPX="66"
              placeholder={'Email (Required)'}
            />
          </Grid>

          {error && error?.length > 0 && (
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="flex-end">
              <Typography
                textAlign="left"
                className={styles.error}
                style={{
                  fontSize: `${scaleByRatio(18)}px`,
                  lineHeight: `${scaleByRatio(40)}px`,
                }}>
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          xs={12}
          //   alignContent={'center'}
          mt={4}
          display={'flex'}
          justifyContent={'center'}>
          <CustomButton text={'Share App'} onClick={handleShareApp} />
        </Grid>
      </Grid>
    </>
  );
};

export default ShareApp;
