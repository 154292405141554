import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import AppBar from '@mui/material/AppBar'
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { Box, Grid, IconButton, useMediaQuery } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import styles from './index.module.scss';
import Profile_Avatar from '../../assets/Profile_Avatar.png';

import './styles.scss';
import {
  landingHeaderMenu,
  DrawerWidth,
  HomeMenuConstant,
  routeUrls,
} from '../../utils/constants';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { useWindowSize } from '../../utils/useWindowSize';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import AppLogo from '../../assets/AppLogo.svg';
import DaterImg from '../../assets/DaterImg.svg';
import FriendImg from '../../assets/FriendImg.svg';
import AppLogoMin from '../../assets/AppLogoMin.svg';
import Explore from '../../assets/Explore.svg';
import ExploreSelected from '../../assets/ExploreSelected.svg';
import ElbowNew from '../../assets/ElbowNew.svg';
import ElbowsSelected from '../../assets/ElbowsSelected.svg';
import Convos from '../../assets/Convos.svg';
import ConvosSelected from '../../assets/ConvosSelected.svg';
import defaultUser from '../../assets/defaultUser.svg';
import ChatterBox from '../../assets/ChatterBox.svg';
import ChatterBoxSelected from '../../assets/ChatterBoxSelected.svg';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import LeftMenuMyFriend from '../../assets/LeftMenuMyFriend.svg';
import LeftMenuDatingPreference from '../../assets/LeftMenuDatingPreference.svg';
import LeftMenuNotifications from '../../assets/LeftMenuNotifications.svg';
import LeftMenuMyProfile from '../../assets/LeftMenuMyProfile.svg';
import LeftMenuAccountSettings from '../../assets/LeftMenuAccountSettings.svg';
import LeftMenuShareApp from '../../assets/LeftMenuShareApp.svg';
import LeftMenuSupport from '../../assets/LeftMenuSupport.svg';
import LeftMenuTAndC from '../../assets/LeftMenuTAndC.svg';
import LeftMenuPrivacyPolicy from '../../assets/LeftMenuPrivacyPolicy.svg';
import LeftMenuLogout from '../../assets/LeftMenuLogout.svg';
import BlueDot from '../../assets/BlueDot.svg';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { scaleByRatio } from '../../utils/helper';
import {
  ActiveDaterContext,
  FriendDaterId,
  ReachOutContext,
  ReportConvoCounter,
  ReportElbowCounter,
} from '../../utils/Context/reportCounter';
import { useGetPropectiveDater, usePatchClearRequestCount } from '../../services/mutations';
import ChatterBoxProspectiveDaterProfile from '../ChatterBoxProspectiveDaterProfile';
import DaterProfileView from '../DaterProfileView';

const drawerWidth = DrawerWidth;

const menuItemStyle = {
  color: '#fff',
  fontFamily: 'Myriad Pro',
  fontSize: `${scaleByRatio(18)}px`,
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
};
const menuItemStyleSelected = {
  color: '#F46B1B',
  // color: '#76d9ff',
  fontFamily: 'Myriad Pro',
  fontSize: `${scaleByRatio(18)}px`,
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const HomeHeader = ({
  window,
  handleMenuClicked,
  selectedMenu,
  isNewNotificationPresent,
  showRerenderHome,
}) => {
  const isMobile = useMediaQuery('(max-width:700px)');
  const localStorage = useLocalStorage();
  const localStorageItem = useLocalStorage();
  const clearCounter=usePatchClearRequestCount()
  const getPropectiveDaterList=useGetPropectiveDater()
  const nav = useNavigate();
  const [mobileOpenRightPanel, setMobileOpenRightPanel] = useState(false);
  const [mobileOpenLeftPanel, setMobileOpenLeftPanel] = useState(false);
  const [headerSelectedMenu, setHeaderSelectedMenu] = useState(selectedMenu);
  const [elbowCount, setElbowCount] = useContext(ReportElbowCounter);
  const [convoCount, setConvoCount] = useContext(ReportConvoCounter);
  const [reachOutHandle, setReachOutHandle] = useContext(ReachOutContext);
  const [activeDaterImg, setActiveDaterImg] = useState('');
  const [friendDaterId,setFriendDaterId] = useContext(FriendDaterId);
  const [showDaterProfile,setShowDaterProfile]=useState(false)
  const [activeFriendDater,setActiveFriendDater]=useContext(ActiveDaterContext);
  console.log("reachoutHandleelbowCount:",reachOutHandle)
  console.log('activeFriendDater:',activeFriendDater,friendDaterId)
  const [height, width] = useWindowSize();
  const [getwidth, setWidth] = useState(0);

  const clearCounterData = (check=false) => {
    console.log('clear counter data:', check);
    // let data={
    //   request_id:localStorage.getLocalStorageItem('counterID'),
    //   reach_out_count:0,
    // }
    let data={};
    if(check){ 
       data={
      request_id:localStorage.getLocalStorageItem('counterID'),
      reach_out_count:0,
    }
  } else{
      data={
      request_id:localStorage.getLocalStorageItem('counterID'),
      chat_with_dater_count:0,
    }
  }
    clearCounter.mutate(
      data,
      {
        onSuccess: (response) => {
          console.log('clear counter success:', response);
          if(check){
            setElbowCount(0);
          }else{
            setConvoCount(0);
          }
        },
        onError: (error) => {
          // setLoading(false);
          console.log('getUsersFriendList error:', error);
        },
      },
  
    );
        }

  useEffect(() => {
    // if (typeof window !== "undefined") {
    setWidth(window?.innerWidth);
    // }
  }, [width]);

  useEffect(() => {
    const daterImg = localStorageItem.getLocalStorageItem('activeDaterImage');
    setActiveDaterImg(daterImg);
  }, []);
  console.log(
    'activeDaterImage',
    localStorageItem.getLocalStorageItem('activeDaterImage'),
  );

  console.log(
    'profileImage',
    localStorageItem.getLocalStorageItem('profileImage'),
  );

  const handleuserType = () => {
    if (localStorageItem.getLocalStorageItem('userType') === 'dater') {
      // return <img src={DaterImg} className='dater-img' alt="dater-img" />
      return 'D';
    } else if (localStorageItem.getLocalStorageItem('userType') === 'friend') {
      // return <img src={FriendImg} className='friend-img' alt="friend-img" />
      return 'F';
    } else {
      return '';
    }
  };

  const handleUserTypeCls = () => {
    if (localStorageItem.getLocalStorageItem('userType') === 'dater') {
      return 'user-type';
    } else if (localStorageItem.getLocalStorageItem('userType') === 'friend') {
      return 'user-type-friend';
    } else {
      return 'user-type';
    }
  };

  let iconChatterBox =
    selectedMenu === HomeMenuConstant.ChatterBox
      ? ChatterBoxSelected
      : ChatterBox;

  const handleDrawerToggleRight = () => {
    setMobileOpenLeftPanel(false);
    setMobileOpenRightPanel((prevState) => !prevState);
  };
  const handleDrawerToggleLeft = () => {
    setMobileOpenRightPanel(false);
    setMobileOpenLeftPanel((prevState) => !prevState);
  };

  const handleTopMenuClick = (menu) => {
    handleMenuClicked(menu);
  };

  const handleReachOutClick = (menu,check) => {
    handleMenuClicked(menu);
    clearCounterData(check)
    
  };

  const handleConvoClick = (menu,check) => {
    handleMenuClicked(menu);
    clearCounterData(check)
    
  };

  const handleLeftMenuClicked = (menu) => {
    handleMenuClicked(menu);
  };
const handleDaterProfileClicked=(menu)=>{
  handleMenuClicked(menu,false,'lastRoute');
};
  const drawerRight = (
    <Box onClick={handleDrawerToggleRight} sx={{ textAlign: 'left' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img
          src={AppLogo}
          alt="App Icon Home"
          className={styles.leftMenuAppIcon}
          // onClick={() => nav(routeUrls.landingPage)}
        />
      </Typography>
      <Divider />
    </Box>
  );

  const drawerLeft = (
    <Box
      onClick={handleDrawerToggleLeft}
      sx={{ textAlign: 'left' }}
      overflowX="visible"
      marginBottom="20%">
      <Typography variant="h6" sx={{ my: 2 }}>
        <img
          src={AppLogo}
          alt="App Icon"
          className={styles.leftMenuAppIcon}
          // onClick={() => nav(routeUrls.landingPage)}
          width={'100%'}
        />
        <IconButton
        //  onClick={handleDrawerClose}
        >
          <CloseOutlinedIcon style={{ fill: '#fff', marginLeft: '200px' }} />
        </IconButton>
      </Typography>
      <Divider />
      <List>
        {localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
          localStorageUserType.dater && (
          <ListItem key={'My Friends'} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2.5,
                gap: 1,
                color: '#fff',
              }}
              onClick={() => handleLeftMenuClicked(HomeMenuConstant.MyFriends)}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: 'center',
                }}>
                <img src={LeftMenuMyFriend} alt="My Friends" />
              </ListItemIcon>
              <ListItemText
                primary={'My Friends'}
                sx={{
                  '& .MuiListItemText-primary':
                    selectedMenu === HomeMenuConstant.MyFriends
                      ? menuItemStyleSelected
                      : menuItemStyle,
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
          localStorageUserType.friend && (
          <ListItem key={'My Daters'} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2.5,
                gap: 1,
              }}
              onClick={() => handleLeftMenuClicked(HomeMenuConstant.MyDaters)}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: 'center',
                }}>
                <img src={LeftMenuMyFriend} alt="My Daters" />
              </ListItemIcon>
              <ListItemText
                primary={'My Daters'}
                sx={{
                  '& .MuiListItemText-primary':
                    selectedMenu === HomeMenuConstant.MyDaters
                      ? menuItemStyleSelected
                      : menuItemStyle,
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
          localStorageUserType.dater && (
          <ListItem
            key={'Dating Preferences'}
            disablePadding
            sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2.5,
                gap: 1,
              }}
              onClick={() =>
                handleLeftMenuClicked(HomeMenuConstant.DatingPreferences)
              }>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: 'center',
                }}>
                <img src={LeftMenuDatingPreference} alt="Dating Preferences" />
              </ListItemIcon>
              <ListItemText
                primary={'Dating Preferences'}
                sx={{
                  '& .MuiListItemText-primary':
                    selectedMenu === HomeMenuConstant.DatingPreferences
                      ? menuItemStyleSelected
                      : menuItemStyle,
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem
          key={'Notifications'}
          disablePadding
          sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              px: 2.5,
              gap: 1,
            }}
            onClick={() =>
              handleLeftMenuClicked(HomeMenuConstant.Notifications)
            }>
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
              }}>
              <img src={LeftMenuNotifications} alt="Notifications" />
              {isNewNotificationPresent && (
                <img
                  src={BlueDot}
                  alt="BlueDot"
                  style={{
                    width: `${scaleByRatio(12)}px`,
                  }}
                />
              )}
            </ListItemIcon>
            <ListItemText
              primary={'Notifications'}
              sx={{
                '& .MuiListItemText-primary':
                  selectedMenu === HomeMenuConstant.Notifications
                    ? menuItemStyleSelected
                    : menuItemStyle,
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={'My Profile'} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              px: 2.5,
              gap: 1,
            }}
            onClick={() => handleLeftMenuClicked(HomeMenuConstant.MyProfile)}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
              }}>
              <img src={LeftMenuMyProfile} alt="My Profile" />
            </ListItemIcon>
            <ListItemText
              primary={'My Profile'}
              sx={{
                '& .MuiListItemText-primary':
                  selectedMenu === HomeMenuConstant.MyProfile
                    ? menuItemStyleSelected
                    : menuItemStyle,
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          key={'Account Settings'}
          disablePadding
          sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              px: 2.5,
              gap: 1,
            }}
            onClick={() =>
              handleLeftMenuClicked(HomeMenuConstant.AccountSettings)
            }>
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
              }}>
              <img src={LeftMenuAccountSettings} alt="Account Settings" />
            </ListItemIcon>
            <ListItemText
              primary={'Account Settings'}
              sx={{
                '& .MuiListItemText-primary':
                  selectedMenu === HomeMenuConstant.AccountSettings
                    ? menuItemStyleSelected
                    : menuItemStyle,
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={'Share App'} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              px: 2.5,
              gap: 1,
            }}
            onClick={() => handleLeftMenuClicked(HomeMenuConstant.ShareApp)}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
              }}>
              <img src={LeftMenuShareApp} alt="Share App" />
            </ListItemIcon>
            <ListItemText
              primary={'Share App'}
              sx={{
                '& .MuiListItemText-primary':
                  selectedMenu === HomeMenuConstant.ShareApp
                    ? menuItemStyleSelected
                    : menuItemStyle,
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={'Support'} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              px: 2.5,
              gap: 1,
            }}
            onClick={() => handleLeftMenuClicked(HomeMenuConstant.Support)}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
              }}>
              <img src={LeftMenuSupport} alt="Support" />
            </ListItemIcon>
            <ListItemText
              primary={'Support'}
              sx={{
                '& .MuiListItemText-primary':
                  selectedMenu === HomeMenuConstant.Support
                    ? menuItemStyleSelected
                    : menuItemStyle,
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={'T&C'} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              px: 2.5,
              gap: 1,
            }}
            onClick={() => handleLeftMenuClicked(HomeMenuConstant.TnC)}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
              }}>
              <img src={LeftMenuTAndC} alt="T&C" />
            </ListItemIcon>
            <ListItemText
              primary={'T&C'}
              sx={{
                '& .MuiListItemText-primary':
                  selectedMenu === HomeMenuConstant.TnC
                    ? menuItemStyleSelected
                    : menuItemStyle,
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          key={'Privacy Policy'}
          disablePadding
          sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              px: 2.5,
              gap: 1,
            }}
            onClick={() =>
              handleLeftMenuClicked(HomeMenuConstant.PrivacyPolicy)
            }>
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
              }}>
              <img src={LeftMenuPrivacyPolicy} alt="Privacy Policy" />
            </ListItemIcon>
            <ListItemText
              primary={'Privacy Policy'}
              sx={{
                '& .MuiListItemText-primary':
                  selectedMenu === HomeMenuConstant.PrivacyPolicy
                    ? menuItemStyleSelected
                    : menuItemStyle,
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={'Logout'} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              px: 2.5,
              gap: 1,
            }}
            onClick={() => handleLeftMenuClicked(HomeMenuConstant.Logout)}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
              }}>
              <img src={LeftMenuLogout} alt="Logout" />
            </ListItemIcon>
            <ListItemText
              primary={'Logout'}
              sx={{
                '& .MuiListItemText-primary':
                  selectedMenu === HomeMenuConstant.Logout
                    ? menuItemStyleSelected
                    : menuItemStyle,
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    console.log('useEffect selectedMenu: ', selectedMenu);
  }, []);
  return (
  <Box>
      <AppBar className={styles.header} component="nav">
        <Toolbar className={styles.headerToolbar}>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggleLeft}
              sx={{
                mr: 2,
                color: 'orange',
                display: { marginRight: '-40px' },
              }}>
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            className={styles.appIconWrapper}>
            <img
              src={AppLogoMin}
              alt="App Icon"
              className={styles.appIcon}
              // onClick={() => nav(routeUrls.landingPage)}
              style={{
                height: `${scaleByRatio(73)}px`,
                paddingTop: `${scaleByRatio(10)}px`,
                paddingBottom: `${scaleByRatio(10)}px`,
              }}
            />
          </Typography>

          <div className="user-profile-wrapper">
            {localStorageItem.getLocalStorageItem('userType') === 'friend' ? (
              <>
                {/* <span className="user-type">D</span> */}
                <img
                  src={
                    localStorageItem.getLocalStorageItem('profileImage') 
                    && localStorageItem.getLocalStorageItem('profileImage') !== 'null' ?
                    localStorageItem.getLocalStorageItem('profileImage') :
                    Profile_Avatar
                  } 
                  className="user-img"
                  alt="Np"
                />
                <span className={handleUserTypeCls()}>+</span>
                {/* <img
                  src={
                    localStorageItem.getLocalStorageItem('profileImage') ||
                    Profile_Avatar
                  }
                  className="user-img"
                  alt="Np"
                /> */}

                <img
                // onClick={handleDaterProfileView}
                onClick={() =>
                  handleDaterProfileClicked(HomeMenuConstant.DaterProfile)
                }
                  src={
                    // activeDaterImg === undefined ||
                    // activeDaterImg === null ||
                    // activeDaterImg === '' ||
                    // activeDaterImg === 'undefined'
                    //   ? Profile_Avatar
                    //   : activeDaterImg
                    localStorageItem.getLocalStorageItem('activeDaterImage')
                      ? localStorageItem.getLocalStorageItem('activeDaterImage')
                      : Profile_Avatar
                  }
                  className="user-img"
                  alt="Np"
                  style={{cursor:'pointer'}}
                />
              </>
            ) : (
              <>
                <img
                  src={
                    localStorageItem.getLocalStorageItem('profileImage') ||
                    Profile_Avatar
                  }
                  className="user-img"
                  alt="Np"
                />
              </>
            )}
          </div>
          {width > 700 && (
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', sm: 'flex' },
              }}
              className={styles.menuContainer}>
              <MenuItem
                key={'Explore'}
                onClick={() => handleTopMenuClick(HomeMenuConstant.Explore)}>
                <img
                  src={
                    selectedMenu === HomeMenuConstant.Explore
                      ? ExploreSelected
                      : Explore
                  }
                  alt="Explore"
                  className={styles.TopMenuICon}
                  style={{
                    width: `${scaleByRatio(27)}px`,
                    height: `${scaleByRatio(24)}px`,
                  }}
                />
                <Typography
                  textAlign="center"
                  className={styles.menuItem}
                  style={{
                    fontSize: `${scaleByRatio(14)}px`,
                    lineHeight: `${scaleByRatio(22)}px`,
                    paddingLeft: `${scaleByRatio(5)}px`,
                    color: `${
                      selectedMenu === HomeMenuConstant.Explore
                        ? // ? '#F36723'
                          `#76d9ff`
                        : '#fff'
                    }`,
                  }}>
                  Explore
                </Typography>
              </MenuItem>
              <MenuItem
                key={'ChatterBox'}
                onClick={() => handleTopMenuClick(HomeMenuConstant.ChatterBox)}>
                <img
                  src={
                    selectedMenu === HomeMenuConstant.ChatterBox
                      ? ChatterBoxSelected
                      : ChatterBox
                  }
                  alt="Chatter Box"
                  className={styles.TopMenuICon}
                  style={{
                    width: `${scaleByRatio(27)}px`,
                    height: `${scaleByRatio(24)}px`,
                  }}
                />
                <Typography
                  textAlign="center"
                  className={styles.menuItem}
                  style={{
                    fontSize: `${scaleByRatio(14)}px`,
                    lineHeight: `${scaleByRatio(22)}px`,
                    paddingLeft: `${scaleByRatio(5)}px`,
                    color: `${
                      selectedMenu === HomeMenuConstant.ChatterBox
                        ? // ? '#FF000A'
                          '#76d9ff'
                        : // : '#05232E'
                          '#fff'
                    }`,
                  }}>
                  {/* Chatter Box */}
                  {/* Chat with my friends */}
                  Group Chat
                </Typography>
              </MenuItem>
              <MenuItem
                key={'Convos'}
                // onClick={() => handleTopMenuClick(HomeMenuConstant.Convos)}
                onClick={() => handleConvoClick(HomeMenuConstant?.Convos,false)}>
                
                <img
                  src={
                    selectedMenu === HomeMenuConstant.Convos
                      ? ConvosSelected
                      : Convos
                  }
                  alt="Convos"
                  className={styles.TopMenuICon}
                  style={{
                    width: `${scaleByRatio(27)}px`,
                    height: `${scaleByRatio(24)}px`,
                  }}
                />
                {convoCount > 0 && (
                  <Grid
                    style={{
                      backgroundColor: 'red',
                      // color: 'red',
                      text: 'white',
                      borderRadius: '50%',
                      width: `${scaleByRatio(25)}px`,
                      height: `${scaleByRatio(25)}px`,
                      textAlign: 'center',
                      fontSize: `${scaleByRatio(15)}px`,
                      marginTop: `${scaleByRatio(-40)}px`,
                      marginLeft: `${scaleByRatio(-10)}px`,
                    }}>
                    {convoCount > 9 ? '9+' : convoCount}
                  </Grid>
                )}
                <Typography
                  textAlign="center"
                  className={styles.menuItem}
                  style={{
                    fontSize: `${scaleByRatio(14)}px`,
                    lineHeight: `${scaleByRatio(22)}px`,
                    paddingLeft: `${scaleByRatio(5)}px`,
                    // marginLeft: `${convoCount >= 0 && scaleByRatio(-10)}px`,
                    color: `${
                      selectedMenu === HomeMenuConstant.Convos
                        ? // ? '#4285F4'
                          '#76d9ff'
                        : // : '#05232E'
                          '#fff'
                    }`,
                  }}>
                  {/* Convos */}
                  {/* Chat with daters */}
                  Dater to Dater
                </Typography>
              </MenuItem>
              <MenuItem
                key={'Elbows'}
                // onClick={() => handleTopMenuClick(HomeMenuConstant.Elbows)}
                onClick={() => handleReachOutClick(HomeMenuConstant.Elbows,true)}
                >
                <img
                  src={
                    selectedMenu === HomeMenuConstant.Elbows
                      ? ElbowsSelected
                      : ElbowNew
                  }
                  alt="Elbows"
                  className={styles.TopMenuICon}
                  style={{
                    width: `${scaleByRatio(27)}px`,
                    height: `${scaleByRatio(24)}px`,
                  }}
                />
                {elbowCount > 0 && (
                  <Grid
                    style={{
                      backgroundColor: 'red',
                      // color: 'red',
                      text: 'white',
                      borderRadius: '50%',
                      width: `${scaleByRatio(25)}px`,
                      height: `${scaleByRatio(25)}px`,
                      textAlign: 'center',
                      fontSize: `${scaleByRatio(14)}px`,
                      marginTop: `${scaleByRatio(-40)}px`,
                      marginLeft: `${scaleByRatio(-10)}px`,
                    }}
                    // className={styles.counter}
                  >
                    {elbowCount > 9 ? '9+' : elbowCount}
                  </Grid>
                )}
                <Typography
                  textAlign="center"
                  className={styles.menuItem}
                  style={{
                    fontSize: `${scaleByRatio(14)}px`,
                    lineHeight: `${scaleByRatio(22)}px`,
                    paddingLeft: `${scaleByRatio(5)}px`,
                    // marginLeft: `${elbowCount >= 0 && scaleByRatio(-10)}px`,
                    color: `${
                      selectedMenu === HomeMenuConstant.Elbows
                        ? // ? '#6B8F39'
                          '#76d9ff'
                        : // : '#05232E'
                          '#fff'
                    }`,
                  }}>
                  {/* Elbows */}
                  Reach Outs
                </Typography>
              </MenuItem>
              {/* )} */}
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {isMobile && (
        <nav>
          <Drawer
            anchor={'right'}
            container={container}
            variant="temporary"
            open={mobileOpenRightPanel}
            onClose={handleDrawerToggleRight}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              // display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}>
            {drawerRight}
          </Drawer>
        </nav>
      )}
      {isMobile && (
        <nav>
          <Drawer
            anchor={'left'}
            container={container}
            variant="temporary"
            open={mobileOpenLeftPanel}
            onClose={handleDrawerToggleLeft}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              // display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                backgroundColor: '#28252587',
                zIndex: 1000,
                backgroundColor: 'currentcolor',
                width: drawerWidth,
              },
            }}>
            {drawerLeft}
          </Drawer>
        </nav>
      )}
    </Box> 
  );
};

export default HomeHeader;
