import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import InputField from '../../atoms/InputField';
import { CustomButton } from '../../atoms/CustomButton';
import styles from './index.module.scss';
import { scaleByRatio } from '../../utils/helper';
import {
  useLocalStorage,
  localStorageKeys,
  localStorageUserType,
} from '../../utils/localStorageItems';
import { useGetMyProfile, usePostFeedback } from '../../services/mutations';
import Loader from '../../molecule/Loader';
import CustomAlert from '../../atoms/CustomAlert';
import { routeUrls } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
const Support = () => {
  // const localStorageItem = useLocalStorage();
  // const userEmail = localStorageItem.getLocalStorageItem(
  //   localStorageKeys.userEmail,
  // );

  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [alertMsg, setAlertMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const supportApp = usePostFeedback();
  const getMyProfile = useGetMyProfile();
  const nav = useNavigate();
  const onAlertClose = () => {
    setAlertMsg('');
    setError('');
    setMessage('');
  };
  const handleInput = (evt) => {
    console.log('evt.target.value', evt.target.value);
    setMessage(evt.target.value);
  };

  const handleSupportData = () => {
    console.log('message', message);
    if (message.length <= 0) {
      setError('Message is required field!');
    } else {
      setLoading(true);
      let data = {
        message: message,
      };

      supportApp.mutate(data, {
        onSuccess: (response) => {
          setLoading(false);
          setAlertMsg('Your Feedback has been sent successfully.');
          console.log('response', response);
        },
        onError: (error) => {
          setLoading(false);
          setError(error?.error || 'Something went wrong. Please try again.');
        },
      });
    }
  };

  const fetchMyProfileDetails = () => {
    setLoading(true);
    getMyProfile.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('getMyProfile onSuccess: ', response);
          // setUserDetails(response?.data?.payload);
          setEmail(response?.data?.payload?.user?.email);
          console.log('email', email);
          setLoading(false);
        },
        onError: (err) => {
          console.log('getMyProfile onError: ', err);
          setLoading(false);
        },
      },
    );
  };

  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };
  useEffect(() => {
    fetchMyProfileDetails();
  }, []);

  return (
    <>
      <Loader open={loading} />
      {alertMsg.length > 0 && (
        <CustomAlert
          isOpen={alertMsg.length > 0 ? true : false}
          message={alertMsg}
          onClose={onAlertClose}
        />
      )}

      <Grid container>
        <Grid
          item
          xs={12}
          //   alignContent={'center'}
          mt={15}
          color={' #444444'}
          display={'flex'}
          justifyContent={'center'}>
          <Typography fontWeight={'500'} className={styles.supportApp}>
            Support
          </Typography>
        </Grid>
        <Grid
          display={'flex'}
          justifyContent={'flex-end'}
          color={'#fff'}
          // marginTop={'-35px'}
          // marginRight={'40px'}
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
            width: '95%',
            marginTop: '-25px',
          }}
          onClick={handleClose}>
          <Typography color={'#fff'}>X</Typography>
        </Grid>
        <Grid item m={1} className={styles.title_message}>
          If you have any issues with Introw Dating, please share your feedback.
        </Grid>
        <Grid container m={1} color={' #444444'}>
          <Grid item xs={12}>
            <Typography
              className={styles.description}
              style={{
                fontSize: `${scaleByRatio(24)}px`,
              }}>
              Email
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputField
              id={'inputData'}
              labelId={''}
              type={'email'}
              // id={'inputData'}
              disabled={true}
              value={email}
              width="1000px"
              // handleChange={(event) => handleInput(event)}
              minHeightPX="56"
              placeholder={'Email (Required)'}
            />
          </Grid>
        </Grid>
        <Grid container m={1} color={' #444444'}>
          <Grid item xs={12}>
            <Typography
              className={styles.description}
              style={{
                fontSize: `${scaleByRatio(24)}px`,
              }}>
              Message
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="Message"
              id={'Message'}
              value={message}
              placeholder={'Message (Required)'}
              handleChange={(event) => handleInput(event)}
              type={'text'}
              class_name={styles.name}
              minHeightPX={`${scaleByRatio(56)}`}
              multiline={true}
              rows={5}
            />
          </Grid>
          {error && error?.length > 0 && message.length <= 0 && (
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="flex-end">
              <Typography
                textAlign="left"
                className={styles.error}
                style={{
                  fontSize: `${scaleByRatio(18)}px`,
                  lineHeight: `${scaleByRatio(40)}px`,
                }}>
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          xs={12}
          //   alignContent={'center'}
          mt={4}
          display={'flex'}
          justifyContent={'center'}>
          <CustomButton
            text={'Submit'}
            // style={{ display: 'block', textAlign: 'center' }}
            // type={ButtonType.primary}
            onClick={handleSupportData}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Support;
