import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './index.module.scss'
import { scaleByRatio } from '../../utils/helper';
import {  Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio'
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import InputField from '../../atoms/InputField';

const EndIntrowDialog = ({
    isOpen=false,
    messageArr=[],
    customMessageID,
    heading='',
    onYesClick,
    onNoClick,
    listItemClass='',
    convo
}) => {
    const [open, setOpen] = useState(isOpen);
    const [selectedValue, setSelectedValue] = useState(null)
    const [customTextMessage, setCustomTextMessage] = useState('')
    

    const handleCancel = () => {
        setOpen(false);
        onNoClick(selectedValue)
    };
    const handleAgree = ()=>{
        setOpen(false);
        onYesClick(selectedValue, customTextMessage)
    }
    const onChange = (id)=>{
        if(id!==customMessageID){
            setCustomTextMessage('')
        }
        setSelectedValue(id)
        
    }
    const handleCustomMessageChange = (evt)=>{
        setCustomTextMessage(evt.target.value)
    }
    return ( 
        <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={styles.heading}
        style={{
            fontSize:`${scaleByRatio(32)}px`,
            lineHeight:`${scaleByRatio(38)}px`,
        }}>
          end this <span style={{
            color:'#F46B1B'
          }}>
            {convo?.session_type == 'PIC'? 'Reach out' : 'Introw'}
            </span>?
        </DialogTitle>
        <DialogContent>
        <Grid container direction="row"  alignItems="flex-end" sx={{background:"#FFFFFF"}}>
            <Grid item xs={12} justifyContent="left" style={{
                display:`flex`
            }}>
                <Typography fullwidth textAlign={"left"} className={styles.subHeading}
                style={{
                    fontSize:`${scaleByRatio(16)}px`
                }}>
                {/* You can select and send a message from the list below, while ending this introw. */}
                {` You can select and send a message from the list below, while ${convo?.session_type == 'PIC'? 'ending this reach out.':'ending this introw.'}`}
                </Typography>

            </Grid>
        </Grid>
        <Grid container direction="row"  alignItems="flex-end" sx={{background:"#FFFFFF"}}>
            {messageArr && messageArr.length>0 && messageArr.map((message)=>{
               if(convo?.session_type=='DIC' && message.id !=3) { return (
                <ListItem
                key={message.id}
                sx={{'&.MuiListItem-root':{
                    display:`flex`,
                    alignItems:'flex-start'
                }}}
            >
                <Radio
                    edge="start"
                    onChange={()=>onChange(message.id)}
                    checked={selectedValue===message.id}
                    sx={{ "& .MuiSvgIcon-root": { 
                        fontSize: `${scaleByRatio(20)}px`,
                    }, color: "#05232E",
                    '&.MuiRadio-root':{
                        paddingTop:`${scaleByRatio(6)}px`
                    },
                    '&.Mui-checked': {
                    color: "#05232E",
                    },
                    }}
                    name="RadioButtons"
                />

                <ListItemText
                id={message.id}
                primary={message.text}
                sx={{'.MuiListItemText-primary': {
                    fontSize:`${scaleByRatio(16)}px`,
                    lineHeight: `${scaleByRatio(20)}px`,
                    fontWeight:`400`,
                    fontFamily: 'Myriad Pro'
                }}}
                />
            </ListItem>
                )}
               else if(convo?.session_type=='PIC' && message.id ==3 || message.id==99) { return (
                    <ListItem
                    key={message.id}
                    sx={{'&.MuiListItem-root':{
                        display:`flex`,
                        alignItems:'flex-start'
                    }}}
                >
                    <Radio
                        edge="start"
                        onChange={()=>onChange(message.id)}
                        checked={selectedValue===message.id}
                        sx={{ "& .MuiSvgIcon-root": { 
                            fontSize: `${scaleByRatio(20)}px`,
                        }, color: "#05232E",
                        '&.MuiRadio-root':{
                            paddingTop:`${scaleByRatio(6)}px`
                        },
                        '&.Mui-checked': {
                        color: "#05232E",
                        },
                        }}
                        name="RadioButtons"
                    />
    
                    <ListItemText
                    id={message.id}
                    primary={message.text}
                    sx={{'.MuiListItemText-primary': {
                        fontSize:`${scaleByRatio(16)}px`,
                        lineHeight: `${scaleByRatio(20)}px`,
                        fontWeight:`400`,
                        fontFamily: 'Myriad Pro'
                    }}}
                    />
                </ListItem>
                    )}
            })}
            
        </Grid>
        <Grid container direction="row" justifyContent={"left"} alignItems="flex-end" sx={{background:"#FFFFFF"}}>
        <InputField
            name='customMessage'
            id={'customMessage'}
            value={customTextMessage}
            placeholder={'Message'}
            handleChange={(evt)=>handleCustomMessageChange(evt)}
            type={'text'}
            class_name={styles.customMessage}
            multiline={true}
            rows={5}
            disabled={(!selectedValue || selectedValue!==customMessageID)}
            />
        </Grid>

        </DialogContent>
        <DialogActions style={{
            justifyContent:"center",
            display: 'flex'
        }}>
        <Button onClick={handleCancel} autoFocus className={`${styles.button}  `}
        style={{
            fontSize:`${scaleByRatio(18)}px`,
            lineHeight:`${scaleByRatio(24)}px`
        }}
        >
            Cancel
        </Button>
        <Button onClick={handleAgree} className={`${styles.button} ${styles.primary}`}
        style={{
            fontSize:`${scaleByRatio(18)}px`,
            lineHeight:`${scaleByRatio(24)}px`
        }}>
            Yes, End This
        </Button>
        </DialogActions>
      </Dialog>
     );
}
 
export default EndIntrowDialog;