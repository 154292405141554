import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import CustomChip from '../../atoms/CustomChip';
import Stack from '@mui/material/Stack';
import { scaleByRatio } from '../../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';

const MyProfileSection = ({
  heading,
  description,
  arrChips = [], // this will be array of arrays
  handleChipClick,
}) => {
  // console.log('MyProfileSection heading: ', heading)
  const nav = useNavigate();
  console.log('MyProfileSection arrChips: ', arrChips);
  const ChipClicked = (chip) => {
    console.log('ChipClicked: ', chip);
    handleChipClick(chip);
    nav(routeUrls.home, {
      state: {
        showEdit: false,
      },
    });
  };
  return (
    <Grid container direction={'row'} alignItems={'flex-start'}>
      {heading && heading.length > 0 && (
        <Grid
          item
          xs={12}
          alignItems="flex-end"
          style={{ minHeight: `${scaleByRatio(20)}px` }}
          className="testing"></Grid>
      )}
      {heading && heading.length > 0 && (
        <Grid item xs={12}>
          <Typography
            fullwidth
            textAlign="left"
            className={styles.subHeader}
            style={{
              fontSize: `${scaleByRatio(24)}px`,
            }}>
            {heading}
          </Typography>
        </Grid>
      )}
      {description && description.length > 0 && (
        <Grid item xs={12}>
          <Typography fullwidth textAlign="left" className={styles.description}>
            {description}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {arrChips && arrChips.length > 0 && (
          <Stack
            direction="row"
            spacing={1}
            className={styles.stackClass}
            flexWrap="wrap"
            style={{
              marginTop: `${scaleByRatio(5)}px`,
              gap: `${scaleByRatio(5)}px`,
            }}>
            {arrChips.map((chips) => {
              return (
                <CustomChip
                  label={chips?.label}
                  clickable={chips?.clickable || false}
                  handleClick={() =>
                    chips?.clickable ? ChipClicked(chips) : null
                  }
                  style={{ fontSize: `${scaleByRatio(54)}px` }}
                />
              );
            })}
          </Stack>
        )}

        {/* <Stack direction="row" spacing={1} className={styles.stackClass}>
                    <CustomChip label='Men' clickable={false} /> 
                    <CustomChip label='Want Children' clickable={false} /> 
                </Stack> */}
      </Grid>
    </Grid>
  );
};

export default MyProfileSection;
