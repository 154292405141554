import React, { useState } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import AppLogoMin from '../../assets/AppLogoMin.svg';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import SingleChoiceSelectionButton from '../../atoms/SingleChoiceSelectionButton';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import CheckBoxWithLabel from '../../atoms/CheckBoxWithLabel';
import { scaleByRatio } from '../../utils/helper';

const QuestionGender = ({
  displayText = '',
  onBackClicked,
  error,
  onNextClicked,
  answerChoices,
  selectedValue,
  onClickCallBack,
  showBackButton = false,
  showVisibility = false,
  isVisible = false,
  onVisibleClick,
  checkBoxLabel = '',
  showSteps = false,
  currentStep,
  totalSteps,
}) => {
  const handleClick = (selectedID) => {
    console.log('handleClick: ', selectedID);
    onClickCallBack(selectedID);
  };
  return (
    <>
      {/* <Grid container direction="row" justifyContent="center" alignItems="flex-end">
            <Grid item className={styles.logoContainer}>
                    <img src={AppLogoMin} alt={'App icon'} className={styles.logo}
                    style={{
                        height:`${scaleByRatio(126)}px`,
                        width:`${scaleByRatio(56)}px`
                    }}
                    />
                
            </Grid>
        </Grid> */}
      {showBackButton && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            <img
              src={ArrowLeft}
              alt={'Back icon'}
              className={styles.logo}
              onClick={onBackClicked}
            />
          </Grid>
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.back}
              onClick={onBackClicked}
              style={{
                fontSize: `${scaleByRatio(16)}px`,
                paddingLeft: `${scaleByRatio(10)}px`,
                paddingBottom: `${scaleByRatio(5)}px`,
              }}>
              Back
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.description}
            style={{ fontSize: `${scaleByRatio(32)}px` }}>
            {displayText}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" className={styles.genderBtn}>
        {answerChoices &&
          answerChoices.map((choice) => {
            return (
              <Grid item justifyContent="left" alignItems="flex-start">
                <SingleChoiceSelectionButton
                  text={choice.value}
                  isSelected={selectedValue === choice.id}
                  onClick={() => handleClick(choice.id)}
                />
              </Grid>
            );
          })}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"></Grid>
      {showVisibility && (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end">
          <CheckBoxWithLabel
            isChecked={isVisible}
            labelText={checkBoxLabel}
            handleChange={onVisibleClick}
          />
        </Grid>
      )}

      {error && error?.length > 0 && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Typography
            textAlign="left"
            className={styles.error}
            style={{
              fontSize: `${scaleByRatio(18)}px`,
              lineHeight: `${scaleByRatio(40)}px`,
            }}>
            {error}
          </Typography>
        </Grid>
      )}
      <Grid container direction="row" alignItems="flex-end">
        <Grid item xs={6}>
          {showSteps && (
            <Typography
              textAlign={'left'}
              className={styles.pagination}
              style={{
                padding: `${scaleByRatio(12)}px ${scaleByRatio(32)}px`,
                fontSize: `${scaleByRatio(25)}px`,
                lineHeight: `${scaleByRatio(34)}px`,
              }}>
              {currentStep}/{totalSteps}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          justifyContent="right"
          style={{
            display: 'flex',
          }}>
          <CustomButton
            text={'Next'}
            variant={ButtonVariant.contained}
            type={ButtonType.primary}
            onClick={() => onNextClicked()}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionGender;
