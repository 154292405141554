import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import HomePageLayout from '../../Layout/HomePageLayout';
import { useWindowSize } from '../../utils/useWindowSize';
import Typography from '@mui/material/Typography';
import { HomeMenuConstant } from '../../utils/constants';
import MyProfile from '../../organism/MyProfile';
import MyProfileFriend from '../../organism/MyProfileFriend';
import MyFriends from '../../organism/MyFriends';
import PrivacyPolicy from '../../organism/PrivacyPolicy';
import TermAndConditions from '../../organism/TermAndConditions';
import MyDaters from '../../organism/MyDaters';
import DatingPreference from '../../organism/DatingPreference';
import Explore from '../../organism/Explore';
import Elbows from '../../organism/Elbows';
import Convos from '../../organism/Convos';
import ChatterBox from '../../organism/ChatterBox';
import AccountSettings from '../../organism/AccountSettings';
import NotificationList from '../../organism/NotificationList';
import StickyFooter from '../../organism/StickyFooter';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { PUB_NUB_PUBLISH, PUB_NUB_SUBSCRIBE } from '../../utils/constants';
import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import { useGetNotificationList } from '../../services/mutations';
import { useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import Logout from '../../organism/LogOut';
import ShareApp from '../../organism/ShareApp';
import Support from '../../organism/Support';
import usePushNotification from '../../utils/hooks/usePushNotification';
import { ChatClose, DaterProfileBackRoute, ExploreCloseContext, GroupChatCloseContext, ReachOutContext } from '../../utils/Context/reportCounter';
import DaterProfileView from '../../organism/DaterProfileView';

const Home = () => {
  const [height, width] = useWindowSize();
  const [getwidth, setWidth] = useState(0);
  const [showRerenderHome, setRerenderHome] = useState(false);

  usePushNotification();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window?.innerWidth);
    }
  }, [width]);
  const [selectedMenu, setSelectedMenu] = useState(HomeMenuConstant.MyProfile);
  const [isNewNotificationPresent, setNewNotificationPresent] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [daterFriendId, setDaterFriendId] = useState(null);
  const [reachOutHandle, setReachOutHandle] = useContext(ReachOutContext);
  const [chatClose, setChatClose] = useContext(ChatClose);
  const [groupChatClose, setGroupChatClose] = useContext(GroupChatCloseContext);
  const [exploreClose, setExploreClose] = useContext(ExploreCloseContext);
  const [lastRoute, setLastRoute] = useState(null);
  const [backRoute, setBackRoute] = useContext(DaterProfileBackRoute);
  const localStorageItem = useLocalStorage();
  const pubnub = new PubNub({
    publishKey: PUB_NUB_PUBLISH,
    subscribeKey: PUB_NUB_SUBSCRIBE,
    uuid: localStorageItem.getLocalStorageItem(localStorageKeys.profileId),
  });
  const getNotificationList = useGetNotificationList();
  const nav = useNavigate();

  const fetchNotificationList = () => {
    setLoading(true);
    getNotificationList.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('fetchNotificationList::', response);
          let newNotification = false;
          let newNotificationStatus = response?.data?.payload?.results?.find(
            (notification) => !notification.read,
          );
          if (newNotificationStatus) {
            console.log('New notification present');
            newNotification = true;
          } else {
            console.log('No New notification');
            newNotification = false;
          }
          setNotificationList(response?.data?.payload?.results);
          setNewNotificationPresent(newNotification);
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setLoading(false);
        },
      },
    );
  };
  const handleMenuClicked = (menu, flag= false,lastRoute) => {
  console.log('handleMenuClicked menu: ',groupChatClose,exploreClose);
if(menu==4){
  setReachOutHandle(true); 
}
if(menu==3){
  setChatClose(true);
}
if(menu==2){
  setGroupChatClose(true);
}
if(menu==1){
  setExploreClose(true);
}
  if(lastRoute){
      setLastRoute(selectedMenu)
      setSelectedMenu(menu);
    }
    if(!flag){
      setDaterFriendId(null);
    }
    setSelectedMenu(menu);
    // if(menu === HomeMenuConstant.Logout){
    //     localStorageItem.removeLocalStorageItem(localStorageKeys.accessToken)
    //     localStorageItem.removeLocalStorageItem(localStorageKeys.isLoggedIn)
    //     localStorageItem.removeLocalStorageItem(localStorageKeys.mobileNumber)
    //     localStorageItem.removeLocalStorageItem(localStorageKeys.profileId)
    //     localStorageItem.removeLocalStorageItem(localStorageKeys.userType)
    //     nav(routeUrls.join);
    // }
  };
  useEffect(() => {
    if (
      localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.dater
    ) {
      if (localStorage.getItem('questionEdit') === 'true') {
        setSelectedMenu(HomeMenuConstant.MyProfile);
      } else {
        // setSelectedMenu(HomeMenuConstant.MyProfile);
        setSelectedMenu(HomeMenuConstant.ChatterBox);
      }
    }
    if (
      localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.friend
    ) {
      setSelectedMenu(HomeMenuConstant.ChatterBox);
    }
    fetchNotificationList();
    let myInterval = setInterval(fetchNotificationList(), 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, []);

  useEffect(()=>{
    if(reachOutHandle?.status){
      handleMenuClicked(HomeMenuConstant.Elbows,true);
      setDaterFriendId(reachOutHandle?.id);
      setReachOutHandle(null);
    }
  },[reachOutHandle?.status])

  const handleCurrentDater = () => {
    setRerenderHome(!showRerenderHome);
  };
useEffect(()=>{
  if(backRoute){
    handleMenuClicked(backRoute,true);
    setBackRoute(null);
  }
},[backRoute])


  return (
    <PubNubProvider client={pubnub}>
      <HomePageLayout
        handleMenuClicked={handleMenuClicked}
        showRerenderHome={showRerenderHome}
        selectedMenu={selectedMenu}
        isNewNotificationPresent={isNewNotificationPresent}>
        {selectedMenu === HomeMenuConstant.MyProfile &&
          localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
            localStorageUserType.dater && (
            <MyProfile handleMenuClicked={handleMenuClicked} />
          )}
        {selectedMenu === HomeMenuConstant.MyProfile &&
          localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
            localStorageUserType.friend && (
            <MyProfileFriend handleMenuClicked={handleMenuClicked} />
          )}
        {selectedMenu === HomeMenuConstant.Support && <Support />}

        {selectedMenu === HomeMenuConstant.ShareApp && <ShareApp />}

        {selectedMenu === HomeMenuConstant.MyFriends && <MyFriends />}
        {selectedMenu === HomeMenuConstant.Logout && (
          <Logout handleMenuClicked={handleMenuClicked} />
        )}
        {selectedMenu === HomeMenuConstant.DatingPreferences && (
          <DatingPreference />
        )}
        {selectedMenu === HomeMenuConstant.Explore && <Explore />}
        {selectedMenu === HomeMenuConstant.PrivacyPolicy && (
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              pb: 9,
              pt: 3,
              pr: 3,
              pl: 3,
              marginTop: '80px',
              display: 'flex',
            }}
            flexDirection={'column'}
            justifyContent="center"
            alignItems="center">
            <PrivacyPolicy
              showAcceptButton={false}
              showCrossButton={true}
              maxheight={66}
              showPageName={true}
            />
          </Box>
        )}
        {selectedMenu === HomeMenuConstant.TnC && (
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              pb: 9,
              pt: 3,
              pr: 3,
              pl: 3,
              marginTop: '80px',
              display: 'flex',
            }}
            flexDirection={'column'}
            justifyContent="center"
            alignItems="center">
            <TermAndConditions
              showAcceptButton={false}
              showCrossButton={true}
              maxheight={66}
              showPageName={true}
            />
          </Box>
        )}

        {selectedMenu === HomeMenuConstant.MyDaters && (
          <MyDaters handleCurrentDater={handleCurrentDater} />
        )}
        {selectedMenu === HomeMenuConstant.Elbows && <Elbows daterFriendId={daterFriendId} setDaterFriendId={setDaterFriendId} />}
        {selectedMenu === HomeMenuConstant.Convos && <Convos />}
        {selectedMenu === HomeMenuConstant.DaterProfile && <DaterProfileView lastRoute={lastRoute}/>}
        {selectedMenu === HomeMenuConstant.ChatterBox && (
          <ChatterBox handleCurrentDater={handleCurrentDater} />
        )}
        {selectedMenu === HomeMenuConstant.AccountSettings && (
          <AccountSettings />
        )}
        {!loading && selectedMenu === HomeMenuConstant.Notifications && (
          <NotificationList
            notifications={notificationList}
            callbackOnClick={fetchNotificationList}
          />
        )}
        {getwidth <= 700 && (
          <StickyFooter
            handleMenuClicked={handleMenuClicked}
            selectedMenu={selectedMenu}
          />
        )}
      </HomePageLayout>
    </PubNubProvider>
  );
};

export default Home;
