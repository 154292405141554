import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { scaleByRatio } from "../../utils/helper";

const SelectDropDown = ({
    margin,
    id,
    value,
    handleChange,
    disabled = false,
    labelId="",
    menuItems=[]
}) => {
    return ( 
        <>
        <FormControl variant='standard' fullWidth margin={margin}>
            <Select
            labelId={labelId}
            id={id}
            value={value}
            onChange={handleChange}
            variant="filled"
            disabled={disabled}
            sx={{
                "& .MuiSelect-filled":{
                    backgroundColor:'#F1F6F8',
                    fontFamily: 'Myriad Pro',
                    fontSize: `${scaleByRatio(20)}px`,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    height: `${scaleByRatio(20)}px`,
                    padding: `${scaleByRatio(10)}px`,
                    borderBottom: `1px solid #C4C4C4`
                }
            }}
            >
                {menuItems.map((menu)=>{
                    return (
                        <MenuItem value={menu.value} style={{
                            padding: `${scaleByRatio(32)}px`
                        }}>{menu.displayText}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
        </>
     );
}
 
export default SelectDropDown;